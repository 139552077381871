import axios from "axios";
import { BusinessUserInt } from "../typescript/interfaces/BusinessUserInt";
import { environment } from "../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../firebase";
const getBusinessUserPosts = async (id: string) => {
  // get email and lookup all the available applications
  try {
    const appCheckToken = await getAppCheckToken(appCheck);
    const result = await axios.post(
      `${environment.endpoints.getPostsCount}`,
      {
        user: id,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    return result.data.posts;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

const createBusinessUser = async (user: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.post(
      `${environment.endpoints.addBusinessUser}`,
      { user },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    if (result.data.message === "Operation successful") {
      console.log("Business user created successfully");
      return true;
    } else {
      console.error("Failed to create business user:", result.data.message);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return false;
  }
};

const getBusinessUsersByCompanyId = async (
  companyId: string
): Promise<BusinessUserInt[]> => {
  const appCheckToken = await getAppCheckToken(appCheck);
  const result = await axios.get(
    `${environment.endpoints.getBusinessUsersByCompanyId}?companyId=${companyId}`,
    {
      headers: {
        "X-Firebase-AppCheck": appCheckToken.token,
      },
    }
  );

  return result.data.result as BusinessUserInt[];
};

export {
  getBusinessUserPosts,
  createBusinessUser,
  getBusinessUsersByCompanyId,
};
