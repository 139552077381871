import axios from "axios";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
export const deleteAutomationTrigger = async (
  triggerId: string,
  jobId: string,
  stateId: string
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    console.log("deleting trigger", triggerId, jobId, stateId);
    const result = axios.delete(
      `${environment.endpoints.deleteAutomationTrigger}?automationTriggerId=${triggerId}&jobId=${jobId}&stateId=${stateId}`,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    return result;
  } catch (error) {
    console.error(error);
  }
};
