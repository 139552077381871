import axios from "axios";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
const addNewsletterUser = async (email: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const res = await axios.post(
      `${environment.endpoints.addNewsletterUser}`,
      {
        email: email,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return res;
  } catch (e) {
    return null;
  }
};

const removeNewsletterUser = async (email: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const res = await axios.post(
      `${environment.endpoints.removeNewsletterUser}`,
      { email: email },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return res;
  } catch (e) {
    return null;
  }
};

export { addNewsletterUser, removeNewsletterUser };
