import React, { useEffect, useState } from "react";
import CompanyPageLayout from "../components/dashboard/companyPage/CompanyPageLayout";
import { CompanyInt } from "../typescript/interfaces/CompanyInt";
import { getCompanyPage } from "../utils/companyPageFunctions";
import { JobInt } from "../typescript/interfaces/JobInterface";
import { getPostedJobsById } from "../utils/applicantFunctions";
import { useParams } from "react-router-dom";
function CompanyPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState({
    description: "",
    bannerUrl: "",
    logoUrl: "",
    name: "",
  });
  const [company, setCompany] = useState({} as CompanyInt);
  const { subdomainId } = useParams();

  // Updated demo positions to match JobInt interface
  const [positions, setPositions] = useState<JobInt[]>([]);

  useEffect(() => {
    const fetchCompanyPage = async () => {
      setIsLoading(true);
      try {
        // Replace 'your-subdomain' with the actual subdomain
        const data = await getCompanyPage(subdomainId);
        console.log(data);
        setPageData({
          description: data.description || "",
          bannerUrl: data.bannerUrl || "",
          logoUrl: data.logoUrl || "",
          name: data.name || "",
        });
        setCompany(data.company || ({} as CompanyInt));
        //
        const positions = await getPostedJobsById(
          data.companyId,
          {
            status: "true",
          },
          undefined,
          undefined,
          data.companyId
        );
        console.log("fetched positions");
        console.log(positions);
        setPositions(positions.jobs);
      } catch (error) {
        console.error("Error fetching company page:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyPage();
  }, [subdomainId]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-100 border-t-black"></div>
      </div>
    );
  }

  return (
    <CompanyPageLayout
      company={company}
      pageData={pageData}
      positions={positions} // Pass positions to the layout
    />
  );
}

export default CompanyPage;
