import React from "react";

type StateColorsProps = {
  state: string;
  color?: string;
};

const stateColorMap: { [key: string]: string } = {
  Guardados: "#FFBB32", // Orange
  Nuevos: "#15BC7F", // Green
  Contratados: "#800080", // Purple
  Todos: "rgba(0,0,0,0.4)",
  // Add more states and colors as needed
};

function StateColors(props: StateColorsProps) {
  const color = stateColorMap[props.state] || "rgba(0,0,0,0.4)"; // Default to gray if state not found

  return (
    <div className="w100">
      {props.state && (
        <div
          style={{
            backgroundColor: props.color ? props.color : color,
            borderRadius: "50px",
            padding: "5px 12px",
            color: "white",
            fontSize: "14px",
            width: "fit-content",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          }}
          title={props.state}
        >
          {props.state}
        </div>
      )}
    </div>
  );
}

export default StateColors;
