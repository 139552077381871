import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  applicantStateOption,
  JobInt,
} from "../../typescript/interfaces/JobInterface";

import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import { filterApplicantsByState } from "../../utils/applicantFunctions";
import ApplicantSidebar from "../applicants-sidebar/ApplicantSidebar";
import LoadingWidget from "../widgets/LoadingWidget";
import { filterApplicantsByLocation } from "../../utils/locationUtils"; // Add this import
import ApplicantPipeline from "./ApplicantPipeline";
import FilterAltIcon from "@mui/icons-material/FilterAlt"; // Add this import
import { useApplicantState } from "../../components/routing/ApplicantStateContext";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"; // Add this import
import VisibilityIcon from "@mui/icons-material/Visibility"; // Add this import
import { useApplicants } from "../../components/routing/ApplicantContext";
import JobEditPopUp from "./JobEditPopUp";
import AddApplicantButton from "./tools/AddApplicantButton";
import AutomationIcon from "@mui/icons-material/AutoFixHigh";
import AutomationForms from "./forms/AutomationForms";
import { createAutomationTrigger } from "../../utils/automation/createAutomationTrigger";
import { AutomationTrigger } from "../../typescript/interfaces/AutomationTypes";
import { useNotification } from "../context/NotificationContext";
import { updateAutomationTrigger } from "../../utils/automation/updateAutomationTrigger";
import { deleteAutomationTrigger } from "../../utils/automation/deleteAutomationTrigger";
import { useJobPositions } from "../../components/routing/JobPositionsContext";
import checkTriggersComplete from "../../utils/automation/checkTriggersComplete";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "../../hooks/useDebounce"; // You'll need to create this hook

interface ApplicantsProps {
  jobs: JobInt[];
  currentJobID: string | null;
  getPositions: () => Promise<void>;
}

// just added the context
// now : refactor the filters !! so that they get the options from here and also update here
// then : update the selection so it works with id
// then : update the filtering so it works with id
// then : update the drag and drop
// make sure the defaults work for those that have none

function Applicants(props: ApplicantsProps) {
  const {
    currentState,
    stateOptions,

    setStateOptions,
    defaultOptions,
  } = useApplicantState();

  const {
    jobApplicants,
    jobLoading,
    fetchJobApplicants,
    updateApplicantState,
    selectedJobApplicant,
    setSelectedJobApplicant,
  } = useApplicants();

  // loads the initial job
  const getInitialJob = useCallback(() => {
    if (props.currentJobID && props.jobs.length > 0) {
      const selectedJob = props.jobs.find(
        (job) => job._id === props.currentJobID
      );
      if (selectedJob) {
        return selectedJob;
      }
    }

    const storedJobId = localStorage.getItem("selectedJobId");
    if (storedJobId && props.jobs.length > 0) {
      const storedJob = props.jobs.find((job) => job._id === storedJobId);
      if (storedJob) {
        return storedJob;
      }
    }
    return props.jobs[0];
  }, [props.jobs, props.currentJobID]);

  const [selectedJob, setSelectedJob] = useState(getInitialJob);

  const [locationFilter, setLocationFilter] = useState<
    "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  >("Todos los aplicantes");

  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [showJobPopUp, setShowJobPopUp] = useState(false);
  const initialFetchDone = useRef(false);
  const [showAutomationForm, setShowAutomationForm] = useState(false);
  const { showNotification } = useNotification();
  const [currentStateId, setCurrentStateId] = useState("");
  const { refreshPositions } = useJobPositions();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300); // 300ms delay
  const [seenFilter, setSeenFilter] = useState("");
  const [sortDate, setSortDate] = useState("");

  // Change the type and rename the state variable
  const [rejectedFilter, setRejectedFilter] = useState<boolean>(false);

  const filterRef = useRef<HTMLDivElement>(null);

  const handleShowAutomationForm = (stateId: string) => {
    setShowAutomationForm(true);
    setCurrentStateId(stateId);
  };

  const handleLocationFilterChange = (
    filter: "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  ) => {
    setLocationFilter(filter);
  };

  // Add useEffect for handling outside clicks
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setIsFilterDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update filteredApplicants to include seen and date filters
  const filteredApplicants = useMemo(() => {
    let returnArray: ApplicantInt[] = jobApplicants;

    // Apply search filter
    if (debouncedSearchQuery.trim()) {
      const query = debouncedSearchQuery.toLowerCase();
      returnArray = returnArray.filter(
        (applicant) =>
          applicant.name.toLowerCase().includes(query) ||
          applicant.email.toLowerCase().includes(query) ||
          (applicant.resumeText || "").toLowerCase().includes(query)
      );
    }

    // Apply location filter if applicable
    if (locationFilter !== "Todos los aplicantes" && selectedJob.mapResults) {
      returnArray = filterApplicantsByLocation(
        returnArray,
        selectedJob.mapResults,
        locationFilter === "Dentro de la zona"
      );
    }

    // Apply seen filter
    if (seenFilter !== "") {
      returnArray = returnArray.filter(
        (applicant) => applicant.seen === (seenFilter === "true")
      );
    }

    // Apply state filter if not "Todos"
    if (currentState.id !== "Todos") {
      returnArray = filterApplicantsByState(returnArray, currentState.id);
    }

    // Update the rejected filter logic
    returnArray = returnArray.filter((applicant) => {
      if (rejectedFilter === true) {
        return applicant.rejected;
      } else {
        return !applicant.rejected || applicant.rejected === undefined;
      }
    });

    // Apply date sorting
    returnArray.sort((a, b) => {
      const dateA = new Date(a.date || 0).getTime();
      const dateB = new Date(b.date || 0).getTime();
      return sortDate === "asc" ? dateA - dateB : dateB - dateA;
    });

    return returnArray;
  }, [
    locationFilter,
    selectedJob.mapResults,
    currentState,
    jobApplicants,
    debouncedSearchQuery,
    seenFilter,
    sortDate,
    rejectedFilter,
  ]);

  const handleApplicantStateChange = async (
    event: React.MouseEvent<HTMLDivElement>,
    applicant: ApplicantInt,
    state: string,
    stateOption?: applicantStateOption
  ): Promise<void> => {
    event.stopPropagation();

    const newApplicant = {
      ...applicant,
      state: state,
    };
    if (stateOption) {
      newApplicant.stateOption = stateOption;
    }
    setSelectedJobApplicant(newApplicant);
    await updateApplicantState(applicant, state, "job", stateOption);
  };

  const createAutomation = async (
    automation: AutomationTrigger,
    exists: boolean,
    currTriggerId: string
  ): Promise<void> => {
    try {
      // Validate that actions with PROPERTY_INCLUDES conditions have at least one tag

      let hasInvalidConditions = false;

      hasInvalidConditions = automation.actions.some((action) => {
        if (!action.condition) return false;

        if ("operator" in action.condition) {
          return action.condition.conditions.some(
            (cond) =>
              cond.type === "PROPERTY_INCLUDES" &&
              (!cond.config.includesValue ||
                cond.config.includesValue.length === 0)
          );
        }

        return (
          action.condition.type === "PROPERTY_INCLUDES" &&
          (!action.condition.config.includesValue ||
            action.condition.config.includesValue.length === 0)
        );
      });

      if (hasInvalidConditions) {
        showNotification(
          "Por favor, agrega al menos una palabra clave para los filtros de Vicky.",
          "error"
        );
        return;
      }

      let completeAutomation = {
        ...automation,
        id: currTriggerId,
        jobId: selectedJob._id,
        isActive: true,
        createdAt: new Date(),
      };

      const isComplete = checkTriggersComplete(
        completeAutomation as AutomationTrigger
      );
      if (!isComplete) {
        showNotification(
          "Por favor, completa todos los campos requeridos para la automatización.",
          "error"
        );
        return;
      }

      if (exists) {
        if (
          completeAutomation.actions.length === 0 &&
          currTriggerId !== "" &&
          selectedJob._id !== "" &&
          selectedJob._id !== undefined
        ) {
          // First delete the automation trigger
          await deleteAutomationTrigger(
            currTriggerId,
            selectedJob._id,
            completeAutomation.stateId
          );
          showNotification("Automatización eliminada correctamente", "success");
        } else {
          await updateAutomationTrigger(
            completeAutomation as AutomationTrigger
          );
          showNotification(
            "Automatización actualizada correctamente",
            "success"
          );
        }
      } else {
        await createAutomationTrigger(completeAutomation as AutomationTrigger);
        showNotification("Automatización creada correctamente", "success");
      }

      setShowAutomationForm(false);
    } catch (error: any) {
      console.error(error);
      showNotification(error.message, "error");
      setShowAutomationForm(false);
      throw error;
    } finally {
      refreshPositions();
    }
  };

  // get the automation triggers
  // if they exist set existing so triggers update else create new

  useEffect(() => {
    const getApplicants = async () => {
      if (selectedJob._id && !initialFetchDone.current) {
        await fetchJobApplicants(selectedJob._id);
        initialFetchDone.current = true; // Mark initial fetch as done
      }
    };

    getApplicants();
  }, [props.jobs, selectedJob._id, fetchJobApplicants, jobApplicants]);

  //  when the job changes update the stateOptions
  useEffect(() => {
    if (selectedJob._id) {
      localStorage.setItem("selectedJobId", selectedJob._id);
      const newOptions = selectedJob.applicantStateOptions || defaultOptions;
      setStateOptions(newOptions);
    }
  }, [
    selectedJob._id,
    selectedJob.applicantStateOptions,
    defaultOptions,
    setStateOptions,
  ]);

  useEffect(() => {
    if (filteredApplicants.length > 0) {
      // when it says todos does not reset
      if (currentState.id !== "Todos") {
        setSelectedJobApplicant(filteredApplicants[0]);
      }
    }
  }, [currentState, filteredApplicants, setSelectedJobApplicant]);

  // Add new stats calculations using useMemo
  const stats = useMemo(() => {
    return {
      total: jobApplicants.length,
      seen: jobApplicants.filter((app) => app.seen).length,
    };
  }, [jobApplicants]);

  // this use effect is causing the stateOptions to be reset
  // reset
  useEffect(() => {
    // Update selectedJob when props.jobs changes

    if (props.jobs && selectedJob._id) {
      const updatedJob = props.jobs.find((job) => job._id === selectedJob._id);
      // if (
      //   updatedJob &&
      //   JSON.stringify(updatedJob) !== JSON.stringify(selectedJob)
      // ) {
      if (updatedJob) {
        setSelectedJob(updatedJob);
      }
    }
  }, [props.jobs, selectedJob._id]);

  return (
    <div
      className="w100 flex-1 px-2 md:px-4 pt-4"
      style={{
        display: "flex",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <div
        className="w100 flx flx-col flex-1 items-center space-y-2"
        style={{
          overflow: "hidden",
        }}
      >
        <div className="mb-2 p-4  md:p-0 md:px-4 bg-white rounded-lg shadow-sm md:shadow-none border md:border-0 border-gray-100 w-full">
          <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
            <div className="flex-1 w-full">
              <div className="flex items-center justify-between gap-2 mb-4">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900 leading-relaxed">
                  {selectedJob.title}
                </h1>
                <div className="flex items-center gap-3">
                  {!selectedJob.isGeneralApplication && (
                    <span
                      className={`px-2 py-1 text-sm rounded-full ${
                        selectedJob.status
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {selectedJob.status ? "Activo" : "Pausado"}
                    </span>
                  )}
                  <button
                    onClick={() => {
                      setShowJobPopUp(true);
                    }}
                    className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                    <span className="hidden sm:inline">Editar</span>
                  </button>
                </div>
              </div>

              <div className="flex flex-wrap items-center gap-y-3 gap-x-4 text-gray-600 text-sm leading-relaxed">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-1">
                    <PeopleAltIcon sx={{ fontSize: 16 }} />
                    <span>{stats.total}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <VisibilityIcon sx={{ fontSize: 16 }} />
                    <span>{stats.seen}</span>
                  </div>
                </div>

                <div className="flex flex-wrap items-center gap-x-4 gap-y-2 w-full sm:w-auto">
                  <span className="hidden sm:inline text-gray-400">|</span>
                  {!selectedJob.isGeneralApplication && (
                    <div className="flex items-center gap-2">
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <span>
                        {selectedJob?.location?.city},{" "}
                        {selectedJob?.location?.country}
                      </span>
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <span>
                      Publicado:{" "}
                      {new Date(selectedJob.datePosted).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flx w-full "
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!jobLoading && (
            <React.Fragment>
              {/* <JobDropDown
                options={jobOptions}
                value={{ value: selectedJob, label: selectedJob.title }}
                onSelect={handleOptionClick}
              /> */}

              {/* Replace existing filters with new consolidated filter button */}
              <div
                className="flx w100 px-2 md:px-4"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                {/* Search bar on the left - removed padding from container */}
                <div className="relative -ml-2 md:-ml-4 pl-2">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Buscar por nombre, email o CV..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="pl-10 pr-8 py-2 border border-gray-300 rounded-md w-80 text-sm transition-colors duration-200 focus:outline-none"
                    />
                    <SearchIcon
                      className="absolute left-3.5 top-1/2 transform -translate-y-1/2 text-gray-500"
                      sx={{ fontSize: 16 }}
                    />
                    {searchQuery && (
                      <button
                        type="button"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        onClick={() => setSearchQuery("")}
                        aria-label="Clear search"
                      >
                        <svg
                          className="w-3.5 h-3.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>

                {/* Action buttons grouped on the right */}
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setShowAutomationForm(true)}
                    className="flex items-center justify-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    <AutomationIcon sx={{ fontSize: 20 }} />
                    <span>Automatizar</span>
                  </button>
                  <div className="relative">
                    <button
                      onClick={() =>
                        setIsFilterDropdownOpen(!isFilterDropdownOpen)
                      }
                      className="flex items-center justify-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      <FilterAltIcon sx={{ fontSize: 20 }} />
                      <span>Filtros</span>
                    </button>

                    {/* Add clear filters button when filters are active */}
                    {(locationFilter !== "Todos los aplicantes" ||
                      seenFilter !== "" ||
                      sortDate !== "" ||
                      rejectedFilter) && (
                      <button
                        onClick={() => {
                          setLocationFilter("Todos los aplicantes");
                          setSeenFilter("");
                          setSortDate("");
                          setRejectedFilter(false);
                        }}
                        className="absolute -right-24 top-0 flex items-center justify-center gap-2 px-3 py-2 text-gray-600 bg-gray-50 border border-gray-200 rounded-md hover:bg-gray-100 transition-colors text-sm"
                        aria-label="Limpiar filtros"
                      >
                        <span>×</span>
                        <span>Limpiar</span>
                      </button>
                    )}

                    {/* Update filter dropdown positioning */}
                    {isFilterDropdownOpen && (
                      <div
                        className="absolute right-0 top-full mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 z-[100]"
                        ref={filterRef}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="p-4 space-y-4">
                          {/* Add Active/Rejected Toggle at the top */}
                          <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">
                              Estado
                            </label>
                            <div className="flex rounded-md border border-gray-300 p-1">
                              <button
                                className={`flex-1 px-3 py-1.5 text-sm rounded-md transition-colors ${
                                  !rejectedFilter
                                    ? "bg-laburo-green text-white"
                                    : "text-gray-700 hover:bg-gray-50"
                                }`}
                                onClick={() => setRejectedFilter(false)}
                              >
                                Activos
                              </button>
                              <button
                                className={`flex-1 px-3 py-1.5 text-sm rounded-md transition-colors ${
                                  rejectedFilter
                                    ? "bg-red-500 text-white"
                                    : "text-gray-700 hover:bg-gray-50"
                                }`}
                                onClick={() => setRejectedFilter(true)}
                              >
                                Rechazados
                              </button>
                            </div>
                          </div>

                          {/* Location Filter - Only show when mapResults exists */}
                          {selectedJob.mapResults && (
                            <div className="space-y-2">
                              <label className="text-sm font-medium text-gray-700">
                                Ubicación
                              </label>
                              <select
                                value={locationFilter}
                                onChange={(e) =>
                                  handleLocationFilterChange(
                                    e.target.value as typeof locationFilter
                                  )
                                }
                                className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                              >
                                <option value="Todos los aplicantes">
                                  Todos los aplicantes
                                </option>
                                <option value="Dentro de la zona">
                                  Dentro de la zona
                                </option>
                                <option value="Afuera de la zona">
                                  Afuera de la zona
                                </option>
                              </select>
                            </div>
                          )}

                          {/* Seen Filter */}
                          <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">
                              Vistos
                            </label>
                            <select
                              value={seenFilter}
                              onChange={(e) => setSeenFilter(e.target.value)}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                            >
                              <option value="">Todos</option>
                              <option value="true">Vistos</option>
                              <option value="false">No vistos</option>
                            </select>
                          </div>

                          {/* Date Sort */}
                          <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">
                              Ordenar por fecha
                            </label>
                            <select
                              value={sortDate}
                              onChange={(e) => setSortDate(e.target.value)}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                            >
                              <option value="desc">Más reciente</option>
                              <option value="asc">Más antiguo</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <AddApplicantButton
                    selectedJob={selectedJob}
                    department={selectedJob.department}
                    position={selectedJob.position}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {/* remove the selected applicant  */}
        {/* Conditional render based on viewMode */}
        {!jobLoading ? (
          <>
            {filteredApplicants.length > 0 ? (
              <ApplicantSidebar
                aplicants={filteredApplicants}
                setApplicantsState={handleApplicantStateChange}
                selectedApplicant={
                  selectedJobApplicant || filteredApplicants[0]
                }
                setSelectedApplicant={setSelectedJobApplicant}
                stateOptions={stateOptions}
              />
            ) : (
              <div className="flex justify-center text-gray-500 mobile-only">
                <div>Aun no hay candidatos en esta posición</div>
              </div>
            )}
            <div
              className="w100 flx flx-col flex-1 desktop-only-flex"
              style={{
                overflow: "hidden",
              }}
            >
              {/* instead of passing options pass the stateOptions from jobID */}
              <ApplicantPipeline
                applicants={filteredApplicants}
                selectedApplicant={
                  selectedJobApplicant || filteredApplicants[0]
                }
                setApplicantsState={handleApplicantStateChange}
                stateOptions={
                  selectedJob.applicantStateOptions || defaultOptions || []
                }
                setSelectedApplicant={setSelectedJobApplicant}
                setShowAutomationForm={(stateId: string) => {
                  handleShowAutomationForm(stateId);
                }}
                isRejectedView={rejectedFilter}
              />
            </div>
          </>
        ) : (
          <div className="flx flx-center mt-25">
            <LoadingWidget loading={true} />
          </div>
        )}
      </div>
      {showJobPopUp && (
        <JobEditPopUp
          setJobPopUp={setShowJobPopUp}
          job={selectedJob}
          getPositions={props.getPositions}
        />
      )}
      {showAutomationForm && (
        <AutomationForms
          stateOptions={selectedJob.applicantStateOptions || defaultOptions}
          onClose={() => setShowAutomationForm(false)}
          onSubmit={async (automation, exists, currTriggerId) => {
            await createAutomation(
              automation as AutomationTrigger,
              exists,
              currTriggerId
            );
          }}
          selectedJob={selectedJob}
          currentStateId={currentStateId}
        />
      )}
    </div>
  );
}

export default Applicants;
