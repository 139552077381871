import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ResumeUploader from "../../resume/ResumeUploader";
import { JobInt } from "../../../typescript/interfaces/JobInterface";
import {
  Education,
  WorkExperience,
} from "../../../typescript/interfaces/AppInterface";
import LoadingWidget from "../../widgets/LoadingWidget";
import {
  addApplicant,
  checkApplicantExists,
  getPostedJobsById,
  parseResume,
} from "../../../utils/applicantFunctions";
import TextEditor2 from "../../text-editor/TextEditor2";
import JobExperienceSection from "../../job-apply/JobExperienceSection";
import EducationSection from "../../job-apply/EducationSection";
import { useUser } from "../../routing/UserContext";
import { uploadResumeToFirebase } from "../../../utils/firebase/firebaseStorageUtils";
import { useNotification } from "../../context/NotificationContext";
import { useApplicants } from "../../routing/ApplicantContext";
import { useJobPositions } from "../../routing/JobPositionsContext";
import { LocationData } from "../../../typescript/interfaces/Location";
import AutocompleteLocation from "../../AutocompleteLocation";

interface AddApplicantButtonProps {
  smallIcon?: boolean;
  selectedJob?: JobInt;
  department?: string;
  position?: string;
}

function AddApplicantButton(props: AddApplicantButtonProps) {
  const { userData } = useUser();
  const { showNotification } = useNotification();
  const { fetchCompanyApplicants, fetchJobApplicants } = useApplicants();
  const { fetchPositions } = useJobPositions();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parsingResume, setParsingResume] = useState(false);
  const [uploadStatus] = useState(0);

  // Form states
  const [selectedJob, setSelectedJob] = useState<JobInt>(
    props.selectedJob || ({} as JobInt)
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resume, setResume] = useState<File>();
  const [description, setDescription] = useState("");
  const [educationList, setEducationList] = useState<Education[]>([]);
  const [experienceList, setExperienceList] = useState<WorkExperience[]>([]);
  const [yearsOfExperience, setYearsOfExperience] = useState<string>("menos_1");
  const [jobs, setJobs] = useState<JobInt[]>([]);
  // location
  const [location, setLocation] = useState<LocationData>();

  const [isLoadingJobs, setIsLoadingJobs] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // Reset form
    setName("");
    setEmail("");
    setPhone("");
    setResume(undefined);
    setDescription("");
    setEducationList([]);
    setExperienceList([]);
    setLocation(undefined);
  };
  const refreshApplicants = async () => {
    if (selectedJob._id) {
      await fetchJobApplicants(selectedJob._id);
      // this should be 20
      await fetchCompanyApplicants(selectedJob.companyId, 1, 20, "", {});
    }
  };

  const handleResumeUpload = async (file: File | undefined) => {
    setResume(file);
    if (!file) return;

    try {
      setParsingResume(true);
      const parsedData = await parseResume(file);

      setName(parsedData.nombre);
      setEmail(parsedData.email);
      setPhone(parsedData.telefono);
      setEducationList(parsedData.educacion as Education[]);
      setExperienceList(parsedData.experiencia_laboral as WorkExperience[]);
      setYearsOfExperience(parsedData.rango_años_experiencia);
    } catch (error) {
      console.error("Error parsing resume:", error);
    } finally {
      setParsingResume(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(selectedJob);
    setLoading(true);
    try {
      if (!resume) {
        throw new Error("No se seleccionó ningún CV");
      }

      // add check if resume is already uploaded
      const resumeExists = await checkApplicantExists(
        selectedJob?._id as string,
        email
      );
      if (resumeExists) {
        throw new Error("El correo ya existe en este trabajo");
      }

      const resumeUrlCreated = await uploadResumeToFirebase(
        resume,
        selectedJob?._id as string,
        name
      );

      const apiData = {
        name,
        email,
        number: phone,
        description,
        date: new Date(),
        resumeUrl: resumeUrlCreated,
        jobId: selectedJob?._id as string,
        educationList,
        experienceList,
        workExperienceYears: yearsOfExperience,
        seen: false,
        fileType: resume?.type as string,
        companyId: selectedJob?.companyId,
        state: selectedJob?.applicantStateOptions[0].id as string,
        stateOption: selectedJob?.applicantStateOptions[0],
        addedByName: userData?.firstName
          ? userData?.firstName + " " + userData?.lastName
          : userData?.user,
        department: props.department,
        position: props.position,
        location: location,
      };

      console.log(apiData);
      const result = await addApplicant(apiData);
      console.log(result);
      // Show success notification
      if (result.success) {
        showNotification("Candidato agregado exitosamente", "success");
        // refresh the jobs on dashboard
        fetchPositions({}, 1, 10);
        refreshApplicants();
        handleClose();
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      console.error("Error submitting:", error);
      // Show error notification
      showNotification(
        error instanceof Error ? error.message : "Error al agregar candidato",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoadingJobs(true);
      try {
        const jobs = await getPostedJobsById(
          userData?.user as string,
          undefined,
          undefined,
          undefined,
          userData?.companyId
        );
        setJobs(jobs.jobs);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setIsLoadingJobs(false);
      }
    };
    fetchJobs();
  }, [userData?.companyId, userData?.user]);

  return (
    <>
      <button
        onClick={handleOpen}
        className="mobile-only hover:bg-gray-100 bg-laburo-green text-white  py-1 px-3 rounded-full font-medium transition-colors duration-200 flex items-center gap-1 border ml-3"
      >
        <AddIcon style={{ fontSize: "20px" }} />
      </button>

      <button
        onClick={handleOpen}
        className="desktop-only hover:bg-gray-100 bg-laburo-green text-white text-sm px-3 py-1 rounded-full font-medium transition-colors duration-200 flex items-center gap-1 border ml-3"
      >
        <span>Agregar</span>
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={window.innerWidth <= 768}
        container={document.getElementById("portal-root")}
      >
        <div className="bg-white rounded-lg ">
          <div className="flex justify-between items-center p-6 border-b ">
            <h2 className="text-2xl font-semibold text-gray-800">
              Agregar Nuevo Candidato
            </h2>
            <IconButton
              onClick={handleClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <DialogContent
            className="!p-4 sm!p-6 "
            style={{ paddingBottom: "30px" }}
          >
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Job Selection */}
              <div className="w100 postjob-gray-container p-6 bg-gray-50 rounded-lg">
                <div className="mb-6">
                  <label className="block text-lg font-medium mb-2 text-gray-700">
                    Seleccionar Trabajo
                  </label>
                  <select
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                    value={selectedJob ? JSON.stringify(selectedJob) : ""}
                    onChange={(e) => setSelectedJob(JSON.parse(e.target.value))}
                    required
                    disabled={isLoadingJobs}
                  >
                    <option value="">
                      {isLoadingJobs
                        ? "Cargando..."
                        : "Seleccione un trabajo..."}
                    </option>
                    {!isLoadingJobs && jobs.length === 0 ? (
                      <option value="" disabled>
                        No hay trabajos disponibles
                      </option>
                    ) : (
                      jobs.map((job) => (
                        <option key={job._id} value={JSON.stringify(job)}>
                          {job.title} - {job.location.city} -{" "}
                          {new Date(job.datePosted).toLocaleDateString(
                            "es-ES",
                            { month: "short", year: "numeric" }
                          )}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                {/* Resume Upload */}
                <ResumeUploader
                  setResume={handleResumeUpload}
                  status={uploadStatus}
                  loading={parsingResume}
                />

                {/* Basic Info */}
                <div className="space-y-4 mt-6">
                  <div>
                    <label className="block text-lg font-medium mb-2 text-gray-700">
                      Nombre
                    </label>
                    <div className="search-pill">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="search-pill-input w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-lg font-medium mb-2 text-gray-700">
                      Correo
                    </label>
                    <div className="search-pill">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="search-pill-input w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-lg font-medium mb-2 text-gray-700">
                      Teléfono
                    </label>
                    <div className="search-pill">
                      <input
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="search-pill-input w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-lg font-medium mb-2 text-gray-700 ">
                      Ciudad de Domicilio
                    </label>

                    <AutocompleteLocation
                      setSelectedLocation={(loc) => {
                        setLocation(loc);
                      }}
                      value={location}
                    />
                  </div>

                  <div>
                    <TextEditor2
                      editorName="Descripción"
                      htmlValue={description}
                      setHTMLValue={(value) => setDescription(value)}
                    />
                  </div>
                </div>
              </div>

              {/* Experience and Education Sections */}
              <div className="space-y-6">
                <JobExperienceSection
                  JobExperienceList={experienceList}
                  handleJobExperienceChange={(index, field, value) => {
                    const updatedList = [...experienceList];
                    updatedList[index] = {
                      ...updatedList[index],
                      [field]: value,
                    };
                    setExperienceList(updatedList);
                  }}
                  handleJobExperienceDelete={(index) => {
                    setExperienceList(
                      experienceList.filter((_, i) => i !== index)
                    );
                  }}
                  addExperience={() =>
                    setExperienceList([...experienceList, {} as WorkExperience])
                  }
                  yearsOfExperience={yearsOfExperience}
                  setYearsOfExperience={setYearsOfExperience}
                />

                <EducationSection
                  educationList={educationList}
                  handleEducationChange={(index, field, value) => {
                    const updatedList = [...educationList];
                    updatedList[index] = {
                      ...updatedList[index],
                      [field]: value,
                    };
                    setEducationList(updatedList);
                  }}
                  handleEducationDelete={(index) => {
                    setEducationList(
                      educationList.filter((_, i) => i !== index)
                    );
                  }}
                  addEducation={() =>
                    setEducationList([...educationList, {} as Education])
                  }
                />
              </div>

              {/* Submit Button */}
              <div className="flex justify-center pt-6">
                {loading ? (
                  <LoadingWidget loading={loading} />
                ) : (
                  <button
                    type="submit"
                    className="w-full max-w-[500px] button-hover bg-laburo-green hover:bg-laburo-green-dark text-white px-8 py-3 rounded-lg font-medium transition-colors duration-200 "
                    disabled={loading}
                  >
                    Agregar Candidato
                  </button>
                )}
              </div>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}

export default AddApplicantButton;
