import videoPoster from "../../assets/videos/videoPoster.png";
import React, { useState, useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

// Replace HeroIcons imports with MUI icons
import company1 from "../../assets/images/companies/1.png";
import company2 from "../../assets/images/companies/2.png";
import company3 from "../../assets/images/companies/3.png";
import company4 from "../../assets/images/companies/4.png";
import company5 from "../../assets/images/companies/5.png";
import company6 from "../../assets/images/companies/6.png";
import company7 from "../../assets/images/companies/7.png";
// import feature images

import vacantes from "../../assets/images/features/vacantes.png";
import postulantes from "../../assets/images/features/postulantes.png";
import companyPage from "../../assets/images/features/companyPage.png";

import mobile from "../../assets/images/heroPreview/mobile.png";
import desktop from "../../assets/images/heroPreview/desktop.png";

import {
  Share as ShareIcon,
  AccountTree as ProcessIcon,
  Business as BuildingIcon,
  Storage as DatabaseIcon,
  Bolt as BoltIcon,
  AutoFixHigh as AutoFixIcon,
} from "@mui/icons-material";
import ContactUsForm from "../../components/dashboard/forms/ContactUsForm";

function BusinessLanding() {
  // Add ref for contact section
  const contactRef = useRef<HTMLDivElement>(null);
  // Add ref for features section
  const featuresRef = useRef<HTMLDivElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  // Update scroll handler
  const scrollToContact = () => {
    if (contactRef.current) {
      const elementPosition = contactRef.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.scrollY - window.innerHeight * 0.05;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const scrollToFeatures = () => {
    if (featuresRef.current) {
      const elementPosition = featuresRef.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.scrollY - window.innerHeight * 0.05;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const mainFeatures = [
    {
      title: "Procesos centralizados",
      description:
        "Olvídate de descargar CVs y organizar carpetas. Todo tu reclutamiento centralizado en una sola plataforma ",
      icon: ProcessIcon,
      image: vacantes,
    },
    {
      title: "Página de empresa",
      description:
        "Potencia tu marca empleadora con una página personalizada que destaque tu cultura y atraiga talento",
      icon: BuildingIcon,
      image: companyPage,
    },
    {
      title: "Base de candidatos",
      description:
        "Guarda todos los CVs permanentemente en tu base de datos personal para futuras búsquedas",
      icon: DatabaseIcon,
      image: postulantes,
    },
  ];

  const secondaryFeatures = [
    {
      title: "Automatizaciones",
      description: "Envía mensajes o toma acciones automáticas",
      icon: BoltIcon,
    },
    {
      title: "Inteligencia Artificial",
      description: "Pre filtra candidatos, resume los CVs, y mucho más",
      icon: AutoFixIcon,
    },
    {
      title: "Contenido compartible",
      description: "Comparte tus vacantes con hermosos templates",
      icon: ShareIcon,
    },
  ];

  const [selectedFeature, setSelectedFeature] = useState(mainFeatures[0]);

  return (
    <div className="min-h-[100vh] min-w-[100vw]">
      {/* Optional overlay to ensure content remains readable */}
      <div className="fixed top-0 left-0 w-full h-full bg-black/20 -z-10"></div>
      {/* Video Background */}
      <div
        className={`video-default-bg ${isVideoLoaded ? "hidden" : ""}`}
      ></div>

      <div className="fixed top-0 left-0 w-[100vw] h-[100vh] -z-20">
        {/* Background image that shows until video loads */}
        <img
          src={videoPoster}
          alt="Background"
          className={`absolute top-0 left-0 w-full h-full object-cover ${
            isVideoLoaded ? "hidden" : ""
          }`}
        />

        <video
          autoPlay={true}
          loop
          muted
          playsInline
          controls={false}
          className={`absolute top-0 left-0 w-full h-full object-cover video-controls ${
            !isVideoLoaded ? "hidden" : ""
          }`}
          onLoadedData={(e) => {
            setIsVideoLoaded(true);
          }}
          onPause={() => setIsVideoLoaded(false)} // Add this line
        >
          <source
            src={
              "https://firebasestorage.googleapis.com/v0/b/hrbot-e8686.appspot.com/o/assets%2FgradientVideo.mp4?alt=media&token=161facd5-ceab-4ebd-afa2-f143f1adf36c"
            }
            type="video/mp4"
          />
        </video>
      </div>

      {/* Decorative Background Elements */}
      <div className="fixed top-0 left-0 w-full h-[100vh] -z-15 overflow-hidden opacity-20">
        {/* Top-left group - Modern Resume Icon */}
        <div className="absolute top-[10%] right-[10%] md:top-[8%] md:right-[30%] transform rotate-6">
          <svg
            width="140"
            height="140"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1"
          >
            {/* Background rectangle */}
            <rect
              x="3"
              y="2"
              width="18"
              height="20"
              rx="2"
              className="fill-white/5"
            />

            {/* Person outline */}
            <circle cx="8" cy="7" r="1.5" className="fill-white/10" />
            <path
              d="M6.5 11C6.5 10.3 7.3 9.5 8 9.5C8.7 9.5 9.5 10.3 9.5 11"
              strokeLinecap="round"
            />

            {/* Text lines with varying lengths */}
            <line x1="12" y1="7" x2="17" y2="7" strokeWidth="1.5" />
            <line x1="12" y1="9" x2="15" y2="9" strokeWidth="1" />

            {/* Content lines */}
            <line x1="6" y1="14" x2="17" y2="14" strokeWidth="1" />
            <line x1="6" y1="16" x2="15" y2="16" strokeWidth="1" />
            <line x1="6" y1="18" x2="16" y2="18" strokeWidth="1" />
          </svg>
        </div>

        {/* Top-right group - Connected People Network */}
        <div className="absolute top-[45%] right-[99%] md:top-[50%] md:right-[15%] transform -rotate-12 ">
          <svg
            width="160"
            height="160"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1"
          >
            <circle cx="8" cy="8" r="2" className="fill-white/5" />
            <circle cx="16" cy="16" r="2" className="fill-white/5" />
            <circle cx="16" cy="8" r="2" className="fill-white/5" />
            <circle cx="8" cy="16" r="2" className="fill-white/5" />
            <path d="M8 11v2M16 11v2M11 8h2M11 16h2" strokeDasharray="2 2" />
          </svg>
        </div>

        {/* Center-left group - Analytics Chart */}
        <div className="absolute top-[75%] right-[17%] hidden md:block">
          <svg
            width="120"
            height="120"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1"
          >
            <path d="M3 20h18" strokeLinecap="round" />
            <path
              d="M4 12l4-4 4 4 8-8"
              className="fill-none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="4" cy="12" r="1" className="fill-white" />
            <circle cx="8" cy="8" r="1" className="fill-white" />
            <circle cx="12" cy="12" r="1" className="fill-white" />
            <circle cx="20" cy="4" r="1" className="fill-white" />
          </svg>
        </div>

        {/* Center-right group - Calendar/Schedule */}
        <div className="absolute top-[30%] right-[10%] md:top-[05%] md:right-[10%] transform rotate-12">
          <svg
            width="130"
            height="130"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1"
          >
            <rect
              x="3"
              y="4"
              width="18"
              height="16"
              rx="2"
              className="fill-white/5"
            />
            <path d="M3 8h18" />
            <path d="M8 4v4M16 4v4" />
            <circle cx="8" cy="12" r="1" className="fill-white" />
            <circle cx="12" cy="12" r="1" className="fill-white" />
            <circle cx="16" cy="12" r="1" className="fill-white" />
            <circle cx="8" cy="16" r="1" className="fill-white" />
            <circle cx="12" cy="16" r="1" className="fill-white" />
            <circle cx="16" cy="16" r="1" className="fill-white" />
          </svg>
        </div>

        {/* Bottom-left group - Task Completion */}
        <div className="absolute bottom-[2%] right-[2%] md:bottom-[10%] md:right-[4%] transform -rotate-6 animate-pulse-slow">
          <svg
            width="110"
            height="110"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1"
            className="drop-shadow-[0_0_8px_rgba(255,255,255,0.3)]"
          >
            {/* Outer decorative ring */}
            <circle
              cx="12"
              cy="12"
              r="11"
              className="stroke-white/20"
              strokeDasharray="4 4"
            />

            {/* Main circle with gradient */}
            <circle cx="12" cy="12" r="9">
              <animate
                attributeName="opacity"
                values="0.3;0.5;0.3"
                dur="2s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="r"
                values="9;9.2;9"
                dur="2s"
                repeatCount="indefinite"
              />
              <defs>
                <radialGradient id="taskGradient">
                  <stop offset="0%" stopColor="white" stopOpacity="0.2" />
                  <stop offset="100%" stopColor="white" stopOpacity="0.05" />
                </radialGradient>
              </defs>
              <animate
                attributeName="fill"
                values="url(#taskGradient);white/10;url(#taskGradient)"
                dur="2s"
                repeatCount="indefinite"
              />
            </circle>

            {/* Checkmark with animation */}
            <path
              d="M8 12l3 3 6-6"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-white"
              strokeWidth="1.5"
            >
              <animate
                attributeName="stroke-dasharray"
                values="0,20;20,0"
                dur="0.8s"
                fill="freeze"
              />
            </path>
          </svg>
        </div>

        {/* Decorative Shapes */}

        {/* Bottom-right group - Mail Icon */}
        <div className="absolute bottom-[20%] right-[15%] md:bottom-[25%] md:right-[01%] transform rotate-12">
          <svg
            width="120"
            height="120"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1.5"
          >
            {/* Simplified, more rounded envelope */}
            <rect
              x="2"
              y="4"
              width="20"
              height="16"
              rx="4"
              className="fill-white/5"
            />
            {/* Smoother mail flap curve */}
            <path
              d="M2 8L12 14L22 8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        {/* Center group - Attachment Icon */}
        <div className="absolute top-[47%] right-[1%] md:top-[45%] md:right-[5%] transform -rotate-45">
          <svg
            width="100"
            height="100"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1.5"
          >
            <path
              d="M12 22a6 6 0 0 1-6-6V8a4 4 0 0 1 8 0v8a2 2 0 0 1-4 0V8"
              strokeLinecap="round"
              className="fill-white/5"
            />
          </svg>
        </div>

        {/* New Right Side Decorative Elements */}

        {/* Decorative Elements - Right Side Distribution */}
        {/* Search/Filter Icon - Upper Right */}
        <div className="absolute top-[20%] right-[17%] hidden md:block transform -rotate-12">
          <svg
            width="130"
            height="130"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1.5"
          >
            {/* Main circle (glass part) */}
            <circle cx="10" cy="10" r="7" className="fill-white/5" />
            {/* Handle with rounded cap */}
            <path d="M15.5 15.5L20 20" strokeLinecap="round" strokeWidth="2" />
          </svg>
        </div>

        {/* Chat/Message Bubbles - Upper Right */}
        <div className="absolute top-[47%] right-[25%] md:top-[28%] md:right-[1%] transform rotate-3">
          <svg
            width="120"
            height="120"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1"
          >
            {/* Main bubble with more rounded corners */}
            <path
              d="M21 14.5a3 3 0 0 1-3 3H8.5l-4.3 3.2c-.8.6-2.2 0-2.2-1V5a3 3 0 0 1 3-3h13a3 3 0 0 1 3 3v9.5z"
              className="fill-white/5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            {/* Three horizontal lines */}
            <line x1="7" y1="7" x2="11" y2="7" strokeWidth="1.2" />
            <line x1="7" y1="10" x2="14" y2="10" strokeWidth="1.2" />
            <line x1="7" y1="13" x2="12" y2="13" strokeWidth="1.2" />
          </svg>
        </div>

        {/* Profile Card Icon - Middle Right */}
        <div className="absolute top-[65%] right-[30%] hidden md:block transform -rotate-6">
          <svg
            width="150"
            height="150"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1.2"
          >
            {/* Card background with more rounded corners */}
            <rect
              x="4"
              y="4"
              width="16"
              height="16"
              rx="4"
              className="fill-white/5"
            />
            {/* Larger, centered avatar circle */}
            <circle cx="12" cy="10" r="3.5" className="fill-white/10" />
            {/* Smoother profile curve */}
            <path d="M7 19c0-2.8 2.2-5 5-5s5 2.2 5 5" strokeLinecap="round" />
          </svg>
        </div>

        {/* Document/CV Icon - Lower Middle Right */}
        <div className="absolute top-[35%] right-[25%] transform rotate-12 hidden md:block">
          <svg
            width="110"
            height="110"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="1.2"
          >
            {/* Main document shape with more rounded corners */}
            <path
              d="M6 2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z"
              className="fill-white/5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            {/* Simplified lines for text representation */}
            <line x1="8" y1="8" x2="16" y2="8" strokeLinecap="round" />
            <line x1="8" y1="13" x2="16" y2="13" strokeLinecap="round" />
            <line x1="8" y1="18" x2="16" y2="18" strokeLinecap="round" />
          </svg>
        </div>
      </div>

      {/* Content wrapper */}
      <div className="relative z-10">
        <Navbar scrollPast={false} lockNavbar={true} hideHireButton={true} />

        {/* Hero Section */}
        <div className="relative isolate px-6 lg:px-8">
          <div className="absolute inset-0 -z-10 opacity-1"></div>

          {/* Remove the duplicate decorative elements div */}
          <div className="mx-auto max-w-7xl pt-5 sm:pb-38 sm:pt-28 lg:pb-46 lg:pt-30">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
              {/* Left Column - Text Content */}
              <div className="text-left">
                {/* Add subtle animation to title */}
                <h1 className="text-5xl md:text-8xl tracking-tight text-black/70 leading-tight lexend-title">
                  Recluta
                  <br />
                  Como
                  <br />
                  Profesional
                </h1>

                {/* Replace the paragraph with a div */}
                <div className="mt-6 text-xl leading-8 text-white w-full md:w-3/4 relative">
                  <span className="relative z-10">
                    Más que una plataforma de reclutamiento, un asistente que te
                    ayuda a{" "}
                  </span>
                  {/* Increase the height of the container */}
                  <div className="inline-flex h-[1.6em] overflow-hidden italic relative z-10">
                    <ul className="animate-word-rotate block">
                      <li className="block h-[1.6em] leading-[1.6em]">
                        crecer tu equipo
                      </li>
                      <li className="block h-[1.6em] leading-[1.6em]">
                        publicar vacantes
                      </li>
                      <li className="block h-[1.6em] leading-[1.6em]">
                        mejorar procesos
                      </li>
                      <li className="block h-[1.6em] leading-[1.6em]">
                        tomar decisiones
                      </li>
                      <li className="block h-[1.6em] leading-[1.6em]">
                        encontrar talento
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Add secondary CTA button */}
                <div className="mt-10 flex items-center gap-x-6 relative">
                  <button
                    onClick={scrollToContact}
                    className="animate-float-in px-8 py-3 text-lg font-semibold text-white shadow-sm hover:bg-primary/90 transition-all bg-white/10 backdrop-blur-sm rounded-full p-2 hover:scale-105 hover:shadow-lg"
                  >
                    Solicitar Demo
                  </button>
                  <button
                    onClick={scrollToFeatures}
                    className="hidden md:flex text-white hover:text-primary transition-colors duration-200 items-center gap-2 cursor-pointer group"
                  >
                    Ver características
                    <svg
                      className="w-5 h-5 transition-transform duration-200 transform group-hover:translate-x-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14m-4 4l4-4-4-4" />
                    </svg>
                  </button>
                </div>

                {/* Replace trust indicators with metrics */}
                <div className="mt-16 pt-8 border-t border-white/10">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="text-center">
                      <div className="text-3xl text-white">+1000</div>
                      <p className="text-sm text-white/70">
                        Vacantes Publicadas
                      </p>
                    </div>
                    <div className="text-center">
                      <div className="text-3xl text-white">95%</div>
                      <p className="text-sm text-white/70">Tasa de Éxito</p>
                    </div>
                    <div className="text-center">
                      <div className="text-3xl text-white">24/7</div>
                      <p className="text-sm text-white/70">Soporte </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column - Device Previews */}
              <div className="relative hidden lg:block h-[700px]">
                {/* Desktop mockup */}
                <div
                  className="absolute top-[2%] left-[2%] w-[95%] bg-white/10 backdrop-blur-sm rounded-lg 
                  shadow-[0_20px_50px_rgba(0,0,0,0.3)] 
                  animate-slide-in-right opacity-0"
                >
                  {/* Add review stars */}
                  <div className="absolute -top-8 -left-4 flex items-center gap-2 bg-white/10 p-3 rounded-lg">
                    <div className="flex gap-1">
                      {[...Array(5)].map((_, i) => (
                        <svg
                          key={i}
                          className="w-5 h-5 text-yellow-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>
                    <span className="text-white text-sm">5.0</span>
                  </div>

                  {/* Existing mockup content */}
                  <div className="w-full rounded-lg border-4 border-white/20 overflow-hidden">
                    <div className="w-full h-8 bg-white/20 flex items-center px-2">
                      <div className="flex gap-1">
                        <div className="w-3 h-3 rounded-full bg-white/40"></div>
                        <div className="w-3 h-3 rounded-full bg-white/40"></div>
                        <div className="w-3 h-3 rounded-full bg-white/40"></div>
                      </div>
                    </div>
                    <img
                      src={desktop}
                      alt="Desktop preview"
                      className="w-full h-auto object-cover rounded-b-lg"
                    />
                  </div>
                </div>

                {/* Phone mockup - Updated positioning */}
                <div
                  className="absolute top-[40%] right-[-5%] w-[30%] bg-gray/50 border-black/20 backdrop-blur-sm rounded-[2rem] 
                  shadow-[0_20px_60px_-15px_rgba(0,0,0,0.5)] 
                  animate-slide-in-left opacity-0"
                  style={{ transform: "translateY(-50%)" }}
                >
                  <div className="w-full rounded-[2rem] border-4 border-black/20 overflow-hidden">
                    <div className="w-full bg-white">
                      <div className="w-[40%] h-3 bg-black/20 rounded-b-xl mx-auto"></div>
                    </div>
                    <img
                      src={mobile}
                      alt="Mobile preview"
                      className="w-full h-auto object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="py-24 sm:py-32">
          <div
            ref={featuresRef}
            className="mx-auto max-w-7xl     px-0  sm:px-6 md:px-8"
          >
            {/* Title section remains the same */}
            <div className="mx-auto max-w-2xl text-center mb-8">
              <h2 className="text-4xl tracking-tight text-white sm:text-6xl mb-4">
                Todo lo que necesitas
              </h2>
              <p className="text-xl leading-8 text-white/80">
                Herramientas diseñadas para optimizar tu proceso de
                reclutamiento
              </p>
            </div>

            <div className="backdrop-blur-xl bg-white/10 rounded-3xl p-4 sm:p-8 md:p-12 shadow-2xl">
              {/* Feature tabs */}
              <div className="flex justify-center mt-4 mb-8 gap-2 sm:gap-4">
                {mainFeatures.map((feature) => (
                  <button
                    key={feature.title}
                    onClick={() => setSelectedFeature(feature)}
                    className={`px-3 sm:px-6 py-2 rounded-full transition-all text-sm sm:text-base ${
                      selectedFeature.title === feature.title
                        ? "bg-white text-gray-900 shadow-lg"
                        : "text-white hover:bg-white/10"
                    }`}
                  >
                    {feature.title}
                  </button>
                ))}
              </div>

              {/* Main Features with Image */}
              <div className="mx-auto mt-8 max-w-7xl">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-12">
                  {/* Image Column - Moved up for mobile */}
                  <div className="lg:order-2 bg-white/90 rounded-2xl shadow-sm p-2 aspect-[4/3]">
                    <img
                      src={selectedFeature.image}
                      alt={selectedFeature.title}
                      className="w-full h-full object-cover rounded-xl transition-all duration-500"
                    />
                  </div>

                  {/* Features Column */}
                  <div className="space-y-4 lg:order-1">
                    {mainFeatures.map((feature) => {
                      const isSelected =
                        selectedFeature.title === feature.title;
                      return (
                        <div
                          key={feature.title}
                          className={`relative p-6 sm:p-8 rounded-2xl transition-all duration-300 cursor-pointer
                            ${
                              isSelected
                                ? "bg-white/90 shadow-lg transform -translate-x-2"
                                : "bg-white/80 hover:bg-white/85 hover:-translate-x-1"
                            }
                          `}
                          onClick={() => setSelectedFeature(feature)}
                        >
                          {/* Selection indicator */}
                          {isSelected && (
                            <div className="absolute left-0 top-0 bottom-0 w-1 bg-primary rounded-l-2xl" />
                          )}

                          <div className="flex items-center gap-x-4">
                            <div
                              className={`p-2 rounded-xl transition-colors duration-300 
                              ${isSelected ? "bg-primary/10" : "bg-white/50"}`}
                            >
                              <feature.icon
                                className={`h-6 w-6 transition-colors duration-300
                                  ${
                                    isSelected
                                      ? "text-primary"
                                      : "text-gray-600"
                                  }`}
                              />
                            </div>
                            <h3
                              className={`text-lg font-semibold leading-8 transition-colors duration-300
                              ${
                                isSelected ? "text-gray-900" : "text-gray-700"
                              }`}
                            >
                              {feature.title}
                            </h3>
                          </div>
                          <p
                            className={`mt-4 text-base leading-7 transition-colors duration-300
                            ${isSelected ? "text-gray-600" : "text-gray-500"}`}
                          >
                            {feature.description}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Secondary Features */}
              <div className="mx-auto mt-20 max-w-7xl">
                {/* Add decorative separator with text */}
                <div className="flex items-center gap-4 mb-14">
                  <div className="flex-grow h-px bg-gradient-to-r from-transparent via-white/50 to-transparent"></div>
                  <div className="text-white/70 text-base md:text-lg whitespace-nowrap">
                    Y mucho más
                  </div>
                  <div className="flex-grow h-px bg-gradient-to-r from-transparent via-white/50 to-transparent"></div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
                  {secondaryFeatures.map((feature) => (
                    <div
                      key={feature.title}
                      className="relative bg-white/80 p-6 md:p-8 rounded-2xl shadow-sm hover:shadow-md transition-all"
                    >
                      <div className="flex items-center gap-x-3 md:gap-x-4">
                        <feature.icon className="h-6 w-6 md:h-8 md:w-8 text-primary" />
                        <h3 className="text-base md:text-lg font-semibold leading-7 md:leading-8 text-gray-900">
                          {feature.title}
                        </h3>
                      </div>
                      <p className="mt-3 md:mt-4 text-sm md:text-base leading-6 md:leading-7 text-gray-600">
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="py-24 sm:py-32 bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center mb-16">
              <h2 className="text-3xl tracking-tight text-gray-900 sm:text-4xl">
                Resultados que hablan por sí mismos
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-600">
                Empresas que confían en Laburo mejoran sus métricas de
                reclutamiento significativamente
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {[
                {
                  value: "70%",
                  label: "Menos tiempo en contratación",
                  description:
                    "Reduce el tiempo de contratación de semanas a días",
                },
                {
                  value: "3x",
                  label: "Más candidatos calificados",
                  description: "Mejora la calidad de tu pipeline de candidatos",
                },
                {
                  value: "85%",
                  label: "Automatización de tareas",
                  description: "Libera a tu equipo de tareas repetitivas",
                },
              ].map((stat, index) => (
                <div key={index} className="relative group">
                  {/* Card container with layered effect */}
                  <div className="absolute inset-0 bg-gradient-to-r from-primary/20 to-primary/10 transform rotate-1 rounded-2xl transition-all duration-300 group-hover:rotate-2 group-hover:scale-105" />
                  <div className="absolute inset-0 bg-white/80 backdrop-blur-sm transform -rotate-1 rounded-2xl transition-all duration-300 group-hover:-rotate-2 group-hover:scale-105" />

                  {/* Main card content */}
                  <div className="relative bg-white p-8 rounded-2xl shadow-[0_4px_20px_-4px_rgba(0,0,0,0.1)] transform transition-all duration-300 group-hover:-translate-y-1 group-hover:shadow-[0_8px_30px_-4px_rgba(0,0,0,0.2)]">
                    {/* Decorative elements */}
                    <div className="absolute top-0 left-0 w-24 h-24 bg-gradient-to-br from-primary/5 to-transparent rounded-tl-2xl" />
                    <div className="absolute bottom-0 right-0 w-24 h-24 bg-gradient-to-tl from-primary/5 to-transparent rounded-br-2xl" />

                    {/* Content */}
                    <div className="relative z-10">
                      <span className="block text-4xl font-bold text-primary mb-4">
                        {stat.value}
                      </span>
                      <p className="text-lg leading-8 text-gray-900 mb-2">
                        {stat.label}
                      </p>
                      <p className="text-sm text-gray-500">
                        {stat.description}
                      </p>
                    </div>

                    {/* Bottom border gradient */}
                    <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-primary/50 via-primary to-primary/50 rounded-full transform transition-all duration-300 group-hover:h-1.5" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Companies Carousel Section */}
        <div className="py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center mb-16">
              <h2 className="text-3xl tracking-tight text-white sm:text-4xl">
                Empresas que confían en nosotros
              </h2>
            </div>

            {/* Infinite Carousel */}
            <div className="relative overflow-hidden">
              <div className="flex animate-infinite-scroll space-x-4 sm:space-x-12 whitespace-nowrap">
                {[...Array(3)].map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center space-x-4 sm:space-x-12"
                  >
                    {[
                      company1,
                      company2,
                      company3,
                      company4,
                      company5,
                      company6,
                      company7,
                    ].map((company, companyIndex) => (
                      <div
                        key={`${index}-${companyIndex}`}
                        className="w-24 h-24 sm:w-40 sm:h-40 backdrop-blur-xl bg-white/70 rounded-2xl shadow-sm flex items-center justify-center p-3 sm:p-6"
                      >
                        <img
                          src={company}
                          alt={`Company ${companyIndex + 1}`}
                          className="max-w-full max-h-full object-contain rounded-lg"
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <div className="relative isolate px-3 sm:px-6 py-12 sm:py-24 lg:px-8 bg-white">
          <div className="mx-auto max-w-7xl" ref={contactRef}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              {/* Left Column - Text Content */}
              <div className="text-center lg:text-left max-w-xl mx-auto lg:max-w-none">
                <h2 className="text-3xl tracking-tight text-gray-900 sm:text-4xl">
                  Todo esto y más a un precio hecho para ti
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Nuestro equipo está listo para brindarte una solución
                  personalizada que se adapte a tus necesidades específicas.
                </p>
                {/* Add decorative line */}
                <div className="my-8 w-full h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent"></div>
                <div className="space-y-6 max-w-md mx-auto lg:mx-0">
                  <div className="flex flex-col lg:flex-row items-center gap-3 justify-center lg:justify-start">
                    <BoltIcon className="h-8 w-8 lg:h-6 lg:w-6 text-primary flex-shrink-0 mb-2 lg:mb-0" />
                    <span className="text-gray-700 text-center lg:text-left">
                      Implementación rápida y sencilla
                    </span>
                  </div>
                  <div className="flex flex-col lg:flex-row items-center gap-3 justify-center lg:justify-start">
                    <ProcessIcon className="h-8 w-8 lg:h-6 lg:w-6 text-primary flex-shrink-0 mb-2 lg:mb-0" />
                    <span className="text-gray-700 text-center lg:text-left">
                      Soporte personalizado
                    </span>
                  </div>
                  <div className="flex flex-col lg:flex-row items-center gap-3 justify-center lg:justify-start">
                    <DatabaseIcon className="h-8 w-8 lg:h-6 lg:w-6 text-primary flex-shrink-0 mb-2 lg:mb-0" />
                    <span className="text-gray-700 text-center lg:text-left">
                      Planes flexibles y escalables
                    </span>
                  </div>
                </div>
              </div>

              {/* Right Column - Contact Form */}
              <div className="w-full max-w-xl mx-auto lg:max-w-none">
                <ContactUsForm />
              </div>
            </div>
          </div>
        </div>

        <Footer type={2} />
      </div>
    </div>
  );
}

export default BusinessLanding;
