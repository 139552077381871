import React, { useState, useRef, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ApplicantInt } from "../../../typescript/interfaces/AppInterface";
import StateColors from "../../applicants-sidebar/StateColors";
import { applicantStateOption } from "../../../typescript/interfaces/JobInterface";
import { useApplicantState } from "../../routing/ApplicantStateContext";

interface ApplicantStateDropDownProps {
  applicant: ApplicantInt;
  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: string,
    stateOption?: applicantStateOption
  ) => Promise<void>;
  stateOptions: applicantStateOption[];
  loading?: boolean;
}

function ApplicantStateDropDown({
  applicant,
  setApplicantsState,
  stateOptions,
  loading,
}: ApplicantStateDropDownProps) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { defaultOptions } = useApplicantState();

  const options = stateOptions?.length ? stateOptions : defaultOptions;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="asdd-container" ref={dropdownRef}>
      <div
        className={`asdd-box-header min-h-[49px] flex justify-between items-center ${
          open ? "asdd-box-header-open" : ""
        }`}
        onClick={() => {
          if (!loading) {
            setOpen(!open);
          }
        }}
      >
        {loading ? (
          <div>...</div>
        ) : (
          <div className="flx items-center w-full ">
            <div className="w-full">
              <StateColors
                state={
                  options.find((o) => o.id === applicant.state)?.name || ""
                }
                color={
                  options.find((o) => o.id === applicant.state)?.color || ""
                }
              />
            </div>
            <div className="">
              <ArrowDropDownIcon
                style={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className={`asdd-box ${!open && "hide"} z-50`}>
        {options.map((option, index) => {
          if (option.name !== applicant.state) {
            return (
              <div
                className="asdd-box-item"
                key={index}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setApplicantsState(e, applicant, option.id, option);

                  setOpen(false);
                }}
              >
                <StateColors state={option.name} color={option.color} />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default ApplicantStateDropDown;
