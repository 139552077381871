import axios from "axios";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
const uploadCommentAttachmentImageToFirebase = async (
  file: File,
  userId: string,
  jobId: string,
  applicantId: string
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    // Create FormData instance
    const formData = new FormData();

    // Append all data to FormData
    formData.append("attachment", file);
    formData.append("userId", userId);
    formData.append("jobId", jobId);
    formData.append("applicantId", applicantId);

    const response = await axios.post(
      `${environment.endpoints.uploadCommentAttachmentImageToFirebase}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error uploading comment attachment image to Firebase:",
      error
    );
    throw error;
  }
};

export default uploadCommentAttachmentImageToFirebase;
