import axios from "axios";
import { ApplicantInt } from "../typescript/interfaces/AppInterface";
import { applicantStateOption } from "../typescript/interfaces/JobInterface";
import { environment } from "../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../firebase";

const getPostedJobsById = async (
  id: string,
  filters?: { [key: string]: string },
  limit?: number,
  page?: number,
  companyId?: string
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.get(
      `${
        environment.endpoints.getUserJobsById
      }?id=${id}&companyId=${companyId}&filters=${encodeURIComponent(
        JSON.stringify(filters) || ""
      )}&limit=${limit}&page=${page}`,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

const getApplicantsById = async (id: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.get(
      `${environment.endpoints.getApplicantsById}?id=${id}`,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

const updateApplicantById = async (data: ApplicantInt) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    await axios.post(`${environment.endpoints.updateApplicant}`, data, {
      headers: {
        "X-Firebase-AppCheck": appCheckToken.token,
      },
    });
    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return false;
  }
};

const checkApplicantExists = async (jobId: string, email: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.post(
      `${environment.endpoints.checkApplicantInDB}`,
      { email, jobId },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    if (result.data.message === "Applicant exists") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Unexpected error:", error);
  }
};

const markApplicantAsSeen = async (data: ApplicantInt) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    await axios.post(`${environment.endpoints.markApplicantAsSeen}`, data, {
      headers: {
        "X-Firebase-AppCheck": appCheckToken.token,
      },
    });
    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return false;
  }
};

// todo : change to south america
const handleApplicantState = async (
  data: ApplicantInt,
  state: string,
  stateOption?: applicantStateOption
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    await axios.post(
      `${environment.endpoints.handleApplicantState}`,
      {
        data,
        newState: state,
        stateOption,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

const filterApplicantsByState = (applicants: ApplicantInt[], state: string) => {
  return applicants.filter((applicant) => applicant.state === state);
};

const parseResume = async (file: File) => {
  const formData = new FormData();
  formData.append("resume", file);
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.post(
      `${environment.endpoints.applicantCVPreProcessingHandler}`,
      formData,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};

const getHighestEducationLevel = (applicant: ApplicantInt) => {
  return applicant.educationList?.[0]?.nivel_educativo || "";
};

const updateApplicantReviewScore = async (
  id: string,
  score: number,
  posterId: string
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  const result = await axios.post(
    `${environment.endpoints.updateApplicantReviewScore}`,
    {
      id,
      score,
      posterId: posterId,
    },
    {
      headers: {
        "X-Firebase-AppCheck": appCheckToken.token,
      },
    }
  );
  return result.data;
};

const addApplicant = async (data: ApplicantInt) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.post(
      `${environment.endpoints.addApplicant}`,
      data,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Return the error response data which contains your backend message
      return error.response.data;
    }
    console.error("Error adding applicant:", error);
    throw error;
  }
};

const getStateOptionsByJobId = async (jobId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  const result = await axios.post(
    `${environment.endpoints.findStateOptionsByJobId}`,
    { jobId },
    {
      headers: {
        "X-Firebase-AppCheck": appCheckToken.token,
      },
    }
  );

  return result.data.result.applicantStateOptions;
};

const deleteApplicant = async (applicantId: string, resumeUrl?: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.delete(
      `${environment.endpoints.deleteApplicant}?applicantId=${applicantId}&resumeUrl=${resumeUrl}`,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Return the error response data which contains your backend message
      return error.response.data;
    }
    console.error("Error deleting applicant:", error);
    throw error;
  }
};

const handleApplicantRejectedState = async (
  applicantId: string,
  state: boolean,
  companyId: string,
  jobId: string,
  authorName: string
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.post(
      `${environment.endpoints.handleApplicantRejectedState}`,
      { applicantId, state, companyId, jobId, authorName },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error handling applicant rejected state:", error);
    throw error;
  }
};

const uploadCVToCompanyPage = async (data: ApplicantInt, companyId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    console.log("data", data);
    const result = await axios.post(
      `${environment.endpoints.uploadCvToCompanyPage}`,
      {
        applicantData: data,
        companyId,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error uploading CV to company page:", error);
    return {
      success: false,
      message: "Error uploading CV to company page",
    };
  }
};

export {
  getPostedJobsById,
  getApplicantsById,
  updateApplicantById,
  checkApplicantExists,
  markApplicantAsSeen,
  handleApplicantState,
  filterApplicantsByState,
  parseResume,
  getHighestEducationLevel,
  updateApplicantReviewScore,
  addApplicant,
  getStateOptionsByJobId,
  deleteApplicant,
  handleApplicantRejectedState,
  uploadCVToCompanyPage,
};
