import React, { useEffect, useState } from "react";
import { AutomationAction } from "../../../../typescript/interfaces/AutomationTypes";
import { EmailTemplate } from "../../../../typescript/interfaces/EmailType";
import { upsertEmailTemplate } from "../../../../utils/email/upsertEmailTemplate";
import { getEmailTemplates } from "../../../../utils/email/getEmailTemplates";
import deleteEmailTemplate from "../../../../utils/email/deleteEmailTemplate";
import { useUser } from "../../../routing/UserContext";
import TextEditor2 from "../../../text-editor/TextEditor2";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNotification } from "../../../context/NotificationContext";
import ConfirmPopUp from "../../tools/ConfirmPopUp";
import InfoIcon from "@mui/icons-material/Info";
import SearchableDropDownWithId from "../../../widgets/SearchableDropDownWithId";

interface EmailAutomationTemplateProps {
  action: AutomationAction;
  updateAction: (updates: Partial<AutomationAction>) => void;
}

const EmailVariablesToolTip = () => (
  <div className="absolute right-0 w-72 p-3 bg-gray-800 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 transform translate-y-2 group-hover:translate-y-0 z-50">
    <div className="relative">
      <div className="absolute -top-2 right-2 w-3 h-3 bg-gray-800 transform rotate-45"></div>
      <p>
        Personaliza tu correo utilizando variables dinámicas:
        <br />• {"{{nombre}}"} - Nombre del candidato
        <br />• {"{{cargo}}"} - Nombre del cargo
        <br />• {"{{empresa}}"} - Nombre de la empresa
        <br />
        <span className="text-gray-300 text-xs mt-2">
          Ejemplo: "Hola {"{{ nombre }}"}, gracias por aplicar al puesto de{" "}
          {"{{ cargo }}"}"
        </span>
      </p>
    </div>
  </div>
);

function EmailAutomationTemplate({
  action,
  updateAction,
}: EmailAutomationTemplateProps) {
  const { companyData } = useUser();
  const { showNotification } = useNotification();
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [newTemplate, setNewTemplate] = useState<Partial<EmailTemplate>>({
    name: "",
    subject: "",
    content: "",
  });
  const [savingTemplateLoading, setSavingTemplateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<
    Partial<EmailTemplate>
  >({
    name: "",
    subject: "",
    content: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [originalTemplate, setOriginalTemplate] = useState<
    Partial<EmailTemplate>
  >({
    name: "",
    subject: "",
    content: "",
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      if (!companyData?._id) return;

      try {
        setIsLoading(true);
        setError(null);
        const response = await getEmailTemplates(companyData._id);
        setTemplates(response);
      } catch (error) {
        setError(
          "Error al cargar las plantillas. Por favor, intente nuevamente."
        );
        console.error("Error fetching templates:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, [companyData?._id]);

  useEffect(() => {
    if (templates.length > 0 && action.config.emailTemplate) {
      const selectedEmailTemplate = templates.find(
        (t) => t.id === action.config.emailTemplate
      );
      if (selectedEmailTemplate) {
        setSelectedTemplate(selectedEmailTemplate);
        setOriginalTemplate(selectedEmailTemplate);
        setIsEditing(true);
      }
    }
  }, [templates, action.config.emailTemplate]);

  const resetNewTemplateForm = () => {
    setNewTemplate({
      name: "",
      subject: "",
      content: "",
    });
  };

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Destinatario
        </label>
        <select
          value={action.config.recipientType || ""}
          onChange={(e) =>
            updateAction({
              config: {
                ...action.config,
                recipientType: e.target.value as "APPLICANT" | "TEAM" | "BOTH",
              },
            })
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        >
          <option value="APPLICANT">Candidato</option>
          <option value="TEAM">Equipo</option>
          <option value="BOTH">Ambos</option>
        </select>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <label className="block text-sm font-medium text-gray-700">
            Plantilla de Email
          </label>
          <button
            onClick={(e) => {
              e.preventDefault();
              setSelectedTemplate({
                name: "",
                subject: "",
                content: "",
              });

              setIsNewTemplate(true);
              resetNewTemplateForm();
              setIsEditing(false);
            }}
            className="inline-flex items-center py-1 px-2 justify-center text-sm font-medium text-laburo-green border border-laburo-green rounded-full hover:bg-gray-100"
          >
            Nueva
          </button>
        </div>
        {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
        <SearchableDropDownWithId
          options={
            !isLoading
              ? templates.map((template) => ({
                  name: template.name,
                  id: template.id || "",
                }))
              : []
          }
          placeholder={
            isLoading ? "Cargando plantillas..." : "Seleccionar plantilla..."
          }
          value={selectedTemplate.name}
          onChange={(selectedId) => {
            if (selectedId === "") {
              setIsEditing(false);
              setSelectedTemplate({ name: "", subject: "", content: "" });
              setOriginalTemplate({ name: "", subject: "", content: "" });
              updateAction({
                config: {
                  ...action.config,
                  emailTemplate: "",
                },
              });
              return;
            }
            setIsNewTemplate(false);
            const selected = templates.find((t) => t.id === selectedId);

            setSelectedTemplate(
              selected || { name: "", subject: "", content: "" }
            );
            setIsEditing(true);
            updateAction({
              config: {
                ...action.config,
                emailTemplate: selected?.id || "",
              },
            });
          }}
        />
      </div>

      {isEditing && !isNewTemplate && (
        <div className="space-y-4 p-4 border border-gray-200 rounded-md">
          <div
            onClick={() => setShowEditor(!showEditor)}
            className="relative cursor-pointer py-4"
          >
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center items-center">
              <span className="bg-gray-50 px-3 text-sm text-gray-500 flex items-center gap-2">
                Editar Plantilla
                {showEditor ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </span>
            </div>
          </div>

          {showEditor && (
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Nombre de la plantilla
                </label>
                <input
                  type="text"
                  value={selectedTemplate.name}
                  onChange={(e) =>
                    setSelectedTemplate({
                      ...selectedTemplate,
                      name: e.target.value,
                    })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Asunto
                </label>
                <input
                  type="text"
                  value={selectedTemplate.subject}
                  onChange={(e) =>
                    setSelectedTemplate({
                      ...selectedTemplate,
                      subject: e.target.value,
                    })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="space-y-2">
                <div className="flex items-center gap-2 mb-2 justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Contenido
                  </label>
                  <div className="relative group">
                    <InfoIcon className="text-gray-400 w-4 h-4 " />
                    <EmailVariablesToolTip />
                  </div>
                </div>
                <TextEditor2
                  editorName=""
                  htmlValue={selectedTemplate.content || ""}
                  setHTMLValue={(html) =>
                    setSelectedTemplate({ ...selectedTemplate, content: html })
                  }
                  noMarginTop={true}
                />
              </div>

              <div className="flex space-x-4 mt-4">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setShowConfirmPopUp(true);
                  }}
                  disabled={savingTemplateLoading}
                  className={`w-1/2 px-4 py-2 text-sm font-medium ${
                    savingTemplateLoading
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "text-red-600 border border-red-600 hover:bg-red-50"
                  } rounded-md`}
                >
                  Eliminar
                </button>
                <button
                  onClick={async (event) => {
                    event.preventDefault();
                    setSavingTemplateLoading(true);
                    try {
                      selectedTemplate.companyId = companyData?._id || "";
                      await upsertEmailTemplate(
                        selectedTemplate as EmailTemplate
                      );
                      const updatedTemplates = templates.map((t) =>
                        t.id === selectedTemplate.id
                          ? (selectedTemplate as EmailTemplate)
                          : t
                      );
                      setTemplates(updatedTemplates);
                      showNotification(
                        "Plantilla actualizada exitosamente",
                        "success"
                      );
                    } catch (error) {
                      console.error("Error updating template:", error);
                      showNotification(
                        "Error al actualizar la plantilla",
                        "error"
                      );
                    } finally {
                      setSavingTemplateLoading(false);
                    }
                  }}
                  disabled={
                    !selectedTemplate.name ||
                    !selectedTemplate.subject ||
                    !selectedTemplate.content ||
                    !selectedTemplate.id ||
                    (selectedTemplate.name === originalTemplate.name &&
                      selectedTemplate.subject === originalTemplate.subject &&
                      selectedTemplate.content === originalTemplate.content)
                  }
                  className={`w-1/2 px-4 py-2 text-sm font-medium ${
                    !selectedTemplate.name ||
                    !selectedTemplate.subject ||
                    !selectedTemplate.content ||
                    !selectedTemplate.id ||
                    (selectedTemplate.name === originalTemplate.name &&
                      selectedTemplate.subject === originalTemplate.subject &&
                      selectedTemplate.content === originalTemplate.content)
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "text-laburo-green border border-laburo-green hover:bg-gray-100"
                  } rounded-md transition-colors duration-150 ease-in-out`}
                >
                  {savingTemplateLoading ? "Actualizando..." : "Actualizar"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {isNewTemplate && (
        <div className="space-y-4 p-4 border border-gray-200 rounded-md">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Nombre de la plantilla
            </label>
            <input
              type="text"
              value={newTemplate.name}
              onChange={(e) =>
                setNewTemplate({ ...newTemplate, name: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Asunto
            </label>
            <input
              type="text"
              value={newTemplate.subject}
              onChange={(e) =>
                setNewTemplate({ ...newTemplate, subject: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2 mb-2 justify-between">
              <label className="block text-sm font-medium text-gray-700">
                Contenido
              </label>
              <div className="relative group">
                <InfoIcon className="text-gray-400 w-4 h-4 " />
                <EmailVariablesToolTip />
              </div>
            </div>
            <TextEditor2
              editorName=""
              htmlValue={newTemplate.content || ""}
              setHTMLValue={(html) =>
                setNewTemplate({ ...newTemplate, content: html })
              }
              noMarginTop={true}
            />
          </div>

          <button
            onClick={async (event) => {
              event.preventDefault();
              setSavingTemplateLoading(true);
              try {
                newTemplate.companyId = companyData?._id || "";
                const resultData = await upsertEmailTemplate(
                  newTemplate as EmailTemplate
                );
                const savedTemplate = {
                  ...newTemplate,
                  id: resultData.id,
                } as EmailTemplate;

                setTemplates([...templates, savedTemplate]);
                setIsNewTemplate(false);
                setSelectedTemplate(savedTemplate);
                setIsEditing(true);
                updateAction({
                  config: {
                    ...action.config,
                    emailTemplate: savedTemplate.id,
                  },
                });
                showNotification("Plantilla creada exitosamente", "success");
              } catch (error) {
                console.error("Error saving new template:", error);
                showNotification("Error al crear la plantilla", "error");
              } finally {
                setSavingTemplateLoading(false);
              }
            }}
            disabled={
              !newTemplate.name || !newTemplate.subject || !newTemplate.content
            }
            className={`mt-4 w-full px-4 py-2 text-sm font-medium ${
              !newTemplate.name || !newTemplate.subject || !newTemplate.content
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "text-laburo-green border border-laburo-green hover:bg-gray-100"
            } rounded-md transition-colors duration-150 ease-in-out`}
          >
            {savingTemplateLoading ? (
              <span>Guardando Plantilla </span>
            ) : (
              <span>Guardar Plantilla</span>
            )}
          </button>
        </div>
      )}

      {showConfirmPopUp && (
        <ConfirmPopUp
          question={
            isDeleting
              ? "Eliminando..."
              : "¿Estás seguro/a de que deseas eliminar esta plantilla?"
          }
          onConfirm={async () => {
            setIsDeleting(true);
            try {
              const updatedTemplates = templates.filter(
                (t) => t.id !== selectedTemplate.id
              );
              setTemplates(updatedTemplates);
              setIsEditing(false);
              setSelectedTemplate({
                name: "",
                subject: "",
                content: "",
              });
              await deleteEmailTemplate(selectedTemplate.id || "");
              showNotification("Plantilla eliminada exitosamente", "success");
            } catch (error) {
              console.error("Error deleting template:", error);
              showNotification("Error al eliminar la plantilla", "error");
            } finally {
              setIsDeleting(false);
              setShowConfirmPopUp(false);
            }
          }}
          onCancel={() => {
            setShowConfirmPopUp(false);
          }}
        />
      )}
    </div>
  );
}

export default EmailAutomationTemplate;
