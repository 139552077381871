import React from "react";
import "../assets/styles/navbar.css";
import "../assets/styles/global.css";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { getAuth } from "firebase/auth";
import useAuth from "../utils/useAuth";
import SubBar from "./dashboard/SubBar";
import { useUser } from "./routing/UserContext";
import { handleSignOutGlobal } from "../utils/globalUtils";
import { useApplicants } from "./routing/ApplicantContext";
import { useApplicantState } from "./routing/ApplicantStateContext";
import { useJobPositions } from "./routing/JobPositionsContext";
import ProfileSettings from "./widgets/ProfileSettings";

interface navProps {
  scrollPast: boolean;
  hidePublish?: boolean;
  hideSearch?: boolean;
  hideHireButton?: boolean;
  hideDash?: boolean;
  hideSignIn?: boolean;
  lockNavbar?: boolean;
  highlightJobs?: boolean;
  highlightLogin?: boolean;
  highlightPost?: boolean;
  highlightDash?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<string>>;
  subbarLoading?: boolean;
  jobsAvailable?: number;
  hidePanel?: boolean;
  blockLogoClick?: boolean;
  showProfileSettings?: boolean;
}
function Navbar(props: navProps) {
  const { clearUserData, userData } = useUser();
  const { clearAll: clearApplicants } = useApplicants();
  const { clearState: clearApplicantState } = useApplicantState();
  const { clearPositions: clearJobPositions } = useJobPositions();

  const auth = getAuth();
  const { user } = useAuth();
  const [navOpen, setNavOpen] = useState(false);

  const toggleMenu = () => {
    setNavOpen(!navOpen);
  };

  const handleOutsideClick = () => {
    setNavOpen(false);
  };

  // useEffect(() => {
  //   const auth = getAuth();
  //   const user = auth.currentUser;

  //   if (user != null) {
  //     setShowDashboard(true);
  //   }
  // }, []);
  const handleSignOut = async () => {
    // First clear Firebase and localStorage
    await handleSignOutGlobal(auth);

    // Then clear all contexts
    clearUserData?.();
    clearApplicants?.();
    clearApplicantState?.();
    clearJobPositions?.();
  };
  return (
    <div
      className="nav-container"
      style={{
        backgroundColor: `${props.scrollPast ? "white" : ""}`,
        position: props.lockNavbar ? "static" : "fixed",
      }}
    >
      {/* start container here */}
      <div className="nav-box">
        <div className="flx" style={{ alignItems: "center" }}>
          <Link
            to={!props.blockLogoClick ? "/" : ""}
            className="laburo-logo-txt"
            style={{
              textDecoration: "none",
              color: `${props.scrollPast ? "black" : "white"}`,
            }}
          >
            Laburo
          </Link>
          <div className="desktop-only">
            {props.setPage && (
              <SubBar
                setSelectedPage={props.setPage}
                loading={props.subbarLoading || false}
              />
            )}
          </div>
        </div>

        <div className=" nav-inner-container ">
          {/* mobile */}
          <div
            className="flx"
            style={{
              gap: "20px",
              marginRight: "10px",
            }}
          >
            {!props.hideSignIn && (
              <div
                className="flx  mobile-only "
                style={{ alignItems: "center" }}
              >
                {/* if signed in show dash else person */}

                {user !== null ? (
                  <Link to="/dashboard">
                    <SpaceDashboardIcon
                      style={{
                        fontSize: "30px",
                        color: `${props.scrollPast ? "black" : "white"}`,
                        opacity: props.hideDash ? "0.4" : "1",
                      }}
                    />
                  </Link>
                ) : (
                  <Link to={"/ingresar"}>
                    <PersonIcon
                      style={{
                        fontSize: "30px",
                        color: `${props.scrollPast ? "black" : "white"}`,
                        opacity: props.hideSignIn ? "0.4" : "1",
                      }}
                    />
                  </Link>
                )}
              </div>
            )}

            {!props.hideHireButton && (
              <Link
                to="/empresas"
                className="mobile-only w100"
                style={{
                  display: "flex",
                  height: "100%",
                  padding: "3px 0px",
                  textDecoration: "none",
                }}
              >
                <div className="post-job-mobile-btn mobile-only">
                  <div>Contratar</div>
                </div>
              </Link>
            )}
            <div
              onClick={toggleMenu}
              className="mobile-only  "
              style={{
                display: "flex",
                alignItems: "center",

                height: "100% !important",
              }}
            >
              <MenuIcon
                style={{
                  fontSize: "35px",
                  display: "flex",

                  color: `${props.scrollPast ? "black" : "white"}`,
                }}
              />
            </div>
          </div>
          {/* desktop */}
          <div
            className="flx "
            style={{
              alignItems: "center",

              width: "100%",
              gap: "20px",
            }}
          >
            {!user && !props.hideSearch && (
              <div
                className="txt-s4 desktop-only nav-text-desk-search "
                style={{
                  color: `${props.scrollPast ? "black" : "white"}`,
                  opacity: props.highlightJobs ? "0.2" : "1",
                }}
              >
                <Link to={"/jobs"} style={{ whiteSpace: "nowrap" }}>
                  Buscar Trabajos
                </Link>
              </div>
            )}

            <div
              className="person-icon txt-s4 md:mr-[10px]"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              {user != null ? (
                !props.hidePanel && (
                  <div className="desktop-only">
                    <Link
                      className="desktop-only"
                      to="/dashboard"
                      style={{
                        color: `${props.scrollPast ? "black" : "white"}`,
                        opacity: props.highlightDash ? "0.2" : "1",
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <SpaceDashboardIcon
                        style={{ fontSize: "20px" }}
                        className=""
                      />
                      Panel de Control
                    </Link>
                  </div>
                )
              ) : (
                <div
                  className="txt-s4 desktop-only nav-text-desk-search "
                  style={{
                    color: `${props.scrollPast ? "black" : "white"}`,
                    opacity: props.highlightLogin ? "0.2" : "1",
                  }}
                >
                  {!props.hideSignIn && (
                    <Link to={"/ingresar"} style={{ whiteSpace: "nowrap" }}>
                      Iniciar Sesión
                    </Link>
                  )}
                </div>
              )}
            </div>
            {!props.showProfileSettings
              ? !props.hideHireButton && (
                  <Link
                    to={"/empresas"}
                    className="txt-s4 desktop-only nav-text nav-txt-desk-job link-style"
                    style={{
                      whiteSpace: "nowrap",
                      opacity: props.highlightPost ? "0.2" : "1",
                    }}
                  >
                    Contratar con Laburo
                  </Link>
                )
              : user !== null && (
                  <div
                    className="txt-s4 desktop-only cursor-pointer"
                    style={{
                      color: `${props.scrollPast ? "black" : "white"}`,
                      backgroundColor: "inherit",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* <LogoutIcon style={{ fontSize: "20px" }} /> */}
                    {/* <div>Cerrar Sesión</div> */}
                    <ProfileSettings
                      firstName={userData?.firstName as string}
                      lastName={userData?.lastName as string}
                      avatarUrl=""
                      onLogout={() => {
                        // Your logout logic here
                        handleSignOut();
                      }}
                    />
                  </div>
                )}
          </div>
        </div>
      </div>
      {/* end container here */}

      {/* nav menu */}

      {navOpen && (
        <div className="nav-menu-block" onClick={handleOutsideClick}></div>
      )}
      <div className={`nav-menu ${navOpen ? "open" : ""}`}>
        <div className="nav-menu-items">
          <div>
            <Link to="/" className="link-style">
              Pagina Principal
            </Link>
          </div>
          {!user && (
            <div>
              <Link to="/jobs" className="link-style">
                Buscar Trabajos
              </Link>
            </div>
          )}

          <div>
            {user === null
              ? !props.hideHireButton && (
                  <Link to="/empresas" className="link-style">
                    Contratar con Laburo
                  </Link>
                )
              : props.hidePanel && (
                  <Link to="/dashboard" className="link-style">
                    Panel De Control
                  </Link>
                )}
          </div>

          {/* cerrar sesion */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
