import React, { useState, useEffect, useRef } from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import ApplicantSideInfo from "../applicants-sidebar/ApplicantSideInfo";

// context

import { useApplicants } from "../routing/ApplicantContext";

import ReviewStars from "../applicants-sidebar/ReviewStars";
import AddApplicantButton from "./tools/AddApplicantButton";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";
import { DEPARTMENTS } from "../../utils/constants/Departments";
import SearchableDropDown from "../widgets/SearchableDropDown";
import { getPositionsForDepartment } from "../../utils/globalUtils";

interface ApplicantListProps {
  companyId: string;
}

// Add custom hook for debouncing
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

// Update initialFilters to include department and position
const initialFilters = {
  sortDate: "desc",
  sortName: "",
  sortState: "",
  workExperienceYears: "",
  seen: "",
  department: "",
  position: "",
};

// Add this new component near the other component definitions
const LoadingOverlay = () => (
  <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-20">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-laburo-green"></div>
  </div>
);

function ApplicantList({ companyId }: ApplicantListProps) {
  const {
    companyApplicants,
    companyLoading: loading,
    hasMoreCompanyApplicants: hasMore,
    fetchCompanyApplicants,
    selectedCompanyApplicant: selectedApplicant,
    setSelectedCompanyApplicant: setSelectedApplicant,
    updateApplicantState: contextUpdateApplicantState,
    companyApplicantMetadata,
  } = useApplicants();

  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [page, setPage] = useState(1);
  const limit = 20;
  const [popOpen, setPopOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const observerTarget = useRef<HTMLTableRowElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // Add this new state
  const [isSearching, setIsSearching] = useState(false);

  // Update intersection observer to use context values
  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !isFetching && !loading) {
          setIsFetching(true);
          try {
            await fetchCompanyApplicants(
              companyId,
              page + 1,
              limit,
              debouncedSearchTerm,
              filters
            );
            setPage((prev) => prev + 1);
          } catch (error) {
            console.error("Error fetching more applicants:", error);
          } finally {
            setIsFetching(false);
          }
        }
      },
      { threshold: 0.1, rootMargin: "100px" }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [
    hasMore,
    loading,
    isFetching,
    page,
    companyId,
    limit,
    debouncedSearchTerm,
    filters,
    fetchCompanyApplicants,
  ]);

  // Update the useEffect for initial fetch to show loading state
  useEffect(() => {
    const fetchInitialApplicants = async () => {
      try {
        setIsSearching(true);
        setPage(1);
        await fetchCompanyApplicants(
          companyId,
          1,
          limit,
          debouncedSearchTerm,
          filters
        );
      } catch (error) {
        console.error("Error fetching initial applicants:", error);
      } finally {
        setIsSearching(false);
      }
    };

    fetchInitialApplicants();
  }, [companyId, limit, debouncedSearchTerm, fetchCompanyApplicants, filters]);

  // Update filter handling to match backend expectations
  const handleFilterChange = (filterKey: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  // Update state handling to use context
  const setApplicantsState = async (
    event: React.MouseEvent<HTMLDivElement>,
    applicant: ApplicantInt,
    state: string,
    stateOption?: applicantStateOption
  ) => {
    event.stopPropagation();
    const applicantCopy = { ...applicant, state: state };
    if (stateOption) {
      applicantCopy.stateOption = stateOption;
    }
    setSelectedApplicant(applicantCopy);
    await contextUpdateApplicantState(applicant, state, "company", stateOption);
  };

  const handleApplicantClick = (applicant: ApplicantInt) => {
    setSelectedApplicant(applicant);
    setPopOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setPopOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setIsFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFilterDropdownOpen]);

  // Add this new function
  const handleClearFilters = () => {
    setFilters(initialFilters);
    setSearchTerm("");
  };

  // Add this new component for the table header
  const TableHeader = () => (
    <thead className="bg-gray-50 border-b border-gray-200 sticky top-0 z-10">
      <tr>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Nombre
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Estado
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Calificación
        </th>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Fecha
        </th>
      </tr>
    </thead>
  );

  // Update the ApplicantCard component to be a table row
  const ApplicantTableRow: React.FC<{
    applicant: ApplicantInt;
    handleApplicantClick: (applicant: ApplicantInt) => void;
  }> = ({ applicant, handleApplicantClick }) => {
    return (
      <tr
        onClick={() => handleApplicantClick(applicant)}
        className="bg-white border-b border-gray-100 hover:bg-gray-50 transition-colors cursor-pointer"
      >
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 flex-shrink-0 bg-gradient-to-br from-laburo-green from-10% via-emerald-400 to-cyan-500 rounded-full flex justify-center items-center">
              <p className="font-semibold text-white text-sm">
                {applicant.name
                  .split(" ")
                  .map((word: string) => word[0])
                  .slice(0, 2)
                  .join("")
                  .toUpperCase()}
              </p>
            </div>
            <div className="font-medium text-gray-900">{applicant.name}</div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span
            className="px-2 py-1 text-sm rounded-full"
            style={{
              color: "white",

              backgroundColor: applicant.stateOption?.color,
            }}
          >
            {applicant.stateOption?.name}
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <ReviewStars applicant={applicant} source="company" readOnly={true} />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {new Date(applicant.date).toLocaleDateString("es-ES")}
        </td>
      </tr>
    );
  };

  // Replace TableShimmer with TableLoadingRows
  const TableLoadingRows = () => (
    <>
      {[1, 2, 3].map((row) => (
        <tr key={row} className="animate-pulse">
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 bg-gray-200 rounded-full" />
              <div className="h-4 bg-gray-200 rounded w-32" />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="h-6 bg-gray-200 rounded w-20" />
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="h-4 bg-gray-200 rounded w-24" />
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="h-4 bg-gray-200 rounded w-20" />
          </td>
        </tr>
      ))}
    </>
  );

  return (
    <div className="w100 flex-1 h-full" style={{ padding: "0px 10px" }}>
      <div
        className="w100 flex flex-col h-full pb-5"
        style={{ maxWidth: "1000px", margin: "0 auto" }}
      >
        {/* Header Section - Back to original styling */}
        <div className="mb-6 p-4 bg-white rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <h1 className="text-2xl font-bold text-gray-900">
                Lista de Candidatos
              </h1>
              <div className="flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-600">
                <PeopleAltIcon sx={{ fontSize: 16 }} />
                <span>
                  {companyApplicantMetadata?.metadata.totalApplicants}
                </span>
              </div>
            </div>
          </div>

          {/* Search and Filter Section */}
          <div className="flex flex-col sm:flex-row gap-4 mt-4">
            <div className="flex-1">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Buscar candidatos..."
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <SearchIcon
                  className="absolute right-3 top-2.5 text-gray-400"
                  sx={{ fontSize: 20 }}
                />
              </div>
            </div>
            <div className="relative flex gap-2" ref={filterRef}>
              <button
                onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
                className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <FilterAltIcon sx={{ fontSize: 20 }} />
                <span>Filtros</span>
              </button>

              {(filters.seen !== "" ||
                filters.sortDate !== "desc" ||
                filters.department !== "" ||
                filters.position !== "") && (
                <button
                  onClick={handleClearFilters}
                  className="w-full sm:w-auto flex items-center justify-center gap-2 px-3 py-2 text-gray-600 bg-gray-50 border border-gray-200 rounded-md hover:bg-gray-100 transition-colors text-sm"
                  aria-label="Limpiar filtros"
                >
                  <span>×</span>
                  <span>Limpiar</span>
                </button>
              )}

              {isFilterDropdownOpen && (
                <div
                  className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 z-[100]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="p-4 space-y-4">
                    {/* Seen Filter */}
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Vistos
                      </label>
                      <select
                        value={filters.seen}
                        onChange={(e) =>
                          handleFilterChange("seen", e.target.value)
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                      >
                        <option value="">Todos</option>
                        <option value="true">Vistos</option>
                        <option value="false">No vistos</option>
                      </select>
                    </div>

                    {/* Department Filter - Updated to use SearchableDropDown */}
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Departamento
                      </label>
                      <SearchableDropDown
                        options={DEPARTMENTS.map(
                          (department) => department.label
                        )}
                        placeholder="Buscar departamento"
                        value={
                          DEPARTMENTS.find(
                            (dept) => dept.value === filters.department
                          )?.label || ""
                        }
                        onChange={(value: string) => {
                          handleFilterChange(
                            "department",
                            DEPARTMENTS.find((dept) => dept.label === value)
                              ?.value || ""
                          );
                          handleFilterChange("position", "");
                        }}
                        size="small"
                      />
                    </div>

                    {/* Position Filter - Only show if department is selected */}
                    {filters.department && (
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">
                          Posición
                        </label>
                        <SearchableDropDown
                          options={getPositionsForDepartment(
                            filters.department
                          )}
                          placeholder="Seleccionar posición"
                          value={filters.position}
                          onChange={(value: string) =>
                            handleFilterChange("position", value)
                          }
                          size="small"
                        />
                      </div>
                    )}

                    {/* Sort Options */}
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Ordenar por fecha
                      </label>
                      <select
                        value={filters.sortDate}
                        onChange={(e) =>
                          handleFilterChange("sortDate", e.target.value)
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                      >
                        <option value="desc">Más reciente</option>
                        <option value="asc">Más antiguo</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Results count and Add button - updated positioning */}
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-1 text-sm text-gray-600">
            <span>Resultados:</span>
            <span className="font-medium">
              {companyApplicantMetadata?.pagination.total || 0}
            </span>
          </div>
          <AddApplicantButton />
        </div>

        {/* Updated table container */}
        <div className="flex-1 overflow-hidden rounded-lg border border-gray-200 bg-white relative">
          {isSearching && <LoadingOverlay />}
          <div className="h-full overflow-y-auto scrollbar-hide relative">
            <table className="min-w-full divide-y divide-gray-200">
              <TableHeader />
              <tbody className="divide-y divide-gray-200">
                {loading && companyApplicants.length === 0 ? (
                  <TableLoadingRows />
                ) : (
                  <>
                    {companyApplicants.length > 0 ? (
                      <>
                        {companyApplicants.map((applicant) => (
                          <ApplicantTableRow
                            key={applicant._id}
                            applicant={applicant}
                            handleApplicantClick={handleApplicantClick}
                          />
                        ))}

                        {(isFetching || loading) && <TableLoadingRows />}

                        {hasMore && !isFetching && !loading && (
                          <tr ref={observerTarget}>
                            <td colSpan={4} className="h-2" />
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={4} className="px-6 py-4 text-center">
                          <div className="text-center text-gray-500 mt-8">
                            No se encontraron candidatos
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {popOpen && selectedApplicant && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[110]">
          <div
            ref={popupRef}
            className="bg-white rounded-lg max-w-5xl w-full h-full max-h-[90vh] overflow-y-auto relative flex flex-col"
          >
            <ApplicantSideInfo
              selected={selectedApplicant}
              setApplicantsState={setApplicantsState}
              dataType="company"
              closePopUp={() => setPopOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicantList;
