import "firebase/firestore";

import axios from "axios";
import { JobInt } from "../typescript/interfaces/JobInterface";
import { environment } from "../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../firebase";
interface LocationData {
  city: string;
  country: string;
  latitude: number;
  longitude: number;
}
// ----------refactor ---------

const getJobs = async (
  location?: LocationData,
  position?: string,
  lastIndex?: string,
  lastDatePosted?: string,
  filters?: {},
  pageSize?: number,
  setLength?: React.Dispatch<React.SetStateAction<number>>
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  // return array of jobs with last one with id

  let filteredObject;

  if (filters) {
    filteredObject = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== undefined)
    );
  }

  //

  const reqData = {
    location: location || {
      city: "",
      country: "",
      latitude: 0,
      longitude: 0,
    },
    title: position,
    pageSize: pageSize,
    lastId: lastIndex,
    lastDatePosted: lastDatePosted,
    filters: filteredObject || {},
  };
  const result = await axios.post(environment.endpoints.getJobs, reqData, {
    headers: {
      "X-Firebase-AppCheck": appCheckToken.token,
    },
  });

  if (setLength) {
    setLength(result.data.length);
  }
  return result.data.results;
};

const getDbLength = async (location?: LocationData, position?: string | "") => {
  const appCheckToken = await getAppCheckToken(appCheck);
  const reqData = {
    location: location || {
      city: "",
      country: "",
      latitude: 0,
      longitude: 0,
    },
    title: position,
  };
  const result = await axios.post(environment.endpoints.getDbLength, reqData, {
    headers: {
      "X-Firebase-AppCheck": appCheckToken.token,
    },
  });
  return result.data.results;
};

const getJobById = async (jobId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  const data = {
    id: jobId,
  };
  try {
    const result = await axios.post(environment.endpoints.findJob, data, {
      headers: {
        "X-Firebase-AppCheck": appCheckToken.token,
      },
    });

    return result.data.results;
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error;
  }
};

const updateDbStatusDescription = async (jobData: JobInt, withID: boolean) => {
  try {
    const appCheckToken = await getAppCheckToken(appCheck);
    if (withID) {
      console.log(jobData);
      await axios.post(environment.endpoints.updateJobPostById, jobData, {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      });
      console.log("updating with _id");
    }
  } catch (error) {
    console.log(error);
  }
};

const getJobByEditKey = async (jobId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.post(
      environment.endpoints.findJobByEditKey,
      {
        editKey: jobId,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    return result.data.results;
  } catch (error) {
    console.log(error);
  }
};

const generateJobPostingStructuredData = (job: JobInt) => {
  const structuredData: any = {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    title: job.title,
    description: job.description,
    datePosted: job.datePosted,
    validThrough: new Date(
      new Date(job.datePosted).getTime() + 45 * 24 * 60 * 60 * 1000
    ).toISOString(),
    hiringOrganization: {
      "@type": "Organization",
      name: job.company,
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: job.location.city,
        addressCountry: job.location.country,
      },
    },
    employmentType: job.fullTime ? "FULL_TIME" : "PART_TIME",
  };

  return structuredData;
};

const deleteJobById = async (jobId: string, companyId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.post(
      environment.endpoints.removeJob,
      {
        id: jobId,
        companyId,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    // await new Promise((resolve) => setTimeout(resolve, 5000));
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
// use places api to store place and coordinates
// for retrieval use autocomplete and also get coordinates so that then we can query db using geojson

export {
  getJobs,
  getDbLength,
  getJobById,
  getJobByEditKey,
  updateDbStatusDescription,
  generateJobPostingStructuredData,
  deleteJobById,
};
