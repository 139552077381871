import { Auth, signOut } from "firebase/auth";
import { DEPARTMENTS } from "./constants/Departments";
import { DEPARTMENT_POSITIONS } from "./constants/Departments";

export const handleSignOutGlobal = async (auth: Auth) => {
  // Sign out from Firebase
  await signOut(auth);

  // Clear all relevant localStorage items
  const localStorageKeys = [
    // User related
    "userData",
    "companyData",
    // Job related
    "selectedJobId",
    "draftJob",
    "jobStep",
    // Add any other keys you want to clear
  ];

  localStorageKeys.forEach((key) => localStorage.removeItem(key));
};

export const getPositionsForDepartment = (selectedDepartment: string) => {
  const department = DEPARTMENTS.find(
    (dept) => dept.value === selectedDepartment
  );
  if (!department) return [];

  // Convert readonly array to mutable array using spread operator
  return [
    ...(DEPARTMENT_POSITIONS[
      department.value as keyof typeof DEPARTMENT_POSITIONS
    ] || []),
  ];
};
