import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ContentCopy, Visibility, CloudUpload } from "@mui/icons-material";
import { CompanyInt } from "../../../typescript/interfaces/CompanyInt";
import "../../../assets/styles/global.css";
import {
  createCompanyPage,
  getCompanyPage,
  updateCompanyPage,
  uploadCompanyPageImageToFirebase,
} from "../../../utils/companyPageFunctions";
import { useNotification } from "../../context/NotificationContext";
import CompanyPageLayout from "./CompanyPageLayout";
import { JobInt } from "../../../typescript/interfaces/JobInterface";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

interface CompanyPageBuilderProps {
  company: CompanyInt;
}

interface CompanyPageData {
  companyId: string;
  name: string;
  description: string;
  subdomainId: string;
  logoUrl: string;
  bannerUrl: string;
  datePosted?: Date;
}

interface CropModalProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  onCropComplete: (croppedImage: Blob) => void;
  aspectRatio?: number;
}

const CompanyPageBuilder: React.FC<CompanyPageBuilderProps> = ({ company }) => {
  const { showNotification } = useNotification();
  const demoPositions: JobInt[] = [
    {
      title: "Desarrollador",
      company: company.name,
      number: "001",
      datePosted: new Date(),
      description:
        "Buscamos un desarrollador Full Stack con experiencia en React y Node.js para unirse a nuestro equipo de desarrollo.",
      companyId: company._id as string,
      location: {
        city: "Buenos Aires",
        country: "Argentina",
        latitude: -34.6037,
        longitude: -58.3816,
      },
      inPerson: false,
      fullTime: true,
      recieveViaEmail: false,
      recieveEmail: "",
      status: true,
      editKey: undefined,
      userId: undefined,
      applicants: 0,
      paid: false,
      aiScorePaid: false,
      questions: [],
      salaryRange: [50000, 80000],
      mapResults: undefined,
      applicantStateOptions: [
        {
          id: "default",
          name: "Nuevo",
          color: "#4CAF50",
          order: 0,
          isDefault: true,
        },
      ],
    },
    {
      title: "Gerente",
      company: company.name,
      number: "002",
      datePosted: new Date(),
      description:
        "Únete a nuestro equipo de diseño para crear experiencias de usuario excepcionales y interfaces intuitivas.",
      companyId: company._id as string,
      location: {
        city: "Buenos Aires",
        country: "Argentina",
        latitude: -34.6037,
        longitude: -58.3816,
      },
      inPerson: true,
      fullTime: true,
      recieveViaEmail: false,
      recieveEmail: "",
      status: true,
      editKey: undefined,
      userId: undefined,
      applicants: 0,
      paid: false,
      aiScorePaid: false,
      questions: [],
      salaryRange: [60000, 90000],
      mapResults: undefined,
      applicantStateOptions: [
        {
          id: "default",
          name: "Nuevo",
          color: "#4CAF50",
          order: 0,
          isDefault: true,
        },
      ],
    },
    {
      title: "Analista",
      company: company.name,
      number: "003",
      datePosted: new Date(),
      description:
        "Buscamos un Product Manager experimentado para liderar el desarrollo de nuevos productos y características.",
      companyId: company._id as string,
      location: {
        city: "Buenos Aires",
        country: "Argentina",
        latitude: -34.6037,
        longitude: -58.3816,
      },
      inPerson: true,
      fullTime: true,
      recieveViaEmail: false,
      recieveEmail: "",
      status: true,
      editKey: undefined,
      userId: undefined,
      applicants: 0,
      paid: false,
      aiScorePaid: false,
      questions: [],
      salaryRange: [70000, 100000],
      mapResults: undefined,
      applicantStateOptions: [
        {
          id: "default",
          name: "Nuevo",
          color: "#4CAF50",
          order: 0,
          isDefault: true,
        },
      ],
    },
  ];

  const [pageData, setPageData] = useState<CompanyPageData>({
    companyId: company._id as string,
    name: company.name,
    description: "",
    subdomainId: "",
    logoUrl: "",
    bannerUrl: "",
  });
  const [showPreview, setShowPreview] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [highlightedSubdomain, setHighlightedSubdomain] =
    useState<boolean>(false);
  const [loadingPageData, setLoadingPageData] = useState(true);
  const [previewUrls, setPreviewUrls] = useState<{
    logo?: string;
    banner?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingImages, setUploadingImages] = useState<{
    logo?: boolean;
    banner?: boolean;
  }>({});
  const [existingPage, setExistingPage] = useState<boolean>(false);
  const [originalPageData, setOriginalPageData] =
    useState<CompanyPageData | null>(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageFile, setCurrentImageFile] = useState<File | null>(null);
  const [currentImageType, setCurrentImageType] = useState<
    "logo" | "banner" | null
  >(null);

  const handleImageUpload = (type: "logo" | "banner") => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (!file) return;

      setCurrentImageFile(file);
      setCurrentImageType(type);
      setCropModalOpen(true);
    };

    input.click();
  };

  const handleCropComplete = async (croppedImage: Blob) => {
    if (!currentImageType || !currentImageFile) return;

    setUploadingImages((prev) => ({ ...prev, [currentImageType]: true }));

    // Create a new File from the cropped Blob
    const croppedFile = new File([croppedImage], currentImageFile.name, {
      type: currentImageFile.type,
    });

    // Create preview URL
    const previewUrl = URL.createObjectURL(croppedImage);
    setPreviewUrls((prev) => ({
      ...prev,
      [currentImageType]: previewUrl,
    }));

    try {
      // upload the image to firebase
      const result = await uploadCompanyPageImageToFirebase(
        croppedFile,
        pageData.companyId,
        currentImageType
      );
      console.log(result);
    } finally {
      setUploadingImages((prev) => ({ ...prev, [currentImageType]: false }));
      setCurrentImageFile(null);
      setCurrentImageType(null);
    }
  };

  // Split into two separate effects
  React.useEffect(() => {
    // Store URLs that need cleanup
    const urlsToCleanup = new Set<string>();

    // Add cleanup function for new preview URLs
    return () => {
      urlsToCleanup.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, []); // Empty dependency array for component unmount

  // Track new preview URLs
  React.useEffect(() => {
    const currentUrls = Object.values(previewUrls).filter(Boolean) as string[];
    return () => {
      // Only cleanup URLs that are no longer in use
      const newUrls = Object.values(previewUrls).filter(Boolean) as string[];
      const removedUrls = currentUrls.filter((url) => !newUrls.includes(url));
      removedUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [previewUrls]); // Track changes to previewUrls

  const handleSave = async () => {
    if (uploadingImages.logo || uploadingImages.banner) {
      showNotification(
        "Por favor espere a que se completen las cargas de imágenes",
        "error"
      );
      return;
    }

    setIsLoading(true);
    try {
      await createCompanyPage(pageData);
      showNotification("Página de empresa creada exitosamente", "success");
    } catch (error: unknown) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
        if (
          error.message ===
          "Ya existe una página de empresa con este subdominio"
        ) {
          setHighlightedSubdomain(true);
        }
      } else {
        showNotification("Error al crear la página de empresa", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = () => {
    const companyPageUrl = `https://quierolaburo.com/empresas/${pageData.subdomainId}`;
    navigator.clipboard.writeText(companyPageUrl);
    setCopySuccess(true);
  };

  const handleSubdomainChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    // Convert to lowercase and replace invalid characters with hyphens
    const sanitizedValue = value
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9-]/g, "-") // Replace invalid chars with hyphen
      .replace(/-+/g, "-") // Replace multiple hyphens with single hyphen
      .replace(/^-+|-+$/g, ""); // Remove hyphens from start and end

    setPageData({ ...pageData, subdomainId: sanitizedValue });
  };

  // Helper function to get the current image URL (either preview or saved)
  const getImageUrl = (type: "logo" | "banner") => {
    return previewUrls[type] || pageData[`${type}Url`];
  };

  // get company page if already exists

  useEffect(() => {
    const getCompanyData = async () => {
      setLoadingPageData(true);
      try {
        const companyPage = await getCompanyPage(
          undefined,
          company._id as string
        );
        if (companyPage) {
          setPageData(companyPage as CompanyPageData);
          setOriginalPageData(companyPage as CompanyPageData);
          setExistingPage(true);
        }
      } catch (error) {
        console.error("Error fetching company page:", error);
      }
      setLoadingPageData(false);
    };
    console.log("getCompanyData");
    getCompanyData();
  }, [company._id]);

  // Add update function placeholder
  const handleUpdate = async () => {
    console.log(pageData);
    setIsLoading(true);
    try {
      // TODO: Implement update functionality
      await updateCompanyPage(pageData.companyId, pageData);
      showNotification("Página de empresa actualizada exitosamente", "success");
    } catch (error) {
      showNotification("Error al actualizar la página de empresa", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Add this helper function to check for changes
  const hasChanges = () => {
    if (!originalPageData) return true;

    return (
      pageData.name !== originalPageData.name ||
      pageData.description !== originalPageData.description ||
      pageData.subdomainId !== originalPageData.subdomainId ||
      pageData.logoUrl !== originalPageData.logoUrl ||
      pageData.bannerUrl !== originalPageData.bannerUrl ||
      previewUrls.logo !== undefined ||
      previewUrls.banner !== undefined
    );
  };

  // Create a new CropModal component
  const CropModal: React.FC<CropModalProps> = ({
    open,
    onClose,
    imageUrl,
    onCropComplete,
    aspectRatio,
  }) => {
    const [crop, setCrop] = useState<Crop>({
      unit: "%",
      width: 90,
      height: aspectRatio ? 90 / aspectRatio : 90,
      x: 5,
      y: 5,
    });

    const imageRef = useRef<HTMLImageElement | null>(null);

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = event.currentTarget;
      let cropWidth, cropHeight;

      if (aspectRatio) {
        // Calculate dimensions maintaining aspect ratio
        if (width / height > aspectRatio) {
          // Image is wider than target ratio
          cropHeight = height * 0.9;
          cropWidth = cropHeight * aspectRatio;
        } else {
          // Image is taller than target ratio
          cropWidth = width * 0.9;
          cropHeight = cropWidth / aspectRatio;
        }
      } else {
        cropWidth = width * 0.9;
        cropHeight = height * 0.9;
      }

      // Center the crop
      const x = (width - cropWidth) / 2;
      const y = (height - cropHeight) / 2;

      const initialCrop: Crop = {
        unit: "px",
        width: cropWidth,
        height: cropHeight,
        x: x,
        y: y,
      };

      setCrop(initialCrop);
    };

    const getCroppedImg = async () => {
      if (!imageRef.current) return;

      const canvas = document.createElement("canvas");
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      if (!ctx) return;

      ctx.drawImage(
        imageRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      return new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
        }, "image/jpeg");
      });
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>Recortar Imagen</DialogTitle>
        <DialogContent>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={aspectRatio}
            minWidth={200}
            minHeight={aspectRatio ? 200 / aspectRatio : 200}
          >
            <img
              ref={imageRef}
              src={imageUrl}
              onLoad={handleImageLoad}
              style={{ maxWidth: "100%", maxHeight: "70vh" }}
              alt="Crop"
            />
          </ReactCrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            onClick={async () => {
              const croppedImage = await getCroppedImg();
              if (croppedImage) {
                onCropComplete(croppedImage);
                onClose();
              }
            }}
            variant="contained"
            style={{ backgroundColor: "var(--laburo-green)" }}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Modify the return statement to show loading state

  return (
    <div className="flex justify-center">
      <div
        className={`w-full ${!showPreview ? "max-w-[1100px] px-8 pt-5" : ""}`}
      >
        {loadingPageData ? (
          <div className="w-full mb-8 p-6 bg-white  relative max-w-3xl mx-auto min-h-[500px] flex flex-col items-center justify-center">
            <CircularProgress
              style={{
                color: "var(--laburo-green)",
              }}
            />
            <Typography variant="body1" className="text-gray-600 !mt-10">
              Cargando información de la empresa...
            </Typography>
          </div>
        ) : showPreview ? (
          <div className="relative w-full min-h-screen">
            <div
              onClick={() => setShowPreview(false)}
              className="fixed top-[80px] right-[2%]  z-50 rounded-full px-6 py-2 bg-laburo-green cursor-pointer hover:shadow-md text-white hover:bg-laburo-green/90"
              style={{
                backgroundColor: "var(--laburo-green)",
                color: "white",
              }}
            >
              Salir de Vista Previa
            </div>
            <CompanyPageLayout
              company={company}
              pageData={{
                description: pageData.description,
                bannerUrl: getImageUrl("banner"),
                logoUrl: getImageUrl("logo"),
                name: pageData.name,
              }}
              positions={demoPositions as JobInt[]}
            />
          </div>
        ) : (
          <Paper className="w-full mb-8 p-6 bg-white !rounded-lg shadow-sm border border-gray-100 relative max-w-3xl mx-auto">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-4">
                <h1 className="text-3xl font-bold text-gray-900">
                  Página de Empresa
                </h1>
                {existingPage && (
                  <div className="absolute right-5 top-5 gap-2 px-3 py-2 bg-gray-100 rounded-full text-lg text-gray-600 flex items-center">
                    <CloudUpload sx={{ fontSize: 18 }} />
                    <span>Publicada</span>
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-8">
              {/* Updated Image Upload Section */}
              <div className="space-y-6 mb-16">
                {/* Banner Section */}
                <div className="relative">
                  <Typography
                    variant="subtitle1"
                    className="text-gray-700 mb-3"
                  >
                    Imagen de banner
                  </Typography>
                  <div
                    className="h-[calc(42.85vw*0.8)] max-h-56 bg-gray-50 rounded-2xl border-2 border-dashed border-gray-300 overflow-hidden relative group"
                    style={{ aspectRatio: "21/9" }}
                  >
                    {previewUrls.banner || pageData.bannerUrl ? (
                      <>
                        <img
                          src={previewUrls.banner || pageData.bannerUrl}
                          alt="Banner"
                          className="h-full w-full object-cover"
                        />
                        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                          {uploadingImages.banner ? (
                            <div className="w-6 h-6 border-2 border-laburo-green border-t-transparent rounded-full animate-spin" />
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {
                                setPreviewUrls((prev) => ({
                                  ...prev,
                                  banner: undefined,
                                }));
                                setPageData((prev) => ({
                                  ...prev,
                                  bannerUrl: "",
                                }));
                              }}
                              className="min-w-0 w-6 h-6 rounded-full"
                              style={{
                                backgroundColor: "var(--laburo-green)",
                              }}
                            >
                              ×
                            </Button>
                          )}
                        </div>
                      </>
                    ) : (
                      <Button
                        startIcon={<CloudUpload />}
                        onClick={() => {
                          // crop image
                          handleImageUpload("banner");
                        }}
                        className="absolute inset-0 w-full h-full"
                        style={{ color: "var(--laburo-green)" }}
                      >
                        Subir Banner
                      </Button>
                    )}
                  </div>

                  {/* Logo Section */}
                  <div className="absolute -bottom-8 left-10">
                    <div className="w-28 h-28 rounded-full bg-white p-2 shadow-lg">
                      <div className="w-full h-full bg-gray-50 rounded-full border-2 border-dashed border-gray-300 overflow-hidden relative group">
                        {previewUrls.logo || pageData.logoUrl ? (
                          <>
                            <img
                              src={previewUrls.logo || pageData.logoUrl}
                              alt="Company logo"
                              className="h-full w-full object-cover"
                            />
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                              {uploadingImages.logo ? (
                                <div className="w-6 h-6 border-2 border-laburo-green border-t-transparent rounded-full animate-spin" />
                              ) : (
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setPreviewUrls((prev) => ({
                                      ...prev,
                                      logo: undefined,
                                    }));
                                    setPageData((prev) => ({
                                      ...prev,
                                      logoUrl: "",
                                    }));
                                  }}
                                  className="min-w-0 w-6 h-6 rounded-full text-sm"
                                  style={{
                                    backgroundColor: "var(--laburo-green)",
                                  }}
                                >
                                  ×
                                </Button>
                              )}
                            </div>
                          </>
                        ) : (
                          <Button
                            onClick={() => handleImageUpload("logo")}
                            className="absolute inset-0 w-full h-full"
                            style={{ color: "var(--laburo-green)" }}
                          >
                            <CloudUpload />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Company Information Fields */}
              <div className="space-y-8">
                <TextField
                  fullWidth
                  label="Nombre de la empresa"
                  value={pageData.name}
                  onChange={(e) =>
                    setPageData({ ...pageData, name: e.target.value })
                  }
                  className="bg-gray-50 rounded-2xl"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: "1.1rem" } }}
                  InputProps={{ style: { fontSize: "1.1rem" } }}
                />

                <TextField
                  fullWidth
                  label="Descripción de la empresa"
                  multiline
                  rows={4}
                  value={pageData.description}
                  onChange={(e) =>
                    setPageData({ ...pageData, description: e.target.value })
                  }
                  className="bg-gray-50 rounded-2xl"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: "1.1rem" } }}
                  InputProps={{ style: { fontSize: "1.1rem" } }}
                />
              </div>

              <div className="space-y-3">
                <Typography variant="subtitle1" className="text-gray-700 mb-2">
                  URL de tu página de empresa
                </Typography>
                <div className="flex items-center space-x-3">
                  {existingPage ? (
                    <TextField
                      value={`quierolaburo.com/empresas/${pageData.subdomainId}`}
                      className="flex-1"
                      disabled
                      InputProps={{
                        className: "rounded-2xl bg-gray-50 opacity-70",
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      value={pageData.subdomainId}
                      onChange={handleSubdomainChange}
                      placeholder="tu-empresa"
                      className="flex-1"
                      error={highlightedSubdomain}
                      InputProps={{
                        startAdornment: (
                          <div className="bg-gray-100 px-3 py-2 text-gray-500">
                            quierolaburo.com/empresas/
                          </div>
                        ),
                        className: "rounded-2xl bg-gray-50",
                      }}
                      variant="outlined"
                    />
                  )}
                </div>

                <Typography variant="caption" className="text-gray-500">
                  {existingPage
                    ? "El subdominio no puede ser modificado una vez creada la página"
                    : "Esta será la URL pública de tu página de empresa"}
                </Typography>
              </div>

              {/* Action Buttons */}
              <div className="flex gap-6 mt-10">
                <Button
                  variant="contained"
                  onClick={existingPage ? handleUpdate : handleSave}
                  disabled={isLoading || (existingPage && !hasChanges())}
                  className={`rounded-full px-8 py-3 text-white hover:bg-laburo-green/90 relative ${
                    existingPage && !hasChanges()
                      ? "bg-gray-400"
                      : "bg-laburo-green"
                  }`}
                  style={{
                    backgroundColor:
                      existingPage && !hasChanges()
                        ? "#9CA3AF"
                        : "var(--laburo-green)",
                    color: "white",
                    minWidth: "220px",
                  }}
                >
                  {isLoading ? (
                    <span>
                      {existingPage ? "Actualizando..." : "Creando página..."}
                    </span>
                  ) : existingPage ? (
                    "Actualizar Cambios"
                  ) : (
                    "Guardar Cambios"
                  )}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Visibility />}
                  onClick={() => setShowPreview(!showPreview)}
                  className="rounded-full px-8 py-3 text-laburo-green border-laburo-green hover:border-laburo-green/90 hover:text-laburo-green/90"
                  style={{
                    borderColor: "var(--laburo-green)",
                    color: "var(--laburo-green)",
                  }}
                >
                  Vista Previa
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ContentCopy />}
                  onClick={handleCopyLink}
                  disabled={!pageData.subdomainId}
                  className={`rounded-full px-8 py-3 ${
                    !pageData.subdomainId
                      ? "text-gray-400 border-gray-300"
                      : "text-laburo-green border-laburo-green hover:border-laburo-green/90 hover:text-laburo-green/90"
                  }`}
                  style={{
                    borderColor: pageData.subdomainId
                      ? "var(--laburo-green)"
                      : undefined,
                    color: pageData.subdomainId
                      ? "var(--laburo-green)"
                      : undefined,
                  }}
                >
                  Copiar Enlace
                </Button>
              </div>
            </div>
          </Paper>
        )}

        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={() => setCopySuccess(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setCopySuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            ¡Enlace copiado al portapapeles!
          </Alert>
        </Snackbar>

        <CropModal
          open={cropModalOpen}
          onClose={() => setCropModalOpen(false)}
          imageUrl={
            currentImageFile ? URL.createObjectURL(currentImageFile) : ""
          }
          onCropComplete={handleCropComplete}
          aspectRatio={currentImageType === "banner" ? 21 / 9 : 1}
        />
      </div>
    </div>
  );
};

export default CompanyPageBuilder;
