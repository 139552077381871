// creates automation trigger based on state id and trigger

import axios from "axios";
import { AutomationTrigger } from "../../typescript/interfaces/AutomationTypes";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
export const createAutomationTrigger = async (trigger: AutomationTrigger) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const result = await axios.post(
      `${environment.endpoints.createAutomationTrigger}`,
      trigger,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    console.log(result);
    return result;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response.data.message);
  }
};
