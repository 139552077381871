import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
export const getAutomationTrigger = async (jobId: string, stateId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  const response = await fetch(
    `${environment.endpoints.getAutomationTrigger}?jobId=${jobId}&stateId=${stateId}`,
    {
      headers: {
        "X-Firebase-AppCheck": appCheckToken.token,
      },
    }
  );
  const data = await response.json();
  return data;
};
