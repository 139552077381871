import React, { useState } from "react";
import { Typography } from "@mui/material";
import { CompanyInt } from "../../../typescript/interfaces/CompanyInt";
import { JobInt } from "../../../typescript/interfaces/JobInterface";
import { Search as SearchIcon } from "@mui/icons-material";
import LeaveResumePopUp from "./LeaveResumePopUp";

interface CompanyPageLayoutProps {
  company: CompanyInt;
  pageData: {
    description: string;
    bannerUrl: string;
    logoUrl: string;
    name: string;
  };
  positions?: JobInt[];
}

const EmptyStateMessage = ({ message }: { message: string }) => (
  <Typography className="text-gray-400 italic">{message}</Typography>
);

const getRelativeTime = (date: Date) => {
  const now = new Date();
  const postedDate = new Date(date);
  const diffTime = Math.abs(now.getTime() - postedDate.getTime());
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 3) {
    return (
      <span className="inline-flex items-center text-laburo-green">Nuevo</span>
    );
  }
  if (diffDays === 0) return "Publicado hoy";
  if (diffDays === 1) return "Publicado hace 1 día";
  return `Publicado hace ${diffDays} días`;
};

const CompanyPageLayout: React.FC<CompanyPageLayoutProps> = ({
  company,
  pageData,
  positions = [],
}) => {
  // Add state for search
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isResumePopupOpen, setIsResumePopupOpen] = useState(false);

  // Filter positions based on search term
  const filteredPositions = positions.filter((job) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      job.title.toLowerCase().includes(searchLower) ||
      job.location.city.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="w-full min-h-screen">
      {/* Banner and Logo Section */}
      <div className="max-w-[1000px] mx-auto pt-0 lg:pt-12">
        <div className="relative">
          {pageData.bannerUrl ? (
            <div
              className="w-full relative overflow-hidden shadow-md lg:rounded-2xl"
              style={{
                height: "min(428px, calc(9/21 * 100vw))",
              }}
            >
              <img
                src={pageData.bannerUrl}
                alt="Company banner"
                className="w-full h-full object-cover absolute top-0 left-0"
              />
            </div>
          ) : (
            <div
              className="w-full"
              style={{
                height: "min(428px, calc(9/21 * 100vw))",
              }}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-gray-100 flex items-center justify-center lg:rounded-2xl">
                <EmptyStateMessage message="No se ha subido imagen de banner" />
              </div>
            </div>
          )}

          {/* Logo - Positioned over banner */}
          <div className="absolute -bottom-12 left-[5%]">
            <div className="w-28 h-28 md:w-32 md:h-32 rounded-full bg-white p-1 shadow-lg">
              <div className="w-full h-full rounded-full overflow-hidden">
                {pageData.logoUrl ? (
                  <img
                    src={pageData.logoUrl}
                    alt="Company logo"
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <div className="h-full w-full bg-gray-100 flex items-center justify-center">
                    <EmptyStateMessage message="Logo" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wrap content in a container with responsive padding */}
      <div className="max-w-[1000px] mx-auto px-4 md:px-8">
        {/* Company name and CV button section */}
        <div className="mt-16 flex justify-between items-start flex-wrap gap-4">
          <Typography variant="h4" className="font-medium text-gray-800">
            {pageData.name}
          </Typography>

          <div className="flex flex-col items-center gap-2 relative">
            <p className="hidden md:block text-sm text-gray-500 absolute -top-6 left-1/2 -translate-x-1/2 text-center whitespace-nowrap">
              ¿No encontraste lo que buscabas?
            </p>
            <button
              onClick={() => setIsResumePopupOpen(true)}
              className="inline-flex items-center gap-2 bg-white outline-none border-solid border-2 border-gray-900 hover:bg-gray-900 text-gray-900 hover:text-white transition-all duration-300 text-[14px] py-2 px-5 rounded-xl hover:translate-y-[-1px] active:translate-y-[1px] font-medium tracking-wide group"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                />
              </svg>
              Dejar CV en {pageData.name}
            </button>
          </div>
        </div>

        {/* About Section */}
        <section className="mt-8  md:px-0">
          <Typography variant="h6" className="text-gray-700 mb-3">
            Sobre Nosotros
          </Typography>
          {pageData.description ? (
            <Typography className="text-gray-600 max-w-[800px]">
              {pageData.description}
            </Typography>
          ) : (
            <EmptyStateMessage message="Añade una descripción de tu empresa" />
          )}
        </section>
      </div>

      {/* Job Positions Section - Now full width */}
      <section className="bg-gray-50 p-4 md:p-8 mt-8 mb-8 pb-8 md:pb-12 h-full">
        <div className="max-w-[1000px] mx-auto">
          <div className="mx-auto px-4 md:px-8">
            <div className="text-center mb-14">
              <div className="mb-10">
                <h1 className="text-[32px] font-semibold text-gray-900 mb-3">
                  Vacantes Disponibles en {pageData.name}
                </h1>
                <p className="text-[17px] text-gray-500">
                  Explora nuestra oferta laboral
                </p>
              </div>

              {/* Updated search bar */}
              <div className="flex items-center justify-center">
                <div className="relative w-full max-w-[500px]">
                  <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                    <SearchIcon className="text-gray-400 text-xl" />
                  </div>
                  <input
                    type="text"
                    placeholder="Buscar por puesto o ciudad..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-12 pr-4 py-3 bg-white border border-gray-200 rounded-xl outline-none focus:ring-2 focus:ring-gray-100 focus:border-gray-300 transition-all duration-200 text-[15px]"
                  />
                  {searchTerm && (
                    <button
                      onClick={() => setSearchTerm("")}
                      className="absolute inset-y-0 right-3 flex items-center text-gray-400 hover:text-gray-600"
                    >
                      <span className="text-xl">×</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {positions.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 sm:px-4 md:px-8">
              {filteredPositions.length > 0 ? (
                filteredPositions.map((job, index) => (
                  <div
                    key={index}
                    className="w-full p-4 sm:p-8 rounded-md border border-gray-100 hover:shadow-2xl transition-all duration-300 bg-white shadow-[0_4px_24px_-4px_rgba(0,0,0,0.03)] hover:shadow-[0_20px_40px_-12px_rgba(0,0,0,0.12)] hover:border-blue-100/50 group hover:scale-[1.02]"
                  >
                    <div className="space-y-6">
                      <div className="flex justify-between items-start gap-4">
                        <h3 className="text-lg leading-tight font-bold text-gray-900 line-clamp-2 group-hover:text-blue-900 transition-colors duration-200">
                          {job.title}
                        </h3>
                        <div
                          className={`
                          shrink-0 text-[13px] font-medium flex items-center gap-1.5 px-3 py-1.5 rounded-md border
                          ${
                            job.inPerson
                              ? "bg-green-50 text-green-700 border-green-100"
                              : "bg-blue-50 text-blue-700 border-blue-100"
                          }`}
                        >
                          <span
                            className={`inline-block w-1 h-1 rounded-full ${
                              job.inPerson ? "bg-green-400" : "bg-blue-400"
                            }`}
                          ></span>
                          {job.inPerson ? "En persona" : "Remoto"}
                        </div>
                      </div>

                      <div className="flex items-center justify-between gap-4">
                        <div className="space-y-1.5">
                          <p className="text-gray-800 text-[18px] font-medium">
                            {job.location.city}
                          </p>
                          <span className="text-gray-400 text-sm block">
                            {getRelativeTime(job.datePosted)}
                          </span>
                        </div>
                        <a
                          href={`https://quierolaburo.com/job-des/job-apply/?id=${job._id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-gray-900 hover:bg-gray-800 transition-all duration-300 text-[14px] py-2.5 px-6 rounded-md hover:translate-y-[-1px] active:translate-y-[1px] text-white shadow-[0_4px_10px_-3px_rgba(0,0,0,0.1)] font-medium tracking-wide"
                        >
                          Aplicar
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-full text-center py-8">
                  <p className="text-gray-500">
                    No se encontraron resultados para "{searchTerm}"
                  </p>
                </div>
              )}
            </div>
          ) : (
            <p className="text-gray-400 italic text-center">
              No hay posiciones abiertas actualmente
            </p>
          )}
        </div>
      </section>

      {/* Powered by Laburo Footer */}
      <div className="text-center py-6 bg-white">
        <p className="text-gray-500 flex items-center justify-center gap-1 text-sm">
          Powered by
          <a
            href="https://www.quierolaburo.com/empresas"
            className="text-laburo-green font-medium"
            target="_blank"
            rel="noopener noreferrer"
          >
            Laburo
          </a>
        </p>
      </div>

      <LeaveResumePopUp
        open={isResumePopupOpen}
        onClose={() => setIsResumePopupOpen(false)}
        companyName={pageData.name}
        companyId={company._id?.toString() || ""}
      />
    </div>
  );
};

export default CompanyPageLayout;
