import React, { useState, useRef, useEffect } from "react";
import "../../assets/styles/resumeUploader.css";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

interface ResumeUploaderProps {
  setResume: (file: File | undefined) => void;
  status: number;
  loading?: boolean;
  selectedResume?: boolean;
  handleRemove?: () => void;
}

const LoadingAnimation = () => (
  <div className="relative w-16 h-16">
    <div className="absolute inset-0 rounded-full border-4 border-purple-100"></div>
    <div className="absolute inset-0 rounded-full border-4 border-purple-500 border-t-transparent animate-spin"></div>
    <div className="absolute inset-3 rounded-full bg-purple-500/20 animate-pulse"></div>
    <div className="absolute inset-[30%] rounded-full bg-purple-500"></div>
  </div>
);

// Move steps array outside component
const ANALYSIS_STEPS = [
  {
    title: "Analizando CV",
    subtitle: "Procesando el documento...",
    icon: "📄",
  },
  {
    title: "Extrayendo Datos",
    subtitle: "Identificando información relevante...",
    icon: "🔍",
  },
  {
    title: "Experiencia Laboral",
    subtitle: "Analizando historial profesional...",
    icon: "💼",
  },
  {
    title: "Formación Académica",
    subtitle: "Procesando credenciales...",
    icon: "🎓",
  },
  {
    title: "Validación Final",
    subtitle: "Verificando la información...",
    icon: "✨",
  },
] as const;

function ResumeUploader(props: ResumeUploaderProps) {
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showLongWaitMessage, setShowLongWaitMessage] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    let longWaitTimeoutId: NodeJS.Timeout | undefined;

    if (props.loading) {
      intervalId = setInterval(() => {
        setCurrentStep((prev) => {
          if (prev < ANALYSIS_STEPS.length - 1) {
            return prev + 1;
          }
          return prev;
        });
      }, 2000);

      // Set timeout for the "taking longer than usual" message
      longWaitTimeoutId = setTimeout(() => {
        setShowLongWaitMessage(true);
      }, 2000 * (ANALYSIS_STEPS.length + 1));
    } else {
      setCurrentStep(0);
      setShowLongWaitMessage(false);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (longWaitTimeoutId) clearTimeout(longWaitTimeoutId);
    };
  }, [props.loading]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined;

    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];

    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      alert("Solo se aceptan archivos .pdf, .doc o .docx");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    if (selectedFile && selectedFile.size > maxSize) {
      alert("El archivo es demasiado grande. El tamaño máximo es 10MB");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    setFile(selectedFile);
    props.setResume(selectedFile);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemove = () => {
    // Clear the internal state and prop
    setFile(undefined);
    props.setResume(undefined);
    if (props.handleRemove) {
      props.handleRemove();
    }
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <React.Fragment>
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".pdf,.docx,.doc"
      />
      {props.loading ? (
        <div className="transform transition-all duration-500 ease-in-out">
          <div className="bg-white rounded-xl shadow-lg p-8 border border-purple-100">
            <div className="flex flex-col items-center space-y-6">
              <LoadingAnimation />

              <div className="text-center space-y-2 min-h-[80px] transition-all duration-500">
                <div className="flex items-center justify-center space-x-2 text-xl font-semibold text-gray-800">
                  <span className="text-2xl">
                    {ANALYSIS_STEPS[currentStep].icon}
                  </span>
                  <span>{ANALYSIS_STEPS[currentStep].title}</span>
                </div>
                <p className="text-gray-500 text-sm">
                  {showLongWaitMessage
                    ? "Esta tomando un poco más de lo usual..."
                    : ANALYSIS_STEPS[currentStep].subtitle}
                </p>
              </div>

              <div className="flex space-x-2">
                {ANALYSIS_STEPS.map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full transition-all duration-300 ${
                      index === currentStep
                        ? "bg-purple-500 scale-125"
                        : "bg-purple-200"
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={!file ? handleClick : undefined}
          className={`relative cursor-pointer transition-all duration-300 ease-in-out
            ${
              file
                ? "bg-purple-50 border-purple-200"
                : "bg-white border-purple-200 hover:bg-purple-50 hover:border-purple-300"
            } 
            border-2 border-dashed rounded-lg p-8 text-center`}
        >
          <div className="space-y-4">
            <div className="flex justify-center">
              <AutoAwesomeIcon
                className={`text-4xl ${
                  file ? "text-purple-500" : "text-purple-500"
                }`}
              />
            </div>
            <div className="text-lg font-medium">
              {file ? (
                <span className="text-purple-600">CV Cargado Exitosamente</span>
              ) : (
                <span className="text-gray-700">
                  {props.selectedResume
                    ? "Actualizar CV"
                    : "Arrastra tu CV aquí o haz clic para subir"}
                </span>
              )}
            </div>
            <div className="text-sm text-gray-500">
              {!file && "Formatos aceptados: PDF, DOC, DOCX (max. 10MB)"}
            </div>
          </div>
        </div>
      )}
      {file && (
        <div className="mt-4 flex items-center justify-between p-4 bg-purple-50 rounded-lg border border-purple-200">
          <div className="flex items-center space-x-3">
            <svg
              className="w-6 h-6 text-purple-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="text-sm font-medium text-gray-700">
              {file.name}
            </span>
          </div>
          <button
            type="button"
            onClick={handleRemove}
            className="text-sm font-medium text-purple-600 hover:text-purple-800 transition-colors duration-200"
          >
            Eliminar
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

export default ResumeUploader;
