import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../components/Navbar";
import "../assets/styles/postjobs.css";
import PostJobPill from "../components/PostJobPill";
import CreateJob from "../components/create-job/CreateJob";
import ValidateJob from "../components/ValidateJob";

import Footer from "../components/Footer";
import { JobInt } from "../typescript/interfaces/JobInterface";

import PaymentPage from "../components/create-job/PaymentPage";
import { useUser } from "../components/routing/UserContext";
import { useInitialData } from "../components/hooks/useInitialData";
import LoadingWidget from "../components/widgets/LoadingWidget";
// store job on local storage
function PostJob() {
  const { isLoading } = useInitialData();

  const { userData, companyData } = useUser();
  const [step, setStep] = useState(() => {
    // Initialize step from localStorage or default to 1
    return parseInt(localStorage.getItem("jobStep") || "1");
  });
  const def = useMemo(
    () => ({
      title: "",
      company: companyData?.name || "",

      datePosted: new Date(),
      number: "",
      description: "",
      location: {
        city: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      inPerson: true,
      fullTime: true,
      recieveViaEmail: true,
      recieveEmail: userData?.user || "",
      imageURL: "",
      status: false,
      _id: undefined,
      editKey: undefined,
      userId: undefined,
      applicants: undefined,
      paid: false,
      aiScorePaid: false,
      companyId: companyData?._id as string,
      questions: [],
      salaryRange: [],
      mapResults: undefined,
      assignedTo: [],
      applicantStateOptions: [
        {
          id: "new",
          name: "Nuevos",
          color: "#15BC7F",
          order: 1,
          isDefault: true,
          canDelete: true,
        },
        {
          id: "saved",
          name: "Guardados",
          color: "#FFBB32",
          order: 2,
          isDefault: true,
          canDelete: true,
        },
        {
          id: "hired",
          name: "Contratados",
          color: "#800080",
          order: 3,
          isDefault: true,
          canDelete: true,
        },
      ],
      department: "sin_departamento",
      position: "",
    }),
    [companyData, userData]
  );
  const [job, setJob] = useState<JobInt>(() => {
    // Try to get saved job from localStorage
    const savedJob = localStorage.getItem("draftJob");
    if (savedJob) {
      const parsedJob = JSON.parse(savedJob);
      return {
        ...parsedJob,
        // Always use current date when loading from cache
        datePosted: new Date(),
      };
    }
    return def;
  });

  // Save job to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("draftJob", JSON.stringify(job));
  }, [job]);

  // Save step to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("jobStep", step.toString());
  }, [step]);

  // // Clear localStorage when payment is successful
  // useEffect(() => {
  //   const query = new URLSearchParams(window.location.search);
  //   if (query.get("success")) {
  //     setStep(3);
  //     console.log("setting step 3");

  //     window.history.replaceState({}, "", "/post-job");
  //   }
  //   document.title = "Publicar Trabajo";
  // }, []);

  const Step1 = () => {
    return (
      <React.Fragment>
        <PostJobPill step={1} />
        <CreateJob setJob={setJob} job={job} setStep={setStep} />
      </React.Fragment>
    );
  };

  const Step2 = () => {
    return (
      <React.Fragment>
        <PostJobPill step={2} />
        <ValidateJob
          job={job || def}
          setStep={setStep}
          userData={userData}
          companyData={companyData}
        />
      </React.Fragment>
    );
  };

  const Step3 = () => {
    return (
      <React.Fragment>
        <PostJobPill step={3} />
        <PaymentPage setStep={setStep} job={job || def} />
      </React.Fragment>
    );
  };

  if (isLoading) {
    return (
      <div className="w100 flx flx-col flx-center">
        <Navbar scrollPast={true} highlightPost={true} />
        <div className="flx flx-center mt-25">
          <LoadingWidget loading={true} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="w100 flx flx-col flx-center"
      style={{ padding: "0px 10px" }}
    >
      <Navbar scrollPast={true} highlightPost={true} />
      <div className="skip-navbar-margin postjob-container ">
        {/* top bar  */}

        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}

        {/* second bar with content */}
      </div>
      <Footer type={2} />
    </div>
  );
}

export default PostJob;
