import React, { useState } from "react";

import SearchableDropDown from "../../widgets/SearchableDropDown";
import { DEPARTMENTS } from "../../../utils/constants/Departments";
import { getPositionsForDepartment } from "../../../utils/globalUtils";
import { uploadResumeToFirebase } from "../../../utils/firebase/firebaseStorageUtils";
import { uploadCVToCompanyPage } from "../../../utils/applicantFunctions";
import { useNotification } from "../../context/NotificationContext";
import { ApplicantInt } from "../../../typescript/interfaces/AppInterface";

interface LeaveResumePopUpProps {
  open: boolean;
  onClose: () => void;
  companyName: string;
  companyId: string;
}

function LeaveResumePopUp({
  open,
  onClose,
  companyName,
  companyId,
}: LeaveResumePopUpProps) {
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [loadingDots, setLoadingDots] = useState("");
  const [resumeError, setResumeError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
    resume: null as File | null,
    department: "",
    position: "",
  });

  // Add useEffect for loading animation
  React.useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingDots((dots) => (dots.length >= 3 ? "" : dots + "."));
      }, 500);
      return () => clearInterval(interval);
    } else {
      setLoadingDots("");
    }
  }, [loading]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFormData((prev) => ({ ...prev, resume: event.target.files![0] }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setResumeError(false);
    let resumeUrlCreated: string | null = null;

    try {
      // Validate file before upload
      if (!formData.resume) {
        setResumeError(true);
        throw new Error("No se seleccionó ningún CV");
      }

      // Validate file size (e.g., 10MB limit)
      const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
      if (formData.resume.size > MAX_FILE_SIZE) {
        throw new Error(
          "El archivo es demasiado grande. Máximo 10MB permitido."
        );
      }

      // Validate file type
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(formData.resume.type)) {
        throw new Error(
          "Tipo de archivo no permitido. Solo se permiten archivos PDF y DOC/DOCX."
        );
      }

      try {
        resumeUrlCreated = await uploadResumeToFirebase(
          formData.resume,
          companyId,
          formData.name
        );
      } catch (uploadError) {
        throw new Error(
          "Error al subir el archivo. Por favor, intente nuevamente."
        );
      }

      const apiData = {
        name: formData.name,
        email: formData.email,
        number: formData.phone,
        description: formData.description,
        date: new Date(),
        resumeUrl: resumeUrlCreated,
        companyId: companyId,
        fileType: formData.resume.type,
        department: formData.department,
        position: formData.position,

        // Add other necessary fields
      };

      const result = await uploadCVToCompanyPage(
        apiData as ApplicantInt,
        companyId
      );

      if (!result.success) {
        // If API call fails, clean up the uploaded file

        throw new Error(result.message || "Error al procesar la solicitud");
      }

      showNotification("CV enviado exitosamente", "success");
      onClose();
    } catch (error) {
      console.error("Error submitting:", error);
      showNotification(
        error instanceof Error ? error.message : "Error al enviar CV",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[100] flex items-center justify-center p-0 sm:p-4">
      <div className="bg-white w-full h-full sm:h-[90vh] sm:rounded-2xl sm:max-w-[600px] p-4 sm:p-6 relative my-0 sm:my-4 mx-auto overflow-y-auto">
        {/* Header - Make it sticky */}
        <div className="flex justify-between items-center mb-6 bg-white z-10 py-2">
          <h2 className="text-2xl font-medium text-gray-800">
            Dejar CV en {companyName}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <svg
              className="w-5 h-5 text-gray-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        {/* Form - Add padding bottom for better scroll experience */}
        <form onSubmit={handleSubmit} className="space-y-6 pb-4">
          {/* Name Input */}
          <div className="space-y-2">
            <input
              type="text"
              placeholder="Nombre completo"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-gray-300 focus:ring-2 focus:ring-gray-100 outline-none transition-all duration-200"
              required
            />
          </div>

          {/* Email Input */}
          <div className="space-y-2">
            <input
              type="email"
              placeholder="Correo electrónico"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-gray-300 focus:ring-2 focus:ring-gray-100 outline-none transition-all duration-200"
              required
            />
          </div>

          {/* Phone Input */}
          <div className="space-y-2">
            <input
              type="tel"
              placeholder="Teléfono"
              value={formData.phone}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, phone: e.target.value }))
              }
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-gray-300 focus:ring-2 focus:ring-gray-100 outline-none transition-all duration-200"
              required
            />
          </div>

          {/* Department Dropdown */}
          <div className="space-y-2">
            <label className="text-gray-700 text-sm font-medium">
              Área de interés
            </label>
            <SearchableDropDown
              options={DEPARTMENTS.map((department) => department.label)}
              placeholder="Buscar área"
              value={formData.department}
              onChange={(value: string) => {
                setFormData((prev) => ({
                  ...prev,
                  department:
                    DEPARTMENTS.find((dept) => dept.label === value)?.value ||
                    "",
                  position: "",
                }));
              }}
            />
          </div>

          {/* Position Dropdown */}
          <div className="space-y-2">
            <label className="text-gray-700 text-sm font-medium">
              Posición de interés
            </label>
            <SearchableDropDown
              options={getPositionsForDepartment(formData.department)}
              placeholder="Seleccionar posición"
              value={formData.position}
              onChange={(value: string) => {
                setFormData((prev) => ({ ...prev, position: value }));
              }}
            />
          </div>

          {/* Description Input */}
          <div className="space-y-2">
            <textarea
              placeholder="Breve descripción"
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              rows={4}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-gray-300 focus:ring-2 focus:ring-gray-100 outline-none transition-all duration-200 resize-none"
            />
          </div>

          {/* File Upload - Modified border color based on error state */}
          <div className="space-y-2">
            <label className="text-gray-700 text-sm font-medium">
              Curriculum Vitae *
            </label>
            <label
              className={`flex items-center justify-center gap-2 w-full py-3 px-4 border-2 ${
                resumeError ? "border-red-500" : "border-gray-200"
              } rounded-xl cursor-pointer hover:border-gray-300 transition-all duration-200`}
            >
              <svg
                className={`w-5 h-5 ${
                  resumeError ? "text-red-500" : "text-gray-600"
                }`}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              <span className={resumeError ? "text-red-500" : "text-gray-600"}>
                {formData.resume ? formData.resume.name : "Subir CV"}
              </span>
              <input
                type="file"
                hidden
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
              />
            </label>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-gray-900 hover:bg-gray-800 text-white py-3 rounded-xl transition-all duration-300 hover:translate-y-[-1px] active:translate-y-[1px]"
          >
            {loading ? `Enviando${loadingDots}` : "Enviar CV"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LeaveResumePopUp;
