// contexts/ApplicantStateContext.tsx
import React, { createContext, useContext, useState } from "react";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";
import { getStateOptionsByJobId } from "../../utils/applicantFunctions";

interface ApplicantStateContextType {
  stateOptions: applicantStateOption[];
  currentState: applicantStateOption;
  setCurrentState: (state: applicantStateOption) => void;
  setStateOptions: (newOptions: applicantStateOption[]) => void;
  defaultOptions: applicantStateOption[];
  clearState: () => void;
  findStateOptionsByJobId: (jobId: string) => Promise<applicantStateOption[]>;
}

const ApplicantStateContext = createContext<
  ApplicantStateContextType | undefined
>(undefined);

export function ApplicantStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentState, setCurrentState] = useState({
    id: "Todos",
    name: "Todos",
    color: "rgba(0,0,0,0.4)",
    order: 0,
  });

  const defaultOptions = [
    { id: "Nuevos", name: "Nuevos", color: "#15BC7F", order: 1 },
    { id: "Guardados", name: "Guardados", color: "#FFBB32", order: 2 },
    { id: "Hired", name: "Contratados", color: "#800080", order: 3 },
  ];

  const [stateOptions, _setStateOptions] = useState(defaultOptions);
  const setStateOptions = (newOptions: applicantStateOption[]) => {
    // Only update if the options are actually different
    const areOptionsEqual =
      JSON.stringify(newOptions) === JSON.stringify(stateOptions);
    if (!areOptionsEqual) {
      _setStateOptions(newOptions);
    }
  };

  const clearState = () => {
    setCurrentState({
      id: "Todos",
      name: "Todos",
      color: "rgba(0,0,0,0.4)",
      order: 0,
    });
    setStateOptions(defaultOptions);
  };

  const findStateOptionsByJobId = async (jobId: string) => {
    const result = await getStateOptionsByJobId(jobId);
    return result;
  };

  return (
    <ApplicantStateContext.Provider
      value={{
        stateOptions,
        currentState,
        setCurrentState,
        setStateOptions,
        findStateOptionsByJobId,
        defaultOptions,
        clearState,
      }}
    >
      {children}
    </ApplicantStateContext.Provider>
  );
}

export function useApplicantState() {
  const context = useContext(ApplicantStateContext);
  if (context === undefined) {
    throw new Error(
      "useApplicantState must be used within an ApplicantStateProvider"
    );
  }
  return context;
}
