import { useState } from "react";
import {
  Alert,
  TextField,
  Box,
  Typography,
  Container,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { environment } from "../../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../../firebase";

interface ContactUsFormProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export default function ContactUsForm({
  onSuccess,
  onError,
}: ContactUsFormProps) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "error"
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const appCheckToken = await getAppCheckToken(appCheck);

    const { name, email, message } = formData;
    if (!name || !email || !message) {
      setAlertMessage("Por favor completa todos los campos antes de enviar.");
      setAlertSeverity("error");
      setShowAlert(true);
      return;
    }

    setLoading(true);
    try {
      await axios.post(environment.endpoints.customerSupport, formData, {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      });
      setFormData({ name: "", email: "", message: "" });
      setAlertMessage("¡Gracias! Nos pondremos en contacto contigo pronto.");
      setAlertSeverity("success");
      setShowAlert(true);
      onSuccess?.();
    } catch (error) {
      setAlertMessage("Error al enviar el mensaje. Intenta nuevamente.");
      setAlertSeverity("error");
      setShowAlert(true);
      onError?.(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ padding: 0 }}>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowAlert(false)}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          backgroundColor: "background.paper",
          borderRadius: 4,
          p: { xs: 2, sm: 4 },
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          fontWeight="600"
          align="center"
          sx={{ mb: 1 }}
        >
          Contacto
        </Typography>

        <TextField
          fullWidth
          label="Nombre completo"
          name="name"
          value={formData.name}
          onChange={handleChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
        />

        <TextField
          fullWidth
          label="Correo electrónico"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
        />

        <TextField
          fullWidth
          label="Teléfono"
          name="message"
          value={formData.message}
          onChange={handleChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
        />

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <button
            type="submit"
            disabled={loading}
            style={{
              minWidth: 200,
              padding: "14px 32px",
              fontSize: "1.1rem",
              borderRadius: "30px",
              background: "linear-gradient(45deg, #2196F3 40%, #20B2AA 90%)",
              border: "none",
              color: "white",
              cursor: loading ? "default" : "pointer",
              opacity: loading ? 0.7 : 1,
              transition: "all 0.3s ease",
              boxShadow: "0 3px 15px rgba(33, 150, 243, 0.3)",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "translateY(-2px)";
              e.currentTarget.style.boxShadow =
                "0 6px 20px rgba(33, 150, 243, 0.4)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow =
                "0 3px 15px rgba(33, 150, 243, 0.3)";
            }}
          >
            {loading ? "Enviando..." : "Enviar"}
          </button>
        </Box>
      </Box>
    </Container>
  );
}
