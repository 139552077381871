import axios from "axios";
import { EmailTemplate } from "../../typescript/interfaces/EmailType";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
export const upsertEmailTemplate = async (template: EmailTemplate) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.post(
      `${environment.endpoints.createEmailTemplate}`,
      template,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error upserting email template:", error);
    throw error;
  }
};
