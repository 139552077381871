export const DEPARTMENTS = [
  { value: "sin_departamento", label: "Sin Departamento" },
  { value: "administracion", label: "Administración" },
  { value: "agricultura", label: "Agricultura y Agroindustria" },
  { value: "call_center", label: "Atención al Cliente" },
  { value: "comercio_exterior", label: "Comercio Exterior" },
  { value: "construccion", label: "Construcción e Infraestructura" },
  { value: "diseno_creativo", label: "Diseño y Creatividad" },
  { value: "energia", label: "Energía y Servicios Públicos" },
  { value: "ingenieria", label: "Ingeniería" },
  { value: "farmaceutica", label: "Farmacéutica" },
  { value: "finanzas", label: "Finanzas y Contabilidad" },
  { value: "gastronomia_turismo", label: "Gastronomía y Turismo" },
  { value: "gerencia", label: "Gerencia" },
  { value: "investigacion", label: "Investigación y Desarrollo" },
  { value: "legal", label: "Legal y Cumplimiento" },
  { value: "mineria", label: "Minería y Recursos Naturales" },
  { value: "operaciones", label: "Operaciones y Logística" },
  { value: "produccion", label: "Producción e Ingeniería" },
  { value: "recursos_humanos", label: "Recursos Humanos" },
  { value: "salud_ocupacional", label: "Salud Ocupacional" },
  { value: "seguros", label: "Seguros" },
  { value: "tecnologia", label: "Tecnología" },
  { value: "telecomunicaciones", label: "Telecomunicaciones" },
  { value: "textil", label: "Textil y Confección" },
  { value: "trabajo_social", label: "Trabajo Social" },

  { value: "educacion", label: "Educación y Academia" },
  { value: "ventas", label: "Ventas" },
  { value: "marketing", label: "Marketing y Publicidad" },
  { value: "medio_ambiente", label: "Medio Ambiente y Sostenibilidad" },
  { value: "consultoria", label: "Consultoría" },
] as const;

export const DEPARTMENT_POSITIONS = {
  administracion: [
    "Asistente Administrativo",
    "Coordinador Administrativo",
    "Gerente Administrativo",
    "Secretaria Ejecutiva",
    "Auxiliar Administrativo",
  ],
  agricultura: [
    "Ingeniero Agrónomo",
    "Técnico Agrícola",
    "Supervisor de Campo",
    "Gerente de Producción Agrícola",
    "Especialista en Control de Calidad",
    "Supervisor de Planta",
  ],
  call_center: [
    "Agente de Call Center",
    "Supervisor de Call Center",
    "Coordinador de Atención al Cliente",
    "Gerente de Servicio al Cliente",
    "Representante de Soporte Técnico",
  ],
  comercio_exterior: [
    "Analista de Comercio Exterior",
    "Coordinador",
    "Gerente de Comercio Internacional",
    "Especialista en Aduanas",
    "Broker Internacional",
  ],
  construccion: [
    "Ingeniero Civil",
    "Arquitecto",
    "Supervisor de Obra",
    "Maestro de Obra",
    "Gerente de Proyectos",
    "Ingeniero de Costos",
  ],
  diseno_creativo: [
    "Diseñador Gráfico",
    "Director de Arte",
    "Diseñador UX/UI",
    "Diseñador Web",
    "Ilustrador",
    "Diseñador Industrial",
  ],
  energia: [
    "Técnico de Mantenimiento",
    "Supervisor de Planta",
    "Ingeniero de Proyectos",
    "Especialista en Energías Renovables",
    "Operador de Planta",
  ],
  ingenieria: [
    "Ingeniero Mecánico",
    "Ingeniero Industrial",
    "Ingeniero Electrónico",
    "Ingeniero Eléctrico",
    "Ingeniero de Proyectos",
    "Ingeniero de Calidad",
    "Ingeniero de Mantenimiento",
    "Supervisor",
  ],
  farmaceutica: [
    "Químico Farmacéutico",
    "Investigador",
    "Analista de Control de Calidad",
    "Representante Médico",
    "Gerente de Regulación",
    "Supervisor de Producción",
  ],
  finanzas: [
    "Contador",
    "Analista Financiero",
    "Auditor",
    "Gerente Financiero",
  ],
  gastronomia_turismo: [
    "Chef",
    "Sous Chef",
    "Gerente de Restaurante",
    "Guía Turístico",
    "Coordinador de Eventos",
    "Gerente de Hotel",
    "Recepcionista",
  ],
  gerencia: [
    "Director General",
    "Gerente General",
    "Director de Operaciones",
    "Director Comercial",
    "Vicepresidente",
    "Country Manager",
  ],
  investigacion: [
    "Investigador",
    "Analista de Datos",
    "Coordinador de Proyectos",
    "Estadístico",
  ],
  legal: [
    "Abogado",
    "Asesor Legal",
    "Paralegal",
    "Compliance Officer",
    "Director Legal",
    "Especialista en Contratos",
  ],
  mineria: [
    "Ingeniero de Minas",
    "Geólogo",
    "Supervisor de Operaciones",
    "Ingeniero Metalúrgico",
    "Técnico en Seguridad Minera",
    "Operador de Maquinaria Pesada",
  ],
  operaciones: [
    "Jefe de Operaciones",
    "Coordinador de Logística",
    "Supervisor de Almacén",
    "Analista de Operaciones",
    "Gerente de Supply Chain",
    "Despachador",
  ],
  produccion: [
    "Ingeniero de Producción",
    "Supervisor de Planta",
    "Operador de Máquinas",
    "Ingeniero de Procesos",
    "Control de Calidad",
    "Planificador de Producción",
  ],
  recursos_humanos: [
    "Analista de RRHH",
    "Reclutador",
    "Gerente de RRHH",
    "Especialista en Compensaciones",
    "Coordinador de Capacitación",
  ],
  salud_ocupacional: [
    "Médico Ocupacional",
    "Enfermero Ocupacional",
    "Supervisor de Seguridad",
    "Ergonomista",
    "Coordinador HSE",
    "Prevencionista de Riesgos",
  ],
  seguros: [
    "Corredor de Seguros",
    "Analista de Riesgos",
    "Ejecutivo Comercial",
    "Liquidador de Siniestros",
  ],
  tecnologia: [
    "Desarrollador de Software",
    "Ingeniero de Sistemas",
    "Analista Programador",
    "DevOps Engineer",
    "Arquitecto de Software",
    "Administrador de Sistemas",
    "Soporte Técnico",
    "Project Manager IT",
    "QA Analyst",
    "Especialista en Ciberseguridad",
    "Ingeniero de Datos",
    "Científico de Datos",
  ],
  telecomunicaciones: [
    "Técnico de Redes",
    "Instalador de Fibra Óptica",
    "Ingeniero de Telecomunicaciones",
    "Técnico de Soporte en Campo",
    "Supervisor de Instalaciones",
    "Ingeniero de Transmisión",
  ],
  textil: [
    "Diseñador de Modas",
    "Supervisor de Producción",
    "Control de Calidad",
  ],
  trabajo_social: [
    "Trabajador Social",
    "Psicólogo",
    "Coordinador de Programas Sociales",
    "Orientador Vocacional",
    "Gestor de Casos",
    "Especialista en Desarrollo Comunitario",
  ],

  educacion: [
    "Profesor",
    "Director Académico",
    "Coordinador Educativo",
    "Investigador Académico",
    "Decano",
    "Instructor",
    "Capacitador Corporativo",
  ],
  ventas: [
    "Key Account Manager",
    "Gerente Comercial",
    "Director de Ventas",
    "Coordinador de Ventas",
    "Asesor Comercial",
  ],
  marketing: [
    "Community Manager",
    "Analista de Marketing",
    "Brand Manager",
    "Digital Marketing Specialist",
    "Director de Marketing",
    "Content Manager",
    "Especialista SEO",
    "Marketing Analytics Manager",
  ],
  medio_ambiente: [
    "Ingeniero Ambiental",
    "Gestor de Sostenibilidad",
    "Coordinador Ambiental",
    "Especialista en Economía Circular",
    "Auditor Ambiental",
    "Consultor en Sostenibilidad",
  ],
  consultoria: [
    "Consultor de Negocios",
    "Consultor Estratégico",
    "Consultor de Procesos",
    "Consultor de Recursos Humanos",
    "Consultor Financiero",
  ],
} as const;
