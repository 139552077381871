interface Endpoints {
  //jobs
  getJobs: string;
  getDbLength: string;
  findJob: string;
  updateJobPostById: string;
  findJobByEditKey: string;
  removeJob: string;
  postJob: string;
  // applicants
  getUserJobsById: string;
  getApplicantsById: string;
  updateApplicant: string;
  checkApplicantInDB: string;
  markApplicantAsSeen: string;
  handleApplicantState: string;
  applicantCVPreProcessingHandler: string;
  updateApplicantReviewScore: string;
  addApplicant: string;
  findStateOptionsByJobId: string;
  deleteApplicant: string;
  addApplicantComment: string;
  deleteApplicantActivityComment: string;
  getApplicantActivity: string;
  sendmessage: string;
  handleApplicantRejectedState: string;
  // company
  createCompany: string;
  getCompanyByUser: string;
  deductPostFromCompany: string;
  getApplicantsByCompanyId: string;
  // newsletter
  addNewsletterUser: string;
  removeNewsletterUser: string;
  // company page
  createCompanyPage: string;
  getCompanyPage: string;
  updateCompanyPage: string;
  uploadImageToFirebase: string;
  // business user
  getPostsCount: string;
  addBusinessUser: string;
  getBusinessUsersByCompanyId: string;
  // stripe
  stripeCheckoutSession: string;

  //contact us
  customerSupport: string;

  // upload to firebase storage
  uploadCommentAttachmentImageToFirebase: string;

  // automation
  createAutomationTrigger: string;
  deleteAutomationTrigger: string;
  getAutomationTrigger: string;
  updateAutomationTrigger: string;

  // email
  deleteEmailTemplate: string;

  // email templates
  getEmailTemplates: string;
  createEmailTemplate: string;

  // upload cv to company page
  uploadCvToCompanyPage: string;
}

interface Environment {
  endpoints: Endpoints;
}

interface Config {
  development: Environment;
  production: Environment;
}

const BASE_URL = {
  development: "http://127.0.0.1:5001/hrbot-e8686/southamerica-east1",
  production: "https://",
};

const ENDPOINTS = {
  //jobs
  getJobs: "getJobs",
  getDbLength: "getDbLength",
  findJob: "findJob",
  updateJobPostById: "updateJobPostById",
  findJobByEditKey: "findJobByEditKey",
  removeJob: "removeJob",
  postJob: "postJob",
  // applicants
  getUserJobsById: "getUserJobsById",
  getApplicantsById: "getApplicantsById",
  updateApplicant: "updateApplicant",
  checkApplicantInDB: "checkApplicantInDB",
  markApplicantAsSeen: "markApplicantAsSeen",
  handleApplicantState: "handleApplicantState",
  applicantCVPreProcessingHandler: "applicantCVPreProcessingHandler",
  updateApplicantReviewScore: "updateApplicantReviewScore",
  addApplicant: "addApplicant",
  findStateOptionsByJobId: "findStateOptionsByJobId",
  deleteApplicant: "deleteApplicant",
  addApplicantComment: "addApplicantComment",
  deleteApplicantActivityComment: "deleteApplicantActivityComment",
  getApplicantActivity: "getApplicantActivity",
  sendmessage: "sendmessage",
  handleApplicantRejectedState: "handleApplicantRejectedState",
  // company

  createCompany: "createCompany",
  getCompanyByUser: "getCompanyByUser",
  deductPostFromCompany: "deductPostFromCompany",
  getApplicantsByCompanyId: "getApplicantsByCompanyId",
  // newsletter
  addNewsletterUser: "addNewsletterUser",
  removeNewsletterUser: "removeNewsletterUser",
  // company page
  createCompanyPage: "createCompanyPage",
  getCompanyPage: "getCompanyPage",
  updateCompanyPage: "updateCompanyPage",
  uploadImageToFirebase: "uploadImageToFirebase",
  // business user
  getPostsCount: "getPostsCount",
  addBusinessUser: "addBusinessUser",
  getBusinessUsersByCompanyId: "getBusinessUsersByCompanyId",

  //stripe
  stripeCheckoutSession: "stripeCheckoutSession",

  //contact us
  customerSupport: "customerSupport",

  // upload to firebase storage
  uploadCommentAttachmentImageToFirebase:
    "uploadCommentAttachmentImageToFirebase",

  // automation
  createAutomationTrigger: "createAutomationTrigger",
  deleteAutomationTrigger: "deleteAutomationTrigger",
  getAutomationTrigger: "getAutomationTrigger",
  updateAutomationTrigger: "updateAutomationTrigger",

  // email
  deleteEmailTemplate: "deleteEmailTemplate",

  //email templates
  getEmailTemplates: "getEmailTemplates",
  createEmailTemplate: "createEmailTemplate",

  // upload cv to company page
  uploadCvToCompanyPage: "uploadCvToCompanyPage",
};

const config: Config = {
  development: {
    endpoints: Object.keys(ENDPOINTS).reduce(
      (acc, key) => ({
        ...acc,
        [key]: `${BASE_URL.development}/${ENDPOINTS[key as keyof Endpoints]}`,
      }),
      {} as Endpoints
    ),
  },
  production: {
    endpoints: Object.keys(ENDPOINTS).reduce(
      (acc, key) => ({
        ...acc,
        [key]: `${BASE_URL.production}${
          ENDPOINTS[key as keyof Endpoints]
        }-${"gi2cautoja-rj.a.run.app"}`,
      }),
      {} as Endpoints
    ),
  },
};

const getEnvironment = (): Environment => {
  console.log("NODE_ENV", process.env.NODE_ENV);

  const env = process.env.NODE_ENV || "development";
  return config[env as keyof Config];
};

const validateEnvironment = (env: Environment) => {
  if (!env.endpoints) {
    throw new Error("Endpoints are not configured");
  }
};

const environment = getEnvironment();
validateEnvironment(environment);

export { environment };
