import React, { useState, useRef, useEffect } from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import ApplicantCardPipeline from "./cards/ApplicantCardPipeline";
import ApplicantSideInfo from "../applicants-sidebar/ApplicantSideInfo";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";
import { AutoFixHigh } from "@mui/icons-material";

interface ApplicantPipelineProps {
  applicants: ApplicantInt[];
  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    applicant: ApplicantInt,
    state: string,
    stateOption?: applicantStateOption
  ) => Promise<void>;
  stateOptions: applicantStateOption[];
  selectedApplicant: ApplicantInt;
  setSelectedApplicant: (applicant: ApplicantInt) => void;
  setShowAutomationForm: (stateId: string) => void;
  isRejectedView: boolean;
}

const ApplicantPipeline: React.FC<ApplicantPipelineProps> = ({
  applicants,
  setApplicantsState,
  stateOptions,
  selectedApplicant,
  setSelectedApplicant,
  setShowAutomationForm,
  isRejectedView,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closePopUp = () => {
    setIsPopupOpen(false);
  };

  // const columns = {
  //   Nuevos: applicants.filter((a) => a.state === "Nuevos"),
  //   Guardados: applicants.filter((a) => a.state === "Guardados"),
  //   Rechazados: applicants.filter((a) => a.state === "Rechazados"),
  // };

  const columns = React.useMemo(() => {
    return stateOptions.reduce((acc, state) => {
      acc[state.name] = applicants.filter((a) => a.state === state.id);
      return acc;
    }, {} as Record<string, ApplicantInt[]>);
  }, [stateOptions, applicants]);

  const handleDragStart = (e: React.DragEvent, applicant: ApplicantInt) => {
    e.dataTransfer.setData("applicantId", applicant._id as string);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent, targetColumnName: string) => {
    e.preventDefault();
    const applicantId = e.dataTransfer.getData("applicantId");
    const applicant = applicants.find((a) => a._id === applicantId);
    const targetState = stateOptions.find(
      (state) => state.name === targetColumnName
    );

    if (applicant && targetState) {
      await setApplicantsState(
        e as any,
        applicant,
        targetState.id,
        targetState
      );
    }
  };

  const handleApplicantClick = (
    applicant: ApplicantInt,
    stateOption: applicantStateOption
  ) => {
    setIsPopupOpen(true);
    setSelectedApplicant(applicant);
  };

  const getApplicantNavigation = (applicant: ApplicantInt) => {
    const currentStateOption = stateOptions.find(
      (option) => option.id === applicant.state
    );
    if (!currentStateOption) return { hasNext: false, hasPrevious: false };

    const columnApplicants = columns[currentStateOption.name];
    const currentIndex = columnApplicants.findIndex(
      (a) => a._id === applicant._id
    );

    return {
      hasNext: currentIndex < columnApplicants.length - 1,
      hasPrevious: currentIndex > 0,
      onNext: () => {
        if (currentIndex < columnApplicants.length - 1) {
          setSelectedApplicant(columnApplicants[currentIndex + 1]);
        }
      },
      onPrevious: () => {
        if (currentIndex > 0) {
          setSelectedApplicant(columnApplicants[currentIndex - 1]);
        }
      },
      currentPosition: currentIndex + 1,
      totalCount: columnApplicants.length,
    };
  };

  return (
    <>
      <div className="flex gap-6 w-full overflow-x-auto pb-5 flex-1">
        {isRejectedView && (
          <div className="absolute top-0 left-0 right-0 bg-red-50 py-2 px-4 border-b border-red-200 z-[10000]">
            <div className="flex items-center justify-center gap-2 text-red-700">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="font-medium">
                Visualizando candidatos rechazados
              </span>
            </div>
          </div>
        )}
        {stateOptions.map((stateOption) => {
          const columnApplicants = columns[stateOption.name];

          return (
            <div
              key={stateOption.id}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, stateOption.name)}
              className={`flex-none w-[320px] rounded-lg p-4 ${
                isRejectedView
                  ? "bg-red-50 border border-red-200"
                  : "bg-gray-100"
              }`}
            >
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-2">
                  <h3
                    className={`text-lg font-semibold ${
                      isRejectedView ? "text-red-700" : ""
                    }`}
                  >
                    {stateOption.name}
                  </h3>
                  <span
                    className={`px-2 py-0.5 rounded-full text-sm ${
                      isRejectedView
                        ? "bg-red-100 text-red-700"
                        : "bg-gray-200 text-gray-600"
                    }`}
                  >
                    {columnApplicants.length}
                  </span>
                </div>
                {stateOption.automationTriggerId && (
                  <AutoFixHigh
                    className={`hover:cursor-pointer ${
                      isRejectedView ? "text-red-500" : "text-purple-500"
                    }`}
                    style={{ fontSize: "20px" }}
                    onClick={() => setShowAutomationForm(stateOption.id)}
                  />
                )}
              </div>
              <div className="overflow-y-auto scrollbar-hide h-[calc(100%-3rem)] flex flex-col gap-2">
                {columnApplicants.map((applicant) => (
                  <ApplicantCardPipeline
                    key={applicant._id}
                    applicant={applicant}
                    handleDragStart={handleDragStart}
                    onClick={() => handleApplicantClick(applicant, stateOption)}
                    isRejectedView={isRejectedView}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {selectedApplicant && isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[110]">
          <div
            ref={popupRef}
            className="bg-white rounded-lg max-w-5xl w-full h-full max-h-[90vh] overflow-y-auto relative flex flex-col"
          >
            <ApplicantSideInfo
              selected={selectedApplicant}
              setApplicantsState={setApplicantsState}
              stateOptions={stateOptions}
              closePopUp={closePopUp}
              enableNavigation={true}
              {...getApplicantNavigation(selectedApplicant)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicantPipeline;
