import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";

import CircularProgress from "@mui/material/CircularProgress";
import { coordResultsProps } from "../../typescript/interfaces/MapLocationInt";

const libraries: ("places" | "geometry" | "drawing")[] = ["places"];

interface LocationMapSelectorProps {
  setMapOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setProximity?: React.Dispatch<React.SetStateAction<boolean>>;
  fixedRadius?: boolean;
  closerZoom?: boolean;
  setMapResults: React.Dispatch<
    React.SetStateAction<coordResultsProps | undefined>
  >;
  onSubmit: (results: coordResultsProps) => void;
}
function LocationMapSelector(props: LocationMapSelectorProps) {
  const [address, setAddress] = useState("");
  const [radius, setRadius] = useState(props.fixedRadius ? 500 : 10000); // default radius in meters
  const [center, setCenter] = useState({ lat: -0.1807, lng: -78.4678 }); // Quito, Ecuador coordinates
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [circleColor, setCircleColor] = useState("#FF0000"); // Add state for circle color
  const [submitButtonStyle, setSubmitButtonStyle] = useState({
    backgroundColor: "rgb(21, 188, 127)",
    color: "white",
  }); // Add state for submit button style

  const circleRef = useRef<google.maps.Circle | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB3OJgL9-g2f6rIxua7rxV0ST2hcgsqppw", // Use your API key here
    libraries,
  });

  // Pan the map to the new center when 'center' state changes
  useEffect(() => {
    if (map) {
      map.panTo(center);
    }
    if (markerRef.current) {
      markerRef.current.setPosition(center);
    }
  }, [center, map]);

  // Update the circle's center and radius when they change
  useEffect(() => {
    if (map) {
      if (!circleRef.current) {
        circleRef.current = new google.maps.Circle({
          map,
          center: center,
          radius: radius,
          fillColor: circleColor, // Use circleColor state
          fillOpacity: 0.2,
          strokeColor: circleColor, // Use circleColor state
          strokeOpacity: 0.5,
          strokeWeight: 2,
        });
      } else {
        circleRef.current.setCenter(center);
        circleRef.current.setRadius(radius);
        circleRef.current.setOptions({
          fillColor: circleColor,
          strokeColor: circleColor,
        }); // Update circle color
      }
    }
  }, [map, center, radius, circleColor]); // Add circleColor to dependency array

  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
      mapRef.current = map;

      map.setOptions({
        streetViewControl: false,
        gestureHandling: "greedy",
        disableDefaultUI: true, // Disable default UI
      });

      // Create a centered marker using markerRef
      if (!markerRef.current) {
        markerRef.current = new google.maps.Marker({
          map,
          position: map.getCenter(),
        });
      } else {
        markerRef.current.setMap(map);
        markerRef.current.setPosition(map.getCenter());
      }

      // Remove circle creation from here

      // Update drag event listeners
      map.addListener("drag", () => {
        const newCenter = map.getCenter();
        if (newCenter) {
          markerRef.current?.setPosition(newCenter);
          circleRef.current?.setCenter(newCenter);
        }
      });

      map.addListener("dragend", () => {
        const newCenter = map.getCenter();
        if (newCenter) {
          setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
        }

        // Reset circle color and submit button style
        setCircleColor("#FF0000");
        setSubmitButtonStyle({
          backgroundColor: "rgb(21, 188, 127)",
          color: "white",
        });
        // Set map results to null when the map is moved
        props.setMapResults(undefined);
      });
    },
    // here maybe use another useffect to do this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [radius, props] // Add props.setMapResults to dependency array
  );

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);

    // Set Autocomplete options for Ecuador
  };

  const onPlaceChanged = () => {
    setIsAddressLoading(false);
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const newCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setCenter(newCenter);
        setAddress(place.formatted_address || "");
        // Reset circle color and submit button style
        setCircleColor("#FF0000");
        setSubmitButtonStyle({
          backgroundColor: "rgb(21, 188, 127)",
          color: "white",
        });
        // Set map results to null when a new place is selected
        props.setMapResults(undefined);
      }
    }
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    if (newAddress) {
      setIsAddressLoading(true);
    } else {
      setIsAddressLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRadius = Number(e.target.value);
    setRadius(newRadius);
    if (circleRef.current) {
      circleRef.current.setRadius(newRadius);
    }
    // Reset circle color and submit button style
    setCircleColor("#FF0000");
    setSubmitButtonStyle({
      backgroundColor: "rgb(21, 188, 127)",
      color: "white",
    });
    // Set map results to null when the radius is changed
    props.setMapResults(undefined);
  };

  const onSubmit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent event propagation
    e.preventDefault();

    // Close the map
    // Call the onSubmit prop
    props.onSubmit({ center, radius });
    props.setMapResults({ center, radius });

    // Change circle color and submit button style
    setCircleColor("green");
    setSubmitButtonStyle({ backgroundColor: "gray", color: "white" });
  };

  if (!isLoaded) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="locationMapSelector">
      <div className="locationMapSelector-content">
        {isLoaded && (
          <div style={{ position: "relative" }}>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                value={address}
                className=""
                onChange={handleAddressChange}
                onKeyDown={handleKeyDown} // Add this line
                // make this go between company and personal message
                placeholder="Ingrese su dirección o área general"
                style={{
                  padding: "10px 5px",
                  width: "100%",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid rgba(0,0,0,0.2)",
                }}
              />
            </Autocomplete>
            <div
              style={{
                fontSize: "12px",
                paddingLeft: "5px",
                color: "rgba(0,0,0,0.5)",
              }}
            >
              Al ingresar esta información, aceptas que será utilizada
              únicamente para el proceso de aplicación. No sera compartida con
              terceros.
            </div>
            {isAddressLoading && address && (
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "25px",
                  transform: "translateY(-50%)",
                }}
              >
                <CircularProgress size={20} style={{ color: "green" }} />
              </div>
            )}
          </div>
        )}
        <div style={{ position: "relative" }}>
          <GoogleMap
            mapContainerStyle={{
              height: "400px",
              width: "100%",
              borderRadius: "10px",
            }}
            center={center}
            zoom={props.closerZoom ? 14 : 10}
            onLoad={onMapLoad}
            options={{
              disableDefaultUI: true,
              scrollwheel: true,
              zoomControl: window.innerWidth > 750,
            }}
          />
          {!props.fixedRadius && (
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                background: "rgba(255, 255, 255, 0.8)",
                padding: "10px",
                borderRadius: "5px",
                width: "80%",
                maxWidth: "300px",
              }}
            >
              <p style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                Radio: {Math.round(radius / 1000)} kilometros
              </p>
              <input
                style={{
                  fontSize: "14px",
                  width: "100%",
                  height: "20px",
                  accentColor: "rgb(21, 188, 127)",
                }}
                type="range"
                min="1000"
                max="50000"
                value={radius}
                onChange={handleRadiusChange}
              />
            </div>
          )}
        </div>

        <div className="w100 flx flx-center bottom-button-container">
          <div
            className="bg-laburo-green w100  "
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 20px ",
              textAlign: "center",
              marginTop: "10px",

              borderRadius: "5px",
              height: "50px",
              ...submitButtonStyle, // Apply submit button style
            }}
            onClick={onSubmit}
          >
            Confirmar Área
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationMapSelector;
