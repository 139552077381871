import axios from "axios";
import CompanyPageInt from "../typescript/interfaces/CompanyPageInt";
import { environment } from "../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../firebase";
const createCompanyPage = async (data: Partial<CompanyPageInt>) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.post(
      `${environment.endpoints.createCompanyPage}`,
      data,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return response.data.companyPage;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Handle specific HTTP error codes
      if (error.response.status === 409) {
        throw new Error("Ya existe una página de empresa con este subdominio");
      }
      // Handle other specific error messages from the backend
      if (error.response.data?.message) {
        throw new Error(error.response.data.message);
      }
    }
    // Fallback error
    console.error("Error al crear la página de empresa:", error);
    throw new Error("No se pudo crear la página de empresa");
  }
};

const getCompanyPage = async (subdomainId?: string, companyId?: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    // Create URL parameters only for defined values
    const params = new URLSearchParams();
    if (subdomainId) params.append("subdomainId", subdomainId);
    if (companyId) params.append("companyId", companyId);

    const response = await axios.get(
      `${environment.endpoints.getCompanyPage}?${params.toString()}`,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    return response.data.companyPage;
  } catch (error) {
    console.error("Error getting company page:", error);
    throw error;
  }
};

const updateCompanyPage = async (companyId: string, updates: any) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.put(
      `${environment.endpoints.updateCompanyPage}`,
      { companyId, updates },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return response.data.companyPage;
  } catch (error) {
    console.error("Error updating company page:", error);
    throw error;
  }
};

const uploadCompanyPageImageToFirebase = async (
  file: File,
  companyId: string,
  type: "logo" | "banner"
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("companyId", companyId);
    formData.append("imageType", type);

    const response = await axios.post(
      `${environment.endpoints.uploadImageToFirebase}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return {
      path: response.data.path,
      success: true,
    };
  } catch (error) {
    console.error("Error uploading image to firebase:", error);
    return {
      path: null,
      success: false,
    };
  }
};

export {
  createCompanyPage,
  getCompanyPage,
  updateCompanyPage,
  uploadCompanyPageImageToFirebase,
};
