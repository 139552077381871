import axios from "axios";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
const getApplicantActivity = async (applicantId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.get(
      `${environment.endpoints.getApplicantActivity}?applicantId=${applicantId}`,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching applicant activity:", error);
    throw error;
  }
};

export default getApplicantActivity;
