import React, { useState, useEffect, useRef } from "react";

interface SearchableDropDownProps {
  options: { name: string; id: string }[];
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  size?: "small" | "default";
}

function SearchableDropDownWithId({
  options,
  placeholder = "Search...",
  value = "",
  onChange,
  size = "default",
}: SearchableDropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Add normalizeText function to remove diacritics and special characters
  const normalizeText = (text: string): string => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .toLowerCase();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const filtered = options.filter((option) => {
      const normalizedOption = normalizeText(option.name);
      const normalizedSearch = normalizeText(searchTerm);
      return normalizedOption.includes(normalizedSearch);
    });
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const handleSelect = (option: { name: string; id: string }) => {
    setSearchTerm(option.name);
    onChange?.(option.id);
    setIsOpen(false);
  };

  // Define size-specific classes
  const inputClasses = `w-full px-4 ${
    size === "small" ? "py-1.5 text-sm" : "py-2"
  } text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none truncate pr-20`;

  const optionClasses = `px-4 ${
    size === "small" ? "py-1.5 text-sm" : "py-2"
  } cursor-pointer hover:bg-gray-100`;

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div className="relative">
        <input
          type="text"
          className={inputClasses}
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => {
            e.preventDefault();
            setSearchTerm(e.target.value);
          }}
          onFocus={() => setIsOpen(true)}
        />
        {searchTerm && (
          <button
            type="button"
            className="absolute right-8 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSearchTerm("");
              onChange?.("");
            }}
            aria-label="Clear search"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
        <button
          type="button"
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <svg
            className={`${size === "small" ? "w-4 h-4" : "w-5 h-5"}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className={optionClasses}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelect(option);
                }}
              >
                {option.name}
              </div>
            ))
          ) : (
            <div className={optionClasses}>No results found</div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchableDropDownWithId;
