import React, { useState, useEffect, useRef } from "react";
import { useLoadScript, Libraries } from "@react-google-maps/api";

interface AutocompleteProps {
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<LocationData | undefined>
  >;
  setGrayButton?: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder?: string;
  border?: boolean;
  value?: { city: string; country: string };
  className?: string;
}

interface LocationData {
  city: string;
  country: string;
  latitude: number;
  longitude: number;
}

const libraries: Libraries = ["places"];
function AutocompleteLocation({
  setSelectedLocation,
  setGrayButton,
  placeholder,
  border,
  value,
  className,
}: AutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [dotCount, setDotCount] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Update inputValue when value prop changes
  useEffect(() => {
    if (value?.city && value?.country) {
      setInputValue(`${value.city}, ${value.country}`);
    }
  }, [value]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB3OJgL9-g2f6rIxua7rxV0ST2hcgsqppw",
    libraries,
  });

  useEffect(() => {
    if (isLoaded && window.google) {
      autocompleteService.current =
        new google.maps.places.AutocompleteService();
      // We need a HTML element to create PlacesService
      const dummyElement = document.createElement("div");
      placesService.current = new google.maps.places.PlacesService(
        dummyElement
      );
    }
  }, [isLoaded]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsLoading(true);
    setCursorPosition(e.target.selectionStart || 0);

    if (newValue === "") {
      setSuggestions([]);
      setSelectedLocation(undefined);
      setIsLoading(false);
      if (setGrayButton) {
        setGrayButton(false);
      }
      return;
    }

    try {
      if (autocompleteService.current) {
        const results = await autocompleteService.current.getPlacePredictions({
          input: newValue,
          types: ["(cities)"],
          componentRestrictions: { country: ["ec", "pe", "co", "ar", "mx"] },
        });
        setSuggestions(results.predictions);
        setShowSuggestions(true);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
    setIsLoading(false);
  };

  const handleSelectPlace = async (placeId: string) => {
    setIsLoading(true);
    try {
      if (placesService.current) {
        placesService.current.getDetails(
          {
            placeId: placeId,
            // Request more fields to ensure we get all needed data
            fields: [
              "address_components",
              "geometry",
              "formatted_address",
              "name",
            ],
          },
          (place, status) => {
            if (status === "OK" && place && place.address_components) {
              // Find city and country from address components
              let city = "";
              let country = "";

              for (const component of place.address_components) {
                if (component.types.includes("locality")) {
                  city = component.long_name;
                }
                if (component.types.includes("country")) {
                  country = component.long_name;
                }
              }

              // Fallback to first component if city not found
              if (!city && place.address_components.length > 0) {
                city = place.address_components[0].long_name;
              }

              const data: LocationData = {
                city: city,
                country: country,
                latitude: place.geometry?.location?.lat() || 0,
                longitude: place.geometry?.location?.lng() || 0,
              };

              console.log("Selected location:", data); // Debug log
              setSelectedLocation(data);
              setInputValue(`${city}, ${country}`);
              if (setGrayButton) {
                setGrayButton(false);
              }
            } else {
              console.error("Place details status:", status); // Debug log
            }
          }
        );
      }
    } catch (err) {
      console.error("Error selecting place:", err);
      if (setGrayButton) {
        setGrayButton(true);
      }
    }
    setShowSuggestions(false);
    setIsLoading(false);
  };

  // Updated useEffect for dot animation
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isLoading) {
      interval = setInterval(() => {
        setDotCount((prevCount) => (prevCount + 1) % 4);
      }, 150); // Change dot every 150ms (faster)
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  // Updated useEffect for handling clicks outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest(".suggestions-dropdown")
      ) {
        setShowSuggestions(false);
      }
    }

    // Add event listener when suggestions are shown
    if (showSuggestions) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSuggestions]);

  if (!isLoaded) {
    return <div>Cargando...</div>;
  }

  return (
    <div id="searchColumn" className="w100">
      <div className="relative-container" style={{ position: "relative" }}>
        <div
          ref={wrapperRef}
          className={`${border === false ? "" : "search-pill"} ${className}`}
          style={{
            borderBottomLeftRadius:
              showSuggestions && suggestions.length > 0 ? 0 : undefined,
            borderBottomRightRadius:
              showSuggestions && suggestions.length > 0 ? 0 : undefined,
            borderBottom:
              showSuggestions && suggestions.length > 0 ? "none" : undefined,
          }}
        >
          <input
            type="text"
            className="search-pill-input"
            placeholder={placeholder ? placeholder : ""}
            onChange={handleInputChange}
            value={inputValue}
          />
          {isLoading && (
            <div
              style={{
                position: "absolute",
                left: `${cursorPosition * 8 + 15}px`,
                top: "60%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                fontSize: "24px",
                display: "flex",
                gap: "4px", // Add space between dots
              }}
            >
              {[...Array(dotCount)].map((_, index) => (
                <div
                  key={index}
                  style={{
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    backgroundColor: "currentColor",
                  }}
                />
              ))}
            </div>
          )}
        </div>
        {showSuggestions && suggestions.length > 0 && (
          <div
            className="suggestions-dropdown"
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: "0 0 10px 10px",
              zIndex: 1000,
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {suggestions.map((suggestion, index) => (
              <div
                key={suggestion.place_id}
                onClick={() => handleSelectPlace(suggestion.place_id)}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  backgroundColor: "white",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#f5f5f5";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                }}
              >
                {suggestion.description}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AutocompleteLocation;
