import axios from "axios";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
export const getEmailTemplates = async (companyId: string) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.get(
      `${environment.endpoints.getEmailTemplates}?companyId=${companyId}`,
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );

    return response.data.result;
  } catch (error) {
    console.error("Error fetching templates:", error);
    return [];
  }
};
