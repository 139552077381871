import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useSearchParams } from "react-router-dom";
import {
  generateJobPostingStructuredData,
  getJobById,
} from "../utils/jobsUtils";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";

import LoadingWidget from "../components/widgets/LoadingWidget";
import ResumeUploader from "../components/resume/ResumeUploader";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { JobInt } from "../typescript/interfaces/JobInterface";
import JobPost from "../components/JobPost";
import { checkApplicantExists, parseResume } from "../utils/applicantFunctions";
import { Question } from "../typescript/interfaces/CustomQuestionInt";
import {
  AnswersInt,
  Education,
  WorkExperience,
} from "../typescript/interfaces/AppInterface";
import LocationMapSelector from "../components/create-job/LocationMapSelector";
import { coordResultsProps } from "../typescript/interfaces/MapLocationInt";
import TextEditorVisualizer from "../components/text-editor/TextEditorVisualizer";
import JobExperienceSection from "../components/job-apply/JobExperienceSection";
import EducationSection from "../components/job-apply/EducationSection";
import { environment } from "../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../firebase";
import AutocompleteLocation from "../components/AutocompleteLocation";
import { LocationData } from "../typescript/interfaces/Location";
import { useNotification } from "../components/context/NotificationContext";

function JobApply() {
  const storage = getStorage();

  const [jobId, setJobId] = useState("");
  const [jobName, setJobName] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description] = useState("");
  const [searchParams] = useSearchParams();
  const [formAlert, setformAlert] = useState(false);
  const [userAlreadyExistsAlert, setUserAlreadyExistsAlert] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currJob, setCurrJob] = useState<JobInt>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState<File>();
  const alertBox = useRef<HTMLDivElement>(null);
  const [answers, setAnswers] = useState<AnswersInt>({});
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [noJobMatching, setNoJobMatching] = useState(false);
  const [mapResults, setMapResults] = useState<coordResultsProps>();
  const [educationList, setEducationList] = useState<Education[]>([]);
  const [experienceList, setExperienceList] = useState<WorkExperience[]>([]);
  const [department, setDepartment] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [location, setLocation] = useState<LocationData>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parsingResume, setParsingResume] = useState(false);
  const [yearsOfExperience, setYearsOfExperience] = useState<string>("menos_1");
  const [currentStep, setCurrentStep] = useState(1);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { showNotification } = useNotification();
  const [emptyFields, setEmptyFields] = useState<{
    resume: boolean;
    name: boolean;
    email: boolean;
    location: boolean;
    number: boolean;
    questions: boolean;
    map: boolean;
  }>({
    resume: false,
    name: false,
    email: false,
    location: false,
    number: false,
    questions: false,
    map: false,
  });
  const handleAnswerChange = (question: string, answer: string) => {
    setEmptyFields((prev) => ({ ...prev, questions: false }));
    setAnswers((prev) => ({ ...prev, [question]: answer }));
  };

  const handleScroll = () => {
    if (alertBox.current) {
      // Get the element's current position relative to the top of the viewport
      const elementTop = alertBox.current.getBoundingClientRect().top;

      // Calculate the offset needed to place the element 70px from the top
      const offset = elementTop - 80;

      // Scroll the window by the calculated offset
      window.scrollBy({
        top: offset,
        behavior: "smooth",
      });
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const questions =
      currJob?.questions?.every((question) => {
        return (
          answers[question.question] !== undefined &&
          answers[question.question] !== "" &&
          answers[question.question] !== null
        );
      }) || currJob?.questions === undefined;

    let mapReq =
      (mapResults && mapResults.center.lat && mapResults.center.lng) ||
      currJob?.mapResults === undefined;

    // Update empty fields state
    setEmptyFields({
      resume: !resume,
      name: !name,
      email: !email,
      location: !location?.city,
      number: !number,
      questions: !questions,
      map: !mapReq,
    });

    if (
      resume &&
      name &&
      number &&
      email &&
      questions &&
      mapReq &&
      location?.city
    ) {
      setformAlert(false);
      setLoading(true);

      try {
        // Check if email is already registered
        const userExists = await checkApplicantExists(jobId, email);
        if (!userExists) {
          let url = ""; // Default to empty string if no file is uploaded

          if (resume instanceof File) {
            // Proceed with the file upload
            const storageRef = ref(
              storage,
              `/files/${new Date().getTime()}__${jobId}_${name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, resume);

            try {
              await new Promise((resolve, reject) => {
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const percent =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadStatus(percent);
                  },
                  (err) => reject(err),
                  () => resolve(uploadTask.snapshot.ref)
                );
              });
              url = await getDownloadURL(uploadTask.snapshot.ref);
            } catch (err) {
              showNotification(
                "Error al subir el archivo: Por favor asegurese de que el archivo sea un PDF, DOC, o DOCX y que no supere los 4MB.",
                "error"
              );
              setLoading(false);
              return; // Stop further execution on error
            }
          }

          // Prepare data to send, regardless of whether a file was uploaded
          const apiData = {
            to: currJob?.recieveEmail,
            subject: "Aplicación para la posición de " + jobName,
            html: description,
            company: currJob?.company,
            name,
            number,
            email,
            date: new Date(),
            description,
            jobName: jobName,
            resumeUrl: url,
            jobId: jobId,
            answers: answers,
            mapResults,
            fileType: resume?.type,
            educationList,
            experienceList,
            state: currJob?.applicantStateOptions?.[0]?.id || "",
            stateOption: currJob?.applicantStateOptions?.[0] || "",

            companyId: currJob?.companyId,
            workExperienceYears: yearsOfExperience,
            assignedTo: currJob?.assignedTo,
            department,
            position,
            location,
          };
          const apiUrl = `${environment.endpoints.sendmessage}`;
          const appCheckToken = await getAppCheckToken(appCheck);
          try {
            await axios.post(apiUrl, apiData, {
              headers: {
                "X-Firebase-AppCheck": appCheckToken.token,
              },
            });
            navigate("/thank-you");
          } catch (error) {
            showNotification(
              "Error: No se pudo enviar la aplicación: " + error,
              "error"
            );
          } finally {
            setLoading(false);
          }
        } else {
          showNotification(
            "¡Ya te has postulado a esta posición! No puedes volver a aplicar 😔",
            "error"
          );
          setLoading(false);
        }
      } catch (error) {
        showNotification("Error al enviar el formulario: " + error, "error");
        setLoading(false);
      }
    } else {
      showNotification(
        "Por favor completa todos los campos requeridos",
        "error"
      );
      setformAlert(true);
      setLoading(false);
    }
  };
  const showEmptyFields = () => {
    // check if the number of answers is the same as the number of questions

    let questionsReq =
      Object.keys(answers)?.length === currJob?.questions?.length ||
      currJob?.questions === undefined;

    let mapReq =
      (mapResults && mapResults.center.lat && mapResults.center.lng) ||
      currJob?.mapResults === undefined;

    let names = [
      "CV",
      "Nombre",
      "Email",
      "Ciudad de Domicilio",
      "Teléfono",
      "Preguntas Adicionales",
      "Ubicación",
    ];
    // let names = ["CV", "Nombre", "Email", "Teléfono"];
    let form = [
      resume,
      name,
      email,
      location?.city,
      number,
      questionsReq,
      mapReq,
    ];

    // let form = [resume, name, email, number];
    return form.map((val, index) => {
      if (!val) {
        return <div key={index}>{names[index]}</div>;
      }
      return null;
    });
  };

  const handleInvalidEmail = (e: React.FormEvent<HTMLInputElement>) => {
    // Set a custom message in Spanish
    // e.currentTarget.setCustomValidity(
    //   "Por favor introduce una dirección de correo válida."
    // );
  };

  // disable button until the data is populated

  const handleMapSubmit = (results: coordResultsProps) => {
    console.log("results");
    setEmptyFields((prev) => ({ ...prev, map: false }));
  };
  useEffect(() => {
    // const name = searchParams.get("name");
    const fetchJobData = async () => {
      // get the job data from aprams
      const id = searchParams.get("id");
      let ID = searchParams.get("ID");
      try {
        if (id) {
          const jobData = await getJobById(id);
          setJobId(id);
          setCurrJob(jobData);
          setJobName(jobData.title);
          setDepartment(jobData.department);
          setPosition(jobData.position);
          document.title = `${jobData.title} - ${jobData.company}`;
          updateMetaTags(
            jobData.title,
            jobData.description,
            "URL to your image",
            window.location.href
          );
          // Inject structured data
          const script = document.createElement("script");
          script.type = "application/ld+json";
          script.innerHTML = JSON.stringify(
            generateJobPostingStructuredData(jobData)
          );
          document.head.appendChild(script);
        } else if (ID) {
          ID = ID.toLowerCase();
          const jobData = await getJobById(ID);
          setJobId(ID);
          setCurrJob(jobData);
          setJobName(jobData.title);
          const script = document.createElement("script");
          script.type = "application/ld+json";
          script.innerHTML = JSON.stringify(
            generateJobPostingStructuredData(jobData)
          );
          document.head.appendChild(script);
        } else {
          setNoJobMatching(true);
        }
      } catch (err) {
        console.error("Error fetching job data:", err);
        setNoJobMatching(true);
      }
    };

    fetchJobData();
  }, [searchParams]);

  useEffect(() => {
    if (formAlert || userAlreadyExistsAlert) {
      handleScroll();
      setTimeout(() => {
        if (formAlert) {
          setformAlert(false);
        } else {
          setUserAlreadyExistsAlert(false);
        }
      }, 10000);
    }
  }, [formAlert, userAlreadyExistsAlert]);

  useEffect(() => {
    // Check if we have all the required fields filled
    if (resume && name && email && number) {
      setCurrentStep(3);
    } else if (resume) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  }, [resume, name, email, number]);

  const updateMetaTags = (
    title: string,
    description: string,
    imageUrl: string,
    url: string
  ) => {
    const metaTitle = document.getElementById("meta-og-title");
    const metaDescription = document.getElementById("meta-og-description");
    // const metaImage = document.getElementById("meta-og-image");
    const metaUrl = document.getElementById("meta-og-url");

    if (metaTitle) metaTitle.setAttribute("content", title);
    if (metaDescription) metaDescription.setAttribute("content", description);
    // if (metaImage) metaImage.setAttribute("content", imageUrl);
    if (metaUrl) metaUrl.setAttribute("content", url);
  };

  const addExperience = () => {
    setExperienceList([...experienceList, {} as WorkExperience]);
  };
  const addEducation = () => {
    setEducationList([...educationList, {} as Education]);
  };
  const handleEducationChange = (
    index: number,
    field: keyof Education,
    value: string | boolean
  ) => {
    const updatedEducation = [...educationList];
    updatedEducation[index] = { ...updatedEducation[index], [field]: value };
    setEducationList(updatedEducation);
    console.log("education list");
    console.log(updatedEducation);
  };
  const handleResumeUpload = async (file: File | undefined) => {
    setResume(file);
    setLoading(true);
    try {
      setParsingResume(true);
      setCurrentStep(2); // Move to step 2 when starting to process

      if (file) {
        console.log("parsing resume");
        const parsedData = await parseResume(file);
        console.log("parsed resume data");
        console.log(parsedData);
        setEducationList(parsedData.educacion as Education[]);
        setExperienceList(parsedData.experiencia_laboral as WorkExperience[]);
        setName(parsedData.nombre);
        setEmail(parsedData.email);
        setNumber(parsedData.telefono);
        setYearsOfExperience(parsedData.rango_años_experiencia);
        setCurrentStep(3); // Move to step 3 when processing is complete
      }
    } catch (error) {
      console.error("Error parsing resume:", error);
      setCurrentStep(1); // Go back to step 1 if there's an error
    } finally {
      setLoading(false);
      setParsingResume(false);
    }
  };
  const handleEducationDelete = (index: number) => {
    const updatedEducation = educationList.filter((_, i) => i !== index);
    setEducationList(updatedEducation);
  };
  function handleJobExperienceChange(
    index: number,
    field: keyof WorkExperience,
    value: string | boolean
  ): void {
    const updatedExperience = [...experienceList];
    updatedExperience[index] = { ...updatedExperience[index], [field]: value };
    setExperienceList(updatedExperience);
  }
  const handleJobExperienceDelete = (index: number) => {
    const updatedExperience = experienceList.filter((_, i) => i !== index);
    setExperienceList(updatedExperience);
  };

  const getProgressWidth = () => {
    switch (currentStep) {
      case 1:
        return "w-[0%]";
      case 2:
        return "w-[50%]";
      case 3:
        return "w-[100%]";
      default:
        return "w-[0%]";
    }
  };

  const handleRemove = () => {
    // Clear the internal state and prop
    setResume(undefined);
    // Reset all form fields
    setName("");
    setEmail("");
    setNumber("");
    setEducationList([]);
    setExperienceList([]);
    setYearsOfExperience("menos_1");
    // Reset to step 1
    setCurrentStep(1);
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className=" flx-col skip-navbar-margin ">
      <Navbar scrollPast={true} hidePanel={true} hideSignIn={true} />

      <div
        className="flx-col  w100"
        style={{ minHeight: "100vh", alignItems: "center" }}
      >
        {noJobMatching ? (
          <div
            className="flx flx-center flx-col mt-50"
            style={{ fontSize: "20px" }}
          >
            <div>El trabajo que buscas no existe 😔</div>
            <div className=" thank-you-txt-more-jobs  ">
              <div>Sigue explorando más oportunidades haciendo click aquí:</div>

              <div className="w100 flx flx-center mt-25">
                <Link
                  to="/jobs"
                  className="aplicar-btn button-hover"
                  style={{ color: "white" }}
                >
                  Más Trabajos
                </Link>
              </div>
            </div>
          </div>
        ) : currJob && currJob.status === false ? (
          <div
            className="flx flx-center flx-col mt-50"
            style={{ fontSize: "20px" }}
          >
            <div>
              Lo sentimos, esta oferta de trabajo ya no está disponible.
            </div>
            <div className=" thank-you-txt-more-jobs  ">
              <div>Explora más oportunidades haciendo clic aquí:</div>

              <div className="w100 flx flx-center mt-25">
                <Link
                  to="/jobs"
                  className="aplicar-btn button-hover"
                  style={{ color: "white" }}
                >
                  Más Trabajos
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flx flx-col flx-center">
            <div className=" job-des-content-apply  ">
              <div className="mt-25 w100 mb-25">
                {/* link back to job des prior */}

                <Link
                  to={`/jobs`}
                  className="link-style "
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    fontSize: "18px",
                  }}
                >
                  <ArrowBackIcon /> Regresar
                </Link>
              </div>
              <div className="w100 mb-25">
                {currJob ? (
                  <JobPost currJob={currJob} />
                ) : (
                  <div className="w100 flx flx-center">
                    <LoadingWidget loading={true} />
                  </div>
                )}
              </div>

              <div className="mb-50 w100 " style={{ padding: "0px 20px" }}>
                <div className="mb-25 txt-s4"> Descripción: </div>
                {currJob && (
                  <TextEditorVisualizer htmlContent={currJob.description} />
                )}

                <div
                  className="w100"
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    marginTop: "25px",
                  }}
                ></div>
              </div>

              {formAlert && (
                <div className="w100 mb-25" ref={alertBox}>
                  <Alert severity="error" style={{ borderRadius: "10px" }}>
                    <div className="">
                      Por favor completa estos campos: {showEmptyFields()}
                    </div>
                  </Alert>
                </div>
              )}
              {userAlreadyExistsAlert && (
                <div className="w100 mb-25" ref={alertBox}>
                  <Alert severity="error" style={{ borderRadius: "10px" }}>
                    <div className="">
                      ¡Ya te has postulado a esta posición! No puedes volver a
                      aplicar 😔
                    </div>
                  </Alert>
                </div>
              )}
              <div
                className="w100 flx flx-col"
                style={{ gap: "30px", height: "100%" }}
              >
                <div className="w100 postjob-gray-container">
                  <div className="flex flex-col mb-12 pt-8">
                    {/* Steps Container */}
                    <div className="relative flex justify-center items-center mb-12 px-4 w-full">
                      {/* Progress Line Container */}
                      <div className="absolute top-[25px] left-0 right-0 flex justify-center">
                        <div className="relative h-1 w-[80%] max-w-[600px]">
                          {/* Background Line */}
                          <div className="absolute inset-0 bg-gray-200 rounded-full"></div>
                          {/* Progress Line */}
                          <div
                            className={`absolute inset-0 bg-gradient-to-r from-purple-500 to-purple-600 rounded-full transition-all duration-700 ease-in-out ${getProgressWidth()}`}
                            style={{
                              boxShadow: "0 2px 4px rgba(139, 92, 246, 0.3)",
                            }}
                          ></div>
                        </div>
                      </div>

                      {/* Steps */}
                      <div className="flex justify-between w-full max-w-[700px] px-4 relative">
                        {[
                          {
                            number: 1,
                            icon: (
                              <svg
                                className="w-5 h-5 sm:w-7 sm:h-7 text-current"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                />
                              </svg>
                            ),
                            title: "Subir CV",
                            subtitle: "Carga tu hoja de vida",
                          },
                          {
                            number: 2,
                            icon: (
                              <svg
                                className="w-5 h-5 sm:w-7 sm:h-7 text-current"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>
                            ),
                            title: "Autocompletar",
                            subtitle: "Llenamos tus datos",
                          },
                          {
                            number: 3,
                            icon: (
                              <svg
                                className="w-5 h-5 sm:w-7 sm:h-7 text-current"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            ),
                            title: "Postular",
                            subtitle: "Valida y envía",
                          },
                        ].map((step, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center relative group"
                          >
                            {/* Step Circle */}
                            <div
                              className={`
                                w-12 h-12 sm:w-16 sm:h-16 rounded-full flex items-center justify-center mb-2 sm:mb-4
                                transition-all duration-500 ease-in-out
                                ${
                                  currentStep === step.number
                                    ? "bg-purple-500 ring-2 sm:ring-4 ring-purple-100 scale-110"
                                    : currentStep > step.number
                                    ? "bg-purple-500"
                                    : "bg-gray-200"
                                }
                                ${currentStep >= step.number ? "shadow-lg" : ""}
                              `}
                            >
                              {currentStep > step.number ? (
                                <svg
                                  className="w-6 h-6 sm:w-8 sm:h-8 text-white"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              ) : (
                                <div
                                  className={`${
                                    currentStep >= step.number
                                      ? "text-white"
                                      : "text-gray-500"
                                  }`}
                                >
                                  {step.icon}
                                </div>
                              )}
                            </div>

                            {/* Step Text */}
                            <div className="flex flex-col items-center">
                              <span
                                className={`text-sm sm:text-base font-semibold mb-0.5 sm:mb-1 transition-colors duration-300
                                  ${
                                    currentStep >= step.number
                                      ? "text-gray-900"
                                      : "text-gray-400"
                                  }`}
                              >
                                {step.title}
                              </span>
                              <span
                                className={`text-xs sm:text-sm transition-colors duration-300 hidden sm:block
                                  ${
                                    currentStep >= step.number
                                      ? "text-gray-600"
                                      : "text-gray-400"
                                  }`}
                              >
                                {step.subtitle}
                              </span>
                            </div>

                            {/* Hover Effect - Only show on desktop */}
                            <div
                              className={`absolute -top-14 left-1/2 -translate-x-1/2 bg-gray-900 text-white text-xs sm:text-sm py-2 px-3 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none whitespace-nowrap hidden sm:block`}
                            >
                              {currentStep > step.number
                                ? "Completado"
                                : currentStep === step.number
                                ? "En progreso"
                                : "Pendiente"}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Resume Uploader */}
                    <div className="flex justify-center">
                      <div className="w-full max-w-2xl">
                        <ResumeUploader
                          setResume={handleResumeUpload}
                          status={uploadStatus}
                          loading={parsingResume ? true : false}
                          handleRemove={handleRemove}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="w100 postjob-gray-container"
                  style={{ paddingTop: "40px", paddingBottom: "40px" }}
                >
                  <div className="text-xl font-medium mb-8">
                    Información Personal
                  </div>

                  <div className="space-y-6">
                    <div className="w100">
                      <div className="text-lg mb-3">Nombre</div>
                      <div
                        className={`search-pill ${
                          emptyFields.name ? "!border-2 !border-red-500" : ""
                        }`}
                      >
                        <input
                          type="text"
                          className="search-pill-input"
                          value={name}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setName(e.target.value);
                            setEmptyFields((prev) => ({
                              ...prev,
                              name: false,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="w100">
                      <div className="text-lg mb-3">Correo Electrónico</div>
                      <div
                        className={`search-pill ${
                          emptyFields.email ? "!border-2 !border-red-500" : ""
                        }`}
                      >
                        <input
                          type="email"
                          className="search-pill-input"
                          onInvalid={handleInvalidEmail}
                          value={email}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setEmail(e.target.value);
                            setEmptyFields((prev) => ({
                              ...prev,
                              email: false,
                            }));
                            e.target.setCustomValidity("");
                          }}
                        />
                      </div>
                    </div>

                    <div className="w100">
                      <div className="text-lg mb-3">Numero Celular</div>
                      <div
                        className={`search-pill ${
                          emptyFields.number ? "!border-2 !border-red-500" : ""
                        }`}
                      >
                        <input
                          type="text"
                          className="search-pill-input"
                          value={number}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setNumber(e.target.value);
                            setEmptyFields((prev) => ({
                              ...prev,
                              number: false,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="w100">
                      <div className="text-lg mb-3">Ciudad de Domicilio</div>
                      <div>
                        <AutocompleteLocation
                          setSelectedLocation={(loc) => {
                            setLocation(loc);
                            setEmptyFields((prev) => ({
                              ...prev,
                              location: false,
                            }));
                          }}
                          value={location}
                          className={
                            emptyFields.location
                              ? "!border-2 !border-red-500 rounded-lg"
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flx flx-col" style={{ gap: "30px" }}>
                  <JobExperienceSection
                    JobExperienceList={experienceList}
                    handleJobExperienceChange={handleJobExperienceChange}
                    handleJobExperienceDelete={handleJobExperienceDelete}
                    addExperience={addExperience}
                    yearsOfExperience={yearsOfExperience}
                    setYearsOfExperience={setYearsOfExperience}
                  />
                  <EducationSection
                    educationList={educationList}
                    handleEducationChange={handleEducationChange}
                    handleEducationDelete={handleEducationDelete}
                    addEducation={addEducation}
                  />
                </div>

                {currJob?.mapResults && (
                  <div
                    className={`w100 postjob-gray-container ${
                      emptyFields.map
                        ? "!border-2 !border-red-500 rounded-lg"
                        : ""
                    }`}
                    style={{
                      paddingTop: "30px",
                      paddingBottom: "30px",
                      height: "100%",
                    }}
                  >
                    <div className="mb-25" style={{ fontSize: "24px" }}>
                      Dirección Domicilio
                    </div>

                    <LocationMapSelector
                      setMapResults={setMapResults}
                      onSubmit={handleMapSubmit}
                      fixedRadius={true}
                      closerZoom={true}
                    />
                  </div>
                )}
                {currJob?.questions && currJob.questions.length > 0 && (
                  <div
                    className={`w100 postjob-gray-container ${
                      emptyFields.questions
                        ? "!border-2 !border-red-500 rounded-lg"
                        : ""
                    }`}
                    style={{
                      paddingTop: "30px",
                      paddingBottom: "30px",
                      height: "100%",
                    }}
                  >
                    <div
                      className="w100 "
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="mb-25" style={{ fontSize: "24px" }}>
                        Preguntas Adicionales
                      </div>
                      {currJob?.questions &&
                        currJob.questions.map(
                          (question: Question, index: number) => (
                            <div
                              key={index}
                              className="mb-25 "
                              style={{ height: "100%" }}
                            >
                              <div
                                style={{
                                  marginBottom: "10px",
                                  fontSize: "18px",
                                }}
                              >
                                {question.question}
                              </div>
                              {question.type === "text" ? (
                                <div
                                  className="search-pill"
                                  style={{ height: "100%" }}
                                >
                                  <textarea
                                    className="search-pill-input text-input-post-jobs"
                                    value={answers[question.question] || ""}
                                    onChange={(e) =>
                                      handleAnswerChange(
                                        question.question,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      height: "100%",
                                      minHeight: "150px",
                                      resize: "vertical",
                                      padding: "10px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="postjob-custom-questions-container-row-select-wrapper ">
                                  <div
                                    className="postjob-custom-questions-container-row-select "
                                    onClick={() =>
                                      setOpenDropdown(
                                        openDropdown === index ? null : index
                                      )
                                    }
                                  >
                                    {answers[question.question] ||
                                      "Selecciona una opción"}
                                  </div>
                                  {openDropdown === index && (
                                    <div className="postjob-custom-questions-container-row-select-options ">
                                      {question.options?.map(
                                        (option, optionIndex) => (
                                          <div
                                            key={optionIndex}
                                            className="postjob-custom-questions-container-row-select-option"
                                            onClick={() => {
                                              handleAnswerChange(
                                                question.question,
                                                option
                                              );
                                              setOpenDropdown(null);
                                            }}
                                          >
                                            {option}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w100 flx-center flx-col mb-50 mt-25 ">
              {loading ? (
                <LoadingWidget loading={loading} />
              ) : (
                <>
                  <button
                    type="submit"
                    className="aplicar-btn button-hover"
                    style={{ color: "white" }}
                    disabled={loading}
                  >
                    Enviar
                  </button>
                  <Link
                    to="/contact-us"
                    className="mt-4 text-gray-600 hover:text-gray-800 text-sm underline"
                  >
                    Reportar un problema
                  </Link>
                </>
              )}
            </div>
          </form>
        )}

        <Footer type={2} />
      </div>
    </div>
  );
}

export default JobApply;
