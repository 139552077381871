import React, { useState, useEffect } from "react";
import {
  AutomationAction,
  AutomationConditionType,
  AutomationConditionGroup,
  LogicalOperator,
} from "../../../../typescript/interfaces/AutomationTypes";
import { JobInt } from "../../../../typescript/interfaces/JobInterface";
import { Question } from "../../../../typescript/interfaces/CustomQuestionInt";

interface ConditionSectionProps {
  action: AutomationAction;
  index: number;
  updateAction: (updates: Partial<AutomationAction>) => void;
  selectedJob: JobInt;
}

const ConditionSection: React.FC<ConditionSectionProps> = ({
  action,
  index,
  updateAction,
  selectedJob,
}) => {
  const [isGroup, setIsGroup] = useState(false);
  const [unsavedInputs, setUnsavedInputs] = useState<{ [key: number]: string }>(
    {}
  );

  // Initialize isGroup based on condition type when component mounts
  useEffect(() => {
    if (action.condition) {
      setIsGroup("operator" in action.condition);
    }
  }, [action.condition]);

  const handleConditionToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // Initialize with a single condition by default
      setIsGroup(false);
      // reset to default
      updateAction({
        condition: {
          type: "PROPERTY_INCLUDES",
          config: {},
        },
      });
    } else {
      updateAction({
        condition: undefined,
      });
    }
  };

  const handleOperatorChange = (operator: LogicalOperator) => {
    if (action.condition && "operator" in action.condition) {
      updateAction({
        condition: {
          ...action.condition,
          operator,
        },
      });
    }
  };

  const addConditionToGroup = () => {
    if (action.condition && "operator" in action.condition) {
      const group = action.condition as AutomationConditionGroup;
      updateAction({
        condition: {
          ...group,
          conditions: [
            ...group.conditions,
            {
              type: "PROPERTY_INCLUDES",
              config: {},
            },
          ],
        },
      });
    }
  };

  const removeConditionFromGroup = (conditionIndex: number) => {
    if (action.condition && "operator" in action.condition) {
      const group = action.condition as AutomationConditionGroup;
      const remainingConditions = group.conditions.filter(
        (_, i) => i !== conditionIndex
      );

      if (remainingConditions.length === 1) {
        // If only one condition remains, switch to simple mode
        updateAction({
          condition: remainingConditions[0],
        });
        setIsGroup(false);
      } else {
        // Otherwise, keep the group with remaining conditions
        updateAction({
          condition: {
            ...group,
            conditions: remainingConditions,
          },
        });
      }
    }
  };

  const updateConditionInGroup = (conditionIndex: number, updates: any) => {
    if (action.condition && "operator" in action.condition) {
      const group = action.condition as AutomationConditionGroup;
      const newConditions = [...group.conditions];
      newConditions[conditionIndex] = {
        ...newConditions[conditionIndex],
        ...updates,
      };
      updateAction({
        condition: {
          ...group,
          conditions: newConditions,
        },
      });
    }
  };

  const toggleConditionGroup = () => {
    if (action.condition) {
      if ("operator" in action.condition) {
        // Convert group to single condition
        const group = action.condition as AutomationConditionGroup;
        updateAction({
          condition: group.conditions[0],
        });
      } else {
        // Convert single condition to group
        const condition = action.condition;
        updateAction({
          condition: {
            operator: "AND",
            conditions: [condition],
          },
        });
      }
      setIsGroup(!isGroup);
    }
  };

  const renderConditionFields = (condition: any, index: number) => {
    return (
      <div
        key={index}
        className="space-y-4 p-4 bg-white rounded-lg border border-gray-200 relative"
      >
        {isGroup && (
          <button
            type="button"
            onClick={() => removeConditionFromGroup(index)}
            className="absolute top-2 right-2 w-6 h-6 flex items-center justify-center rounded-full bg-red-100 text-red-600 hover:bg-red-200 transition-colors duration-200"
            aria-label="Eliminar condición"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Tipo de Condición
          </label>
          <select
            value={condition.type}
            onChange={(e) => {
              const firstMultipleQuestion = selectedJob.questions.find(
                (question) => question.type === "multiple"
              );
              const defaultAnswer = firstMultipleQuestion?.options?.[0] || "";

              const updates = {
                type: e.target.value as AutomationConditionType,
                config:
                  e.target.value === "ANSWER_EQUALS"
                    ? {
                        questionId: firstMultipleQuestion?.question || "",
                        expectedAnswer: defaultAnswer,
                      }
                    : {},
              };

              if ("operator" in action.condition!) {
                updateConditionInGroup(index, updates);
              } else {
                updateAction({
                  condition: {
                    ...action.condition!,
                    ...updates,
                  },
                });
              }
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          >
            <option value="PROPERTY_INCLUDES">Filtro Inteligente Vicky</option>
            {selectedJob.questions.length > 0 &&
              selectedJob.questions.find(
                (question) => question.type === "multiple"
              ) && (
                <option value="ANSWER_EQUALS">Respuesta del Candidato</option>
              )}
          </select>
        </div>

        {condition.type === "PROPERTY_INCLUDES" && (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Ingresa palabras clave que buscas y Vicky filtrara los candidatos
              por tí
            </label>
            <div className="flex flex-wrap gap-2 p-2 border border-gray-300 rounded-md">
              {(condition.config.includesValue || []).map(
                (tag: string, tagIndex: number) => (
                  <span
                    key={tagIndex}
                    className="bg-emerald-100 text-emerald-700 px-2 py-1 rounded-md flex items-center text-sm border border-emerald-300"
                  >
                    {tag.trim()}
                    <button
                      type="button"
                      onClick={() => {
                        const newTags = (
                          condition.config.includesValue || []
                        ).filter((_: string, i: number) => i !== tagIndex);
                        const updates = {
                          config: {
                            ...condition.config,
                            includesValue: newTags,
                          },
                        };
                        if ("operator" in action.condition!) {
                          updateConditionInGroup(index, updates);
                        } else {
                          updateAction({
                            condition: {
                              ...action.condition!,
                              ...updates,
                            },
                          });
                        }
                      }}
                      className="ml-2 text-emerald-600 hover:text-emerald-800"
                    >
                      ×
                    </button>
                  </span>
                )
              )}
              <input
                type="text"
                value={unsavedInputs[index] || ""}
                onChange={(e) =>
                  setUnsavedInputs((prev) => ({
                    ...prev,
                    [index]: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === ",") {
                    e.preventDefault();
                    const newTag = (unsavedInputs[index] || "").trim();
                    if (
                      newTag &&
                      !condition.config.includesValue?.includes(newTag)
                    ) {
                      const currentTags = condition.config.includesValue || [];
                      const updates = {
                        config: {
                          ...condition.config,
                          includesValue: [...currentTags, newTag],
                        },
                      };
                      if ("operator" in action.condition!) {
                        updateConditionInGroup(index, updates);
                      } else {
                        updateAction({
                          condition: {
                            ...action.condition!,
                            ...updates,
                          },
                        });
                      }
                      setUnsavedInputs((prev) => ({ ...prev, [index]: "" }));
                    }
                  }
                }}
                onBlur={() => {
                  const newTag = (unsavedInputs[index] || "").trim();
                  if (
                    newTag &&
                    !condition.config.includesValue?.includes(newTag)
                  ) {
                    const currentTags = condition.config.includesValue || [];
                    const updates = {
                      config: {
                        ...condition.config,
                        includesValue: [...currentTags, newTag],
                      },
                    };
                    if ("operator" in action.condition!) {
                      updateConditionInGroup(index, updates);
                    } else {
                      updateAction({
                        condition: {
                          ...action.condition!,
                          ...updates,
                        },
                      });
                    }
                  }
                  setUnsavedInputs((prev) => ({ ...prev, [index]: "" }));
                }}
                className="outline-none border-none flex-grow min-w-[120px] bg-gray-50 text-sm min-h-[30px]"
                placeholder="Presiona Enter o coma para agregar"
              />
            </div>
          </div>
        )}

        {condition.type === "ANSWER_EQUALS" && (
          <>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Selecciona la pregunta por la que quieres filtrar
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                value={condition.config.questionId || ""}
                onChange={(e) => {
                  const updates = {
                    config: {
                      ...condition.config,
                      questionId: e.target.value,
                    },
                  };
                  if ("operator" in action.condition!) {
                    updateConditionInGroup(index, updates);
                  } else {
                    updateAction({
                      condition: {
                        ...action.condition!,
                        ...updates,
                      },
                    });
                  }
                }}
              >
                {selectedJob.questions.map(
                  (question: Question, index) =>
                    question.type === "multiple" && (
                      <option value={question.question} key={index}>
                        {question.question}
                      </option>
                    )
                )}
              </select>
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Respuesta Esperada
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                value={condition.config.expectedAnswer || ""}
                onChange={(e) => {
                  const updates = {
                    config: {
                      ...condition.config,
                      expectedAnswer: e.target.value,
                    },
                  };
                  if ("operator" in action.condition!) {
                    updateConditionInGroup(index, updates);
                  } else {
                    updateAction({
                      condition: {
                        ...action.condition!,
                        ...updates,
                      },
                    });
                  }
                }}
              >
                {selectedJob.questions
                  .find((question) => question.type === "multiple")
                  ?.options?.map((option, index) => (
                    <option value={option} key={index}>
                      {option}
                    </option>
                  ))}
              </select>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="border-t border-gray-200 pt-4">
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          id={`condition-toggle-${index}`}
          checked={!!action.condition}
          onChange={handleConditionToggle}
          className="rounded border-gray-300"
        />
        <label
          htmlFor={`condition-toggle-${index}`}
          className="text-sm font-medium text-gray-700"
        >
          Agregar Condición
        </label>
      </div>

      {action.condition && (
        <div className="space-y-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={toggleConditionGroup}
              className="text-sm text-laburo-green hover:text-laburo-green-dark"
            >
              <span className="text-sm text-gray-500">Cambiar a </span>
              {isGroup ? "Condición Simple" : "Condiciones compuestas"}
            </button>
            {isGroup && (
              <div className="flex items-center space-x-2">
                <label className="text-sm font-medium text-gray-700">
                  Activar si se cumple con
                </label>
                <div className="relative inline-block">
                  <select
                    value={
                      (action.condition as AutomationConditionGroup).operator
                    }
                    onChange={(e) =>
                      handleOperatorChange(e.target.value as LogicalOperator)
                    }
                    className="appearance-none bg-white pl-3 pr-8 py-1.5 border border-gray-300 rounded-md text-sm text-gray-700 cursor-pointer hover:border-laburo-green focus:outline-none focus:ring-1 focus:ring-laburo-green focus:border-laburo-green min-w-[90px] transition-colors duration-200"
                  >
                    <option value="AND">Todas</option>
                    <option value="OR">Alguna</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                    <svg
                      className="h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>

          {isGroup ? (
            <>
              {(action.condition as AutomationConditionGroup).conditions.map(
                (condition, index) => renderConditionFields(condition, index)
              )}
              <button
                type="button"
                onClick={addConditionToGroup}
                className="mt-2 text-sm text-laburo-green hover:text-laburo-green-dark"
              >
                + Agregar Otra Condición
              </button>
            </>
          ) : (
            renderConditionFields(action.condition, 0)
          )}
        </div>
      )}
    </div>
  );
};

export default ConditionSection;
