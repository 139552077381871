import React from "react";
import { ApplicantInt } from "../../../typescript/interfaces/AppInterface";
import ReviewStars from "../../applicants-sidebar/ReviewStars";

const getTimeAgo = (date: Date) => {
  const now = new Date();
  const diffInHours = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60 * 60)
  );

  if (diffInHours < 24) {
    return diffInHours === 1 ? "1 hora" : `${diffInHours} horas`;
  } else {
    const diffInDays = Math.floor(diffInHours / 24);
    return diffInDays === 1 ? "1 día " : `${diffInDays} días `;
  }
};

interface ApplicantCardPipelineProps {
  applicant: ApplicantInt;
  handleDragStart: (e: React.DragEvent, applicant: ApplicantInt) => void;
  onClick: () => void;
  isRejectedView: boolean;
}

function ApplicantCardPipeline({
  applicant,
  handleDragStart,
  onClick,
  isRejectedView,
}: ApplicantCardPipelineProps) {
  return (
    <div
      key={applicant._id}
      draggable
      onDragStart={(e) => handleDragStart(e, applicant)}
      onClick={onClick}
      className={`p-3 rounded-lg cursor-pointer transition-all ${
        isRejectedView
          ? "bg-white border border-red-200 hover:border-red-300 hover:shadow-sm"
          : "bg-white hover:shadow-sm border border-gray-200 hover:border-gray-300"
      }`}
    >
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 flex-shrink-0 bg-gradient-to-br from-laburo-green from-10% via-emerald-400 to-cyan-500 rounded-full flex justify-center items-center">
          {" "}
          <p className="font-semibold text-white text-lg">
            {applicant.name
              .split(" ")
              .map((word: string) => word[0])
              .slice(0, 2)
              .join("")
              .toUpperCase()}
          </p>
        </div>
        <div>
          <p className="text-sm font-semibold">{applicant.name}</p>
          <p className="text-xs text-gray-500">{applicant.email}</p>
        </div>
      </div>
      <div className="mt-2 flex justify-between items-center text-xs">
        <div className="flex items-center gap-1">
          <ReviewStars applicant={applicant} source="job" readOnly={true} />
        </div>
        <div className="text-gray-500">
          {getTimeAgo(new Date(applicant.date))}
        </div>
      </div>
    </div>
  );
}

export default ApplicantCardPipeline;
