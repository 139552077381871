import axios from "axios";
import { ApplicantCommentInt } from "../../typescript/interfaces/ApplicantActivityInt";
import { environment } from "../../config/enviroment";
import { getToken as getAppCheckToken } from "firebase/app-check";
import { appCheck } from "../../firebase";
const addApplicantComment = async (
  applicantId: string,
  comment: ApplicantCommentInt,
  companyId: string,
  jobId: string
) => {
  const appCheckToken = await getAppCheckToken(appCheck);
  try {
    const response = await axios.post(
      `${environment.endpoints.addApplicantComment}`,
      {
        applicantId: applicantId,
        commentData: comment,
        companyId: companyId,
        jobId: jobId,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken.token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding applicant comment:", error);
    throw error;
  }
};

export default addApplicantComment;
